import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {Data} from "@angular/router";
import {Observable, of} from "rxjs";
import {fromArray} from "rxjs/internal/observable/fromArray";


@Component({
  selector: 'app-table',
  templateUrl: 'table.html',
  styleUrls: ['table.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Table implements OnChanges {

  @Input() columns: any;
  @Input() rows: any;
  @Input() currentPage: any = 1;

  ngOnChanges(changes: SimpleChanges) {
    this.checkLength();
  }

  showTable = true;
  numOfElements = 10;
  optionsElements = [10,20,50,100,500];

  @Output() rowSelectedEmitter = new EventEmitter<string>();

  totalRows;

  constructor() {
  }

  ngOnInit() {
    this.changeNumOfElements();
    this.checkLength();

    const obsPage = of(this.currentPage);
    obsPage.subscribe(x=>{
      console.log("PageChange-"+x);
    });
  }

  async changeNumOfElements(){
    if(this.columns!=null) {
      this.showTable = false;
      this.columns.pager = {};
      this.columns.pager.display = true;
      this.columns.pager.perPage = this.numOfElements;
      await this.delay(200);
      this.showTable = true;
    }
  }

  changePage(currentPage){
    this.rows.setPage(currentPage);
  }

  checkLength(){
    if(this.rows!=null) {
      this.totalRows = this.rows.length;
    }else{
      this.totalRows = 0;
    }
  }

  onUserRowSelect(event) {
    this.rowSelectedEmitter.emit(event.data);
  }

  async waitForIt(){
    while(this.rows == null || this.rows == undefined){
      await this.delay(200);
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
