import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ForumMessageEntity} from "../../../../model/ForumMessageEntity";
import {Shared} from "../../../shared/shared";
import {ForumService} from "../../../../service/ForumService";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ForumEntity} from "../../../../model/ForumEntity";
import {ForumCategoriesEntity} from "../../../../model/ForumCategoriesEntity";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-single-thread',
  templateUrl: 'singleThread.html',
  styleUrls: ['singleThread.scss']
})
export class SingleThread {
  forumMessages: ForumMessageEntity[];
  forumCategories: ForumCategoriesEntity[];

  inputThread: ForumMessageEntity;

  newMessage: ForumMessageEntity = null;
  currentMessage: ForumMessageEntity;
  answerMode = false;

  mediaRecorder: any;
  audioChunks: any[] = [];
  audioUrl: string | null = null;
  isRecording: boolean = false;

  newEntity: ForumMessageEntity;
  modeAdd = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '180px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  constructor(private forumService: ForumService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    let obj = this.getLastVariableFromUrl('obj');
    this.inputThread = JSON.parse(this.base64ToUtf8(obj));
    console.log(this.inputThread);
    this.forumService.getSingleThread(this.inputThread.forumPrincipalId).subscribe(x => {
      if (x != null) {
        this.forumMessages = x;

        this.forumService.getForumCategories(this.inputThread.forumId).subscribe(x => {
          if (x != null) {
            this.forumCategories = x;
            this.forumCategories.forEach(c=>{
              if(c.subject != null){
                c.name = c.subject.name;
                c.icon = c.subject.icon;
              }
            });


            this.selectMessage(this.forumMessages.find(f=> f.id == this.inputThread.forumPrincipalId));
          }
        });
      }
    });


  }

  saveForumMessage(forumMessage: ForumMessageEntity, bool) {
    this.forumService.saveForumMessage(forumMessage).subscribe(t => {
      if (t != null) {
        if (bool) {
          this.forumMessages.push(t);
        }
        this.forumMessages = this.forumMessages.filter(x => {
          return x
        });
      }
    });
  }

  async saveNewForumMessage() {
    this.saveForumMessage(this.newEntity, true);
    this.modeAdd = false;
  }

  saveMessage() {
    this.forumService.saveForumMessage(this.newMessage).subscribe((x: ForumMessageEntity) => {
      if (x != null) {
        if (this.newMessage.id == null) {
          this.forumMessages.push(x);
        } else {
          this.currentMessage = this.newMessage;
        }
        this.newMessage = null;
      }
    });
  }

  cancelMessage() {
    this.newMessage = null;
  }

  createdMessage(createdMessage) {
    this.forumMessages.push(createdMessage);
    this.selectMessage(this.forumMessages.find(c => c.id == createdMessage.forumPrincipalId));
  }

  createNewMessage() {
    this.newMessage = new ForumMessageEntity();
    this.newMessage.forumId = this.currentMessage.forumId;
    this.newMessage.corpId = this.inputThread.corpId
    this.newMessage.showInCampus = true;
    this.newMessage.authorId = this.inputThread.authorId;
    this.newMessage.authorFullname = this.inputThread.authorFullname;
    this.newMessage.authorImage = this.inputThread.authorImage;
    this.newMessage.authorType = this.inputThread.authorType;
  }

  answerMessage(messageId) {
    let message = this.forumMessages.find(p => p.id == messageId);
    this.newMessage = new ForumMessageEntity();
    this.newMessage.forumId = this.currentMessage.forumId;
    this.newMessage.corpId = this.inputThread.corpId;
    this.newMessage.showInCampus = true;
    this.newMessage.forumMessageId = message.id;
    this.newMessage.title = message.title;
    this.newMessage.forumPrincipalId = message.forumPrincipalId;
    this.newMessage.categoryId = message.categoryId;
    this.newMessage.authorId = this.inputThread.authorId;
    this.newMessage.authorFullname = this.inputThread.authorFullname;
    this.newMessage.authorImage = this.inputThread.authorImage;
    this.newMessage.authorType = this.inputThread.authorType;

    this.answerMode = true;
  }

  selectMessage(message) {
    this.currentMessage = message
    this.currentMessage.answers = this.getRecursiveAnswers(message.id);
    this.newMessage = null
  }

  editMessage(messageId) {
    let message = this.forumMessages.find(p => p.id == messageId);
    this.newMessage = message;
  }

  getRecursiveAnswers(forumMessageId) {
    let output: ForumMessageEntity[] = [];
    if (forumMessageId != null) {
      let answers = this.forumMessages.filter(p => p.forumMessageId == forumMessageId);
      answers.forEach(p => {
        let f = p;
        f.answers = this.getRecursiveAnswers(p.id);
        output.push(f);
      });
    }
    return output;
  }

  getCategory(categoryId) {
    if (categoryId != null) {
      return this.forumCategories.find(p => p.id == categoryId);
    }
  }


  startRecording() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();
        this.isRecording = true;

        // Almacenar los datos de audio en chunks
        this.mediaRecorder.ondataavailable = (event: any) => {
          this.audioChunks.push(event.data);
        };

        // Al detener la grabación, crear un archivo de audio
        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, {type: 'audio/wav'});
          this.audioUrl = URL.createObjectURL(audioBlob); // Crear URL para el audio grabado
          this.audioChunks = []; // Limpiar los chunks para la siguiente grabación
        };
      }).catch(err => {
        console.error('Error al acceder al micrófono:', err);
      });
    } else {
      console.warn('El navegador no soporta la grabación de medios.');
    }
  }

  // Detener la grabación
  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.isRecording = false;
    }
  }

  // Reproducir el audio grabado
  playAudio() {
    const audio = new Audio(this.audioUrl!);
    audio.play();
  }

  getLastVariableFromUrl(name: string) {
    let toReturn;
    this.route.params.subscribe((params: Params) => {
      toReturn = params[name]
    });
    if (toReturn == null || toReturn == undefined) {
      let array = this.router.url.split('/');
      toReturn = array[(array.length - 1)];
    }
    return toReturn;
  }

  base64ToUtf8(base64: string): string {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    // Decodifica UTF-8 desde los bytes
    return new TextDecoder().decode(bytes);
  }
}
