import {SubscriptionPaymentsEntity} from "../model/SubscriptionPaymentsEntity";
import {SearchSubcriptionDto} from "./SearchSubcriptionDto";
import {StudentEntity} from "../model/StudentEntity";
import {SearchPaymentsDto} from "./SearchPaymentsDto";
import {SearchStudentDto} from "./SearchStudentDto";

export class SearchUserObject {
  searchUser: SearchStudentDto;
  searchSubscription: SearchSubcriptionDto;
  searchSubscriptionPayments: SubscriptionPaymentsEntity;
  searchPayments: SearchPaymentsDto

  constructor(su:SearchStudentDto, ss:SearchSubcriptionDto, ssp: SubscriptionPaymentsEntity, sp: SearchPaymentsDto) {
    this.searchUser = su;
    this.searchSubscription = ss;
    this.searchSubscriptionPayments = ssp;
    this.searchPayments = sp;
  }
}


