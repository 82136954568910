import {Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {SubjectService} from "../../../service/SubjectService";
import {BankPackageEntity} from "../../../model/BankPackageEntity";
import {BankItemEntity} from "../../../model/BankItemEntity";
import {BankService} from "../../../service/BankService";
import {VideoPlaylistEntity} from "../../../model/VideoPlaylistEntity";
import {VideoPlaylistVideoEntity} from "../../../model/VideoPlaylistVideoEntity";
import {VideoService} from "../../../service/VideoService";

@Component({
  selector: 'app-video-playlist-data',
  templateUrl: 'videoPlaylistData.html',
  styleUrls: ['videoPlaylistData.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlaylistData {

  videoPlaylist: VideoPlaylistEntity[];
  videoItems: VideoPlaylistVideoEntity[];
  selectedVideoPlaylistId: number;

  @ViewChild('topicHeader') topicHeader:ElementRef;

  constructor(private videoService: VideoService, private shared: Shared, private renderer: Renderer2) {
  }

  ngOnInit() {
    console.log("Hola");
    this.videoService.getVideoPlaylist().subscribe(x => {
      if (x != null) {
        this.videoPlaylist = x;
      }
    });
  }

  filterVideoItems(id: number){
    this.selectedVideoPlaylistId = id;
    this.videoService.getVideoItems(id).subscribe(x => {
      if (x != null) {
        this.videoItems = x;
        this.renderer.selectRootElement('#topicHeader').click();
      }
    });
  }
}
