
export class SearchQuestionDto {
  reference: string;
  corps: number[];
  statement: string;
  comment: string;
  answer: string;
  multimediaTitle: string;
  numOfAnswers: number;
  numOfMultimedia: number;
  subject: number[];
  topic: number[];
  difficulty: number[];
  origin: number[];
  tags: number[];
  groups: number[];
  examSettings: number[];
  officialQuestion: boolean;
  officialNum: number;
  officialYear: number;

  constructor() {
  }
}
