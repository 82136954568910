import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Magnament } from './magnament';
import {MagnamentRoutingModule} from "./magnament-routing.module";
import {LoginService} from "../../service/LoginService";
import {UsersModule} from "./users/users.module";
import {ExamsModule} from "./exams/exams.module";
import {AchievementsModule} from "./achievements/achievements.module";
import {NgToggleModule} from "ng-toggle-button";
import {QuestionsModule} from "./questions/questions.module";
import {CoursesDataModule} from "./coursesData/coursesData.module";
import {WorkgroupModule} from "./workgroup/workgroup.module";
import {TranslationModule} from "./translation/translation.module";
import {SubjectsDataModule} from "./subjectsData/subjectsData.module";
import {StepsDataModule} from "./stepsData/stepsData.module";
import {LibraryModule} from "./library/library.module";
import {VideoModule} from "./video/video.module";
import {PricetableModule} from "./pricetable/pricetable.module";
import {ChatSupportModule} from "./chatSupport/chatSupport.module";
import {TutorModule} from "./tutor/tutor.module";
import {EmailTemplateModule} from "./emailTemplate/emailTemplate.module";
import {RouterModule} from "@angular/router";
import {RegisterLinksModule} from "./registerLinks/registerLinks.module";
import {StaticCalendarModule} from "./staticCalendar/staticCalendar.module";
import {ImagesService} from "../../service/ImagesService";
import {DndModule} from 'ngx-drag-drop';
import {ModuleModule} from "./module/module.module";
import {ScholarshipModule} from "./scholarship/scholarship.module";
import {UniversitiesModule} from "./universities/universities.module";
import {HospitalsModule} from "./hospitals/hospitals.module";
import {CorpStyleModule} from "./corpStyle/corpStyle.module";
import {BankDataModule} from "./bankData/bankData.module";
import {FlashcardsModule} from "./flashcards/flashcards.module";
import {ForumDataModule} from "./forumData/forumData.module";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ReportModule} from "./reports/report.module";
import {VideoPlaylistDataModule} from "./videoPlaylistData/videoPlaylistData.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MagnamentRoutingModule,
    UsersModule,
    ExamsModule,
    CoursesDataModule,
    AchievementsModule,
    NgToggleModule.forRoot(),
    QuestionsModule,
    WorkgroupModule,
    TranslationModule,
    SubjectsDataModule,
    StepsDataModule,
    LibraryModule,
    VideoModule,
    PricetableModule,
    ChatSupportModule,
    TutorModule,
    EmailTemplateModule,
    RouterModule,
    RegisterLinksModule,
    StaticCalendarModule,
    DndModule,
    ModuleModule,
    ScholarshipModule,
    UniversitiesModule,
    HospitalsModule,
    CorpStyleModule,
    BankDataModule,
    FlashcardsModule,
    ForumDataModule,
    ReportModule,
    VideoPlaylistDataModule
  ],
  providers: [LoginService, ImagesService],
  exports: [Magnament],
  declarations: [Magnament]
})
export class MagnamentModule {}

