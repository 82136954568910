import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RegisterLinks } from './registerLinks';
import {RegisterLinksRoutingModule} from "./registerLinks-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {NgToggleModule} from "ng-toggle-button";
import {NgSelectModule} from "@ng-select/ng-select";
import {UserService} from "../../../service/UserService";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RegisterLinksRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgToggleModule,
    NgSelectModule,
    DlDateTimePickerModule
  ],
  providers: [UserService],
  exports: [
    RegisterLinks
  ],
  declarations: [RegisterLinks]
})
export class RegisterLinksModule {}
