import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";
import {LibraryFolderIntermediateEntity} from "./LibraryFolderIntermediateEntity";

export class LibraryEntity {
  corpId: number;
  id: number;
  title: string;
  fileUrl: string;
  lowFileUrl: string;
  front: string;
  frontUrl: string;
  updatesUrl: string;
  subject: SubjectEntity;
  topic: TopicEntity;
  subjectId: number;
  topicId: number;
  reference: string;
  saved: boolean;
  showInCampus: boolean;
  showInMagnament: boolean;
  folders: LibraryFolderIntermediateEntity[];
}
