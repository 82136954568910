import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SubjectsData } from './subjectsData';
import {SubjectsDataRoutingModule} from "./subjectsData-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {Topic} from "./topics/topic";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {SubjectService} from "../../../service/SubjectService";
import {Subject} from "./subjects/subject";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SubjectsDataRoutingModule,
    NgxDatatableModule,
    TableModule,
    DlDateTimePickerModule,
    NgSelectModule,
  ],
  providers: [SubjectService],
  exports: [
    SubjectsData
  ],
  declarations: [SubjectsData, Topic, Subject]
})
export class SubjectsDataModule {}
