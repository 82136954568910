import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BankData } from './bankData';
import {BankDataRoutingModule} from "./bankData-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {BankItem} from "./bankItem/bankItem";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {SubjectService} from "../../../service/SubjectService";
import {BankPackage} from "./bankPackage/bankPackage";
import {BankService} from "../../../service/BankService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    BankDataRoutingModule,
    NgxDatatableModule,
    TableModule,
    DlDateTimePickerModule,
    NgSelectModule,
  ],
  providers: [BankService],
  exports: [
    BankData
  ],
  declarations: [BankData, BankItem, BankPackage]
})
export class BankDataModule {}
