import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {StepEntity} from "../../../../model/StepEntity";
import {CalendarComponent} from "../../generic/table/calendarComponent/calendarComponent";
import {TurnEntity} from "../../../../model/TurnEntity";
import {SiteEntity} from "../../../../model/SiteEntity";
import {ModalityEntity} from "../../../../model/ModalityEntity";
import {CourseTypeEntity} from "../../../../model/CourseTypeEntity";
import {CourseEntity} from "../../../../model/CourseEntity";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {CalendarService} from "../../../../service/CalendarService";
import * as moment from "moment";

@Component({
  selector: 'app-step',
  templateUrl: 'step.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Step {

  @Input() steps: StepEntity[];

  @Input() turns: TurnEntity[];
  @Input() sites: SiteEntity[];
  @Input() modalities: ModalityEntity[];
  @Input() coursesType: CourseTypeEntity[];
  @Input() courses: CourseEntity[];

  @Output() filter = new EventEmitter<number>();
  @Output() filterPackage = new EventEmitter<number>();

  newEntity: StepEntity;
  modeAdd = false;

  showStartDate = false;
  showEndDate = false;

  headers: any = {
    columns: {
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.alias = data.text;
              this.saveStep(obj, false);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.name = data.text;
              this.saveStep(obj, false);
            });
          }
        },
      },
      startDate: {title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.startDate = data.date;
              this.saveStep(obj, false);
            });
          }
        },
      },
      endDate: {title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.endDate = data.date;
              this.saveStep(obj, false);
            });
          }
        },
      },
      courseId: {title: 'Course', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.courses}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.courseId = data.id;
              this.saveStep(obj, false);
            });
          }
        },
      },
      courseTypeId: {title: 'Course type', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.coursesType}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.courseTypeId = data.id;
              this.saveStep(obj, false);
            });
          }
        },
      },
      modalityId: {title: 'Modality', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.modalities}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.modalityId = data.id;
              this.saveStep(obj, false);
            });
          }
        },
      },
      siteId: {title: 'Site', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.sites}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.siteId = data.id;
              this.saveStep(obj, false);
            });
          }
        },
      },
      turnId: {title: 'Turn', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.turns}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.turnId = data.id;
              this.saveStep(obj, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let subject = this.steps.find(x => x.id == data.row.id)
              subject.showInMagnament = data.bool;
              this.saveStep(subject, false);
            });
          }
        },
      },
      activeDays: {title: 'Active days', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.activeDays = data.text;
              this.saveStep(obj, false);
            });
          }
        },
      },
      activeMorningFrom: {title: 'Morn from', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.activeMorningFrom = data.text;
              this.saveStep(obj, false);
            });
          }
        },
      },
      activeMorningTo: {title: 'Morn to', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.activeMorningTo = data.text;
              this.saveStep(obj, false);
            });
          }
        },
      },
      activeAfternoonFrom: {title: 'Aft from', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.activeAfternoonFrom = data.text;
              this.saveStep(obj, false);
            });
          }
        },
      },
      activeAfternoonTo: {title: 'Aft to', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let obj = this.steps.find(x => x.id == data.row.id)
              obj.activeAfternoonTo = data.text;
              this.saveStep(obj, false);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
      corpId: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: row.id, icon: 'cube-outline', text: 'Package', color: '#1c8aa5'}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextPackage(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private calendarService: CalendarService, private shared: Shared) {
  }

  ngOnInit() {
  }

  nextStep(id: number){
    this.filter.emit(id);
  }

  nextPackage(id: number){
    this.filterPackage.emit(id);
  }

  saveStep(step: StepEntity,bool) {
    console.log(step);
    this.calendarService.saveStep(step).subscribe( t =>{
      if(t!=null) {
        if(bool){this.steps.push(t);}
        this.steps = this.steps.filter(x => {return x});
      }
    });
  }

  async saveNewStep() {
    this.saveStep(this.newEntity,true);
    this.modeAdd = false;
  }

  newStep(){
    this.modeAdd = true;
    this.newEntity = new StepEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

  changeStartDate(event){
    this.newEntity.startDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event){
    this.newEntity.endDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

}
