import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import * as moment from "moment";
import {Payment} from "../../../../model/Payment";

@Component({
  selector: 'state-popover' ,
  templateUrl: 'statePopover.html',
  styleUrls: ['./../users.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatePopover implements ViewCell, OnInit {
  @Input() value: string;
  inputObj: any;
  paidDate: string;
  restoreDate: string;

  @Output() stateSave:  EventEmitter<any> = new EventEmitter();

  showStatePopover: Boolean = false;
  showPaidDate: Boolean = false;
  showRestoreDate: Boolean = false;

  rowData: Payment;
  tempRow: Payment;

  constructor() { }

  ngOnInit() {
    this.tempRow = this.rowData;
  }

  openPopover(){
    this.showStatePopover = !this.showStatePopover;
  }

  selectPopover(val1, val2){
    this.tempRow.done = val1;
    this.tempRow.restore = val2;
    if(!this.tempRow.done){
      this.tempRow.doneDate = null;
    }
    if(!this.tempRow.restore){
      this.tempRow.restoDate = null;
    }
  }

  savePaidDate(event){
    this.tempRow.doneDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveRestoreDate(event){
    this.tempRow.restoDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveDataAndClose(){
    this.rowData = this.tempRow;
    this.stateSave.emit(this.rowData);
    this.showStatePopover = false;
  }
}
