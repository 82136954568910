import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {QuestionEntity} from "../model/QuestionEntity";
import {CommonQuestionDto} from "../dto/CommonQuestionDto";
import {AnswerEntity} from "../model/AnswerEntity";
import {QuestionMultimediaEntity} from "../model/QuestionMultimediaEntity";
import {QuestionAdminDto} from "../dto/QuestionAdminDto";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class QuestionService extends ParentService{

  public async getQuestions(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<QuestionEntity[]>(this.shared.MAGNAMENT_URL+'/getQuestions/'+ corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public async getQuestionsAdmin(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<QuestionEntity[]>(this.shared.MAGNAMENT_URL+'/getQuestionsAdmin/'+ corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getQuestion(reference){
    return this.http.get<QuestionEntity>(this.shared.MAGNAMENT_URL+'/getQuestion/'+reference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public searchQuestions(searchQuestions){
    return this.http.post<QuestionEntity[]>(this.shared.MAGNAMENT_URL+'/searchQuestions', searchQuestions, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public importQuestions(obj){
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/importQuestions', obj, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public async getCommonQuestion(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<CommonQuestionDto>(this.shared.MAGNAMENT_URL+'/getCommonQuestion/'+corps+'/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getAnswerQuestion(questionReference){
    return this.http.get<AnswerEntity[]>(this.shared.MAGNAMENT_URL+'/getAnswerQuestion/'+questionReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getMultimediaQuestion(questionReference){
    return this.http.get<QuestionMultimediaEntity[]>(this.shared.MAGNAMENT_URL+'/getMultimediaQuestion/'+questionReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getOriginTagQuestion(questionReference){
    return this.http.get<QuestionAdminDto>(this.shared.MAGNAMENT_URL+'/getOriginTagQuestion/'+questionReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveMultimedia(mul: QuestionMultimediaEntity){
    return this.http.post<QuestionMultimediaEntity>(this.shared.MAGNAMENT_URL+'/saveMultimedia', mul, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveQuestion(que){
    return this.http.post<QuestionEntity>(this.shared.MAGNAMENT_URL+'/saveQuestion', que, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadMultimedia(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadMultimedia', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
}
