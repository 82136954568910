import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {PricetableEntity} from "../model/PricetableEntity";
import {ChatRoomEntity} from "../model/ChatRoomEntity";
import {ChatMessageEntity} from "../model/ChatMessageEntity";
import {MultisubscriptionEntity} from "../model/MultisubscriptionEntity";
import {BasicDto} from "../dto/BasicDto";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {ImageEntity} from "../model/ImageEntity";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class ImagesService extends ParentService{

  public uploadImage(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<ImageEntity>(this.shared.MAGNAMENT_URL+'/uploadLibraryImage/'+this.shared.CURRENT_CORP.corpGroup, formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveImage(imageEntity){
    return this.http.post<ImageEntity>(this.shared.MAGNAMENT_URL+'/saveImage', imageEntity, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getImages(){
    return this.http.get<ImageEntity[]>(this.shared.MAGNAMENT_URL+'/getImages/'+this.shared.CURRENT_CORP.corpGroup, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

}
