import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Flashcards } from './flashcards';
import {FlashcardsRoutingModule} from "./flashcards-routing.module";
import {TableModule} from "../generic/table/table.module";
import {SwiperModule} from "swiper/angular";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimeDateModule, DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgToggleModule} from "ng-toggle-button";
import {QuestionService} from "../../../service/QuestionService";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {FlashcardService} from "../../../service/FlashcardService";
import {SubjectService} from "../../../service/SubjectService";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        FlashcardsRoutingModule,
        TableModule,
        SwiperModule,
        NgSelectModule,
        DlDateTimeDateModule,
        DlDateTimePickerModule,
        NgToggleModule,
        AngularEditorModule
    ],
  providers: [FlashcardService, SubjectService],
  exports: [Flashcards],
  declarations: [Flashcards]
})
export class FlashcardsModule {}
