
export class VideoFolderIntermediateEntity {
  id: number;
  videoId: number;
  videoFolderId: number;
  videoOrder: number;
  enabled: boolean;
  startDate: string;
  endDate: string;
  typeOfRequirement: string;
  requirementReference: string;
  requirementPercentaje: number;
  requirementMoreThan: boolean;
  showRequirement: boolean;
}
