export class SurveyEntity {
  corpId: number;
  id: number;
  reference: string;
  name: string;
  startDate: string;
  endDate: string;
  showInCampus: boolean;
  showInMagnament: boolean;
}


