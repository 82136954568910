import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";

export class CourseEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  startDate: string;
  endDate: string;
  defaultRole: number;
  coursePucharse: boolean;
  forumId: number;
  categoryId: number;
  officialExamId: number;
  registerOrder: number;
  openSignupDate: string;
  closeSignupDate: string;
  contentNotification: boolean;
  showInRegister: boolean;
  showInCampus: boolean;
  showInMagnament: boolean;

  tagCloudQuestionGroups: string;
  subjectQuestionGroups: string;
  flashcardGroups: string;
}
