
export class Payment {
  corpId: number;
  id: number;
  studentId: number;
  subscriptionId: number;
  subscriptionPaymentId: number;
  typeOfPayment: string;
  formatOfPayment: string;
  price: number;
  startDate: string;
  endDate: string;
  done: boolean;
  restore: boolean;
  doneDate: string;
  restoDate: string;
  currency: string;
  fileUrl: string;
}
