import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Location} from '@angular/common';
import {StudentEntity} from "../../model/StudentEntity";
import {Subscription, throwError} from "rxjs";
import {ContextDto} from "../../dto/ContextDto";
import {CorporationEntity} from "../../model/CorporationEntity";
import {SubscriptionEntity} from "../../model/SubscriptionEntity";
import {NotificationEntity} from "../../model/NotificationEntity";
import {Device} from '@ionic-native/device/ngx';
import {Platform} from "@ionic/angular";
import {TranslationEntity} from "../../model/TranslationEntity";
import {ManagerEntity} from "../../model/ManagerEntity";
import {LogEntity} from "../../model/LogEntity";
import * as moment from "moment";
import {CorpStyleEntity} from "../../model/CorpStyleEntity";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Injectable({
  providedIn: 'root'
})
export class Shared {

  //MAGNAMENT_URL = "http://localhost:8102";
  //MAGNAMENT_URL = "http://192.168.0.229:8102";
  //MAGNAMENT_URL = "http://192.168.41.14:8102";
  //MAGNAMENT_URL = "http://192.168.1.11:8102"
  MAGNAMENT_URL = "https://magnament-back.mediplus.es"
  CHAT_URL = "https://chat-back.mediplus.es"
  //CHAT_URL = "http://192.168.40.206:8103";
  CORP_ALIAS = "MD";
  CORP_ID = 1;

  public CURRENT_CORP: CorporationEntity;
  public CURRENT_USER: StudentEntity;
  public CURRENT_MANAGER: ManagerEntity;
  public CURRENT_SUBSCRIPTION: SubscriptionEntity;
  public CURRENT_SUBS_NUMBER: number = 0;
  public SUBSCRIPTIONS: SubscriptionEntity[];
  public TRANSLATIONS: TranslationEntity[];
  public CURRENT_LANGUAGE = 'ES';
  public CURRENT_IP: string;
  public CURRENT_LOCATION: any;
  public SERVER_TIMEZONE = 'Etc/GMT';
  public DARKMODE: boolean = false;
  public STYLE: CorpStyleEntity;

  public CORPS: CorporationEntity[];

  public isMenuColapsed = true;

  public NOTIFICATIONS: NotificationEntity[] = [];

  videoPlayer;

  subscriptionMoment: Subscription;
  subscriptionChat: Subscription;
  savedUrL: string = "";

  haveNotifications: boolean = false;

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  constructor(private router: Router, private route: ActivatedRoute, private location: Location, private http: HttpClient, private device: Device, private platform: Platform, public toastr: ToastrService) {

    if (this.checkHostInReferrer('localhost')) {
      console.log("Local");
      this.MAGNAMENT_URL = "http://192.168.0.229:8102";
      this.MAGNAMENT_URL = "http://192.168.0.172:8102";
      //this.MAGNAMENT_URL = "http://192.168.180.86:8102";
      //this.MAGNAMENT_URL = "http://192.168.0.55:8102";
      //this.MAGNAMENT_URL = "http://192.168.0.17:8102"
      //this.MAGNAMENT_URL = "http://172.20.10.4:8102"
    }

    router.events.subscribe((val) => {
      if (this.savedUrL != null && this.savedUrL != undefined && this.savedUrL != "") {
        if (window.location.href != this.savedUrL) {
          this.videoPlayer.pause();
          this.subscriptionMoment.unsubscribe();
          this.subscriptionChat.unsubscribe();
          this.savedUrL = "";
        }
      }
    });

    this.CURRENT_MANAGER = JSON.parse(localStorage.getItem('CURRENT_MANAGER'));
    this.CURRENT_CORP = JSON.parse(localStorage.getItem('CURRENT_CORP'));

    if (this.CURRENT_CORP != null) {
      this.CORP_ID = this.CURRENT_CORP.id;
      this.CORP_ALIAS = this.CURRENT_CORP.alias;
    }

    if(!this.checkLocation('/singleThread')) {
      if (this.CURRENT_MANAGER == null || this.CURRENT_MANAGER == undefined || this.CURRENT_CORP == null || this.CURRENT_MANAGER == null) {
        this.goTo(['/', 'login']);
      } else {
        this.afterLogin(this.CURRENT_CORP.id, this.CURRENT_MANAGER.id, this.CURRENT_MANAGER.token);
      }
    }
  }

  afterLogin(corpId, managerId, token) {
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);

    this.http.get<ContextDto>(this.MAGNAMENT_URL + '/getContext/' + corpId + '/' + managerId, {headers}).subscribe((x: ContextDto) => {
      this.CURRENT_MANAGER = x.manager;
      this.CURRENT_MANAGER.token = token;
      this.CURRENT_CORP = x.corporation;
      this.CORPS = x.corps;

      /*interval(300000).subscribe(val => this.checkNotifications());
      this.checkNotifications();*/

      this.CORP_ID = this.CURRENT_CORP.id;
      this.CORP_ALIAS = this.CURRENT_CORP.alias;

      this.goTo(['/']);

      if (!this.CURRENT_MANAGER.corporations.includes(this.CURRENT_CORP.id + "")) {
        localStorage.setItem('CURRENT_MANAGER', JSON.stringify(this.CURRENT_MANAGER));
        this.CURRENT_CORP = this.CORPS.find(y => y.id === this.CURRENT_MANAGER.corpId);
        localStorage.setItem('CURRENT_CORP', JSON.stringify(this.CURRENT_CORP));
        window.location.reload();
      } else {
        localStorage.setItem('CURRENT_MANAGER', JSON.stringify(this.CURRENT_MANAGER));
        localStorage.setItem('CURRENT_CORP', JSON.stringify(this.CURRENT_CORP));
      }
    })
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      if (res != null) {
        this.CURRENT_IP = res.ip;
        this.http.get("https://ipapi.co/" + this.CURRENT_IP + "/json").subscribe((result: any) => {
          if (result != null) {
            console.log(result);
            this.CURRENT_LOCATION = result;

            moment.locale(this.CURRENT_LOCATION.languages.split(',')[0]);
            moment.tz.setDefault('Etc/GMT');
          }
        });
      }
    });

    this.getStyle(headers);
  }

  getStyle(headers) {
    return this.http.get<CorpStyleEntity>(this.MAGNAMENT_URL + '/getCorpStyle/' + this.CORP_ID, {headers}).pipe(catchError(this.handleError)).subscribe((x: CorpStyleEntity) => {
      if (x != null) {
        this.STYLE = x;
      }
    });
  }

  async getCorpGroups() {
    while (this.CORPS == null) {
      await this.delay(200);
    }
    let output = this.CURRENT_MANAGER.corporations.split(',').map(Number);
    output = output.filter(p => this.CORPS.find(t => t.id == p).corpGroup == this.CURRENT_CORP.corpGroup);
    return output.join(',');
  }

  changeThemeMode() {
    this.DARKMODE = !this.DARKMODE;
    if (!this.DARKMODE) {
      this.CURRENT_SUBSCRIPTION.settings.theme = 0
    } else {
      this.CURRENT_SUBSCRIPTION.settings.theme = 1
    }
    document.body.classList.toggle('dark');
  }

  changeLanguage(language) {
    this.CURRENT_LANGUAGE = language;
    this.CURRENT_SUBSCRIPTION.settings.language = language;
    this.http.get<TranslationEntity[]>(this.MAGNAMENT_URL + '/getTranslation/' + language, this.headers()).pipe(catchError(this.handleError)).subscribe(x => {
      if (x != null) {
        this.TRANSLATIONS = x;
      }
    });
  }

  checkNotifications() {
    this.http.get<NotificationEntity[]>(this.MAGNAMENT_URL + '/getNotifications/' + this.CURRENT_SUBSCRIPTION.reference, this.headers()).pipe(catchError(this.handleError)).subscribe((x: NotificationEntity[]) => {
      if (x != null) {
        this.NOTIFICATIONS = x;
        console.log(this.NOTIFICATIONS.find(x => !x.showed));
        if (this.NOTIFICATIONS.find(x => !x.showed) != null) {
          this.haveNotifications = true;
        } else {
          this.haveNotifications = false;
        }
      }
    });
  }

  saveUrl() {
    this.savedUrL = window.location.href;
  }

  headers() {
    let token = "";
    if(this.CURRENT_MANAGER != null){
      token = this.CURRENT_MANAGER.token;
    }

    let options = {
      headers: new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('Authorization', `Bearer ${token}`)
    };
    return options;
  }

  checkUrl(urlToCheck: string) {
    if (this.router.url.indexOf(urlToCheck) >= 0) return true;
    return false;
  }

  checkHostInLocation(urlToCheck: string) {
    if (window.location.host.indexOf(urlToCheck) >= 0) return true;
    return false;
  }

  checkLocation(urlToCheck: string) {
    if (this.location.path().indexOf(urlToCheck) >= 0) return true;
    return false;
  }

  checkHostInReferrer(urlToCheck: string) {
    if (document.location.hostname.includes(urlToCheck)) {
      return true;
    } else {
      return false;
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.goTo(['login','logout']);
      return throwError('401');
    } else if (error.status === 403) {
      this.goTo(['login','logout']);
      return throwError('403');
    } else if (error.status === 500) {
      this.toastr.error('Error en el servidor, contacta con informática', 'Error en el servidor');
      return throwError('500');
    } else if (error.status === 400) {
      this.toastr.error('Revisa los parametros introducidos y vuelve a enviarlos, quizás falta un campo o alguno es incorrecto', 'Error en el envío de parámetros');
      return throwError('400');
    } else{
      this.toastr.error('Error, vuelve a intentarlo y si persiste, contacta con informática', 'Error');
      return throwError(error.status);
    }
  }

  handleErrorLogin(error: HttpErrorResponse) {
    if (error.status === 401) {
      return throwError('401');
    } else if (error.status === 403) {
      return throwError('403');
    } else {
      return throwError('400');
    }
  }


  getLastVariableFromUrl(name: string) {
    let toReturn;
    this.route.params.subscribe((params: Params) => {
      toReturn = params[name]
    });
    if (toReturn == null || toReturn == undefined) {
      let array = this.router.url.split('/');
      toReturn = array[(array.length - 1)];
    }
    return toReturn;
  }

  goBack() {
    this.location.back();
  }

  goTo(url) {
    this.router.navigate(url);
  }

  goExternalTo(url) {
    window.open(url, '_system')
  }

  goInternalTo(url) {
    this.router.navigateByUrl(url);
  }

  saveLog(body) {
    if(!this.checkUrl('/singleThread') && this.CURRENT_MANAGER != null) {
      let log: LogEntity = new LogEntity();
      log.corpId = this.CORP_ID;
      log.managerId = this.CURRENT_MANAGER.id;
      log.dateTime = moment().format('yyyy-MM-DDTHH:mm:ss');
      log.ip = this.CURRENT_IP;
      if (this.platform.is('cordova')) {
        log.device = this.device.version;
      } else {
        log.device = window.navigator.userAgent;
      }
      if (body != null) {
        if (body.profileImage != null) {
          body.profileImage = "";
        }
        if (body.front != null) {
          body.front = "";
        }
        log.body = JSON.stringify(body);
      }

      log.url = window.location.href;

      this.http.post(this.MAGNAMENT_URL + '/saveLog', log, this.headers()).subscribe();
    }
  }

  t(word: string) {
    if (this.TRANSLATIONS != null && this.TRANSLATIONS.length > 0) {
      if (this.TRANSLATIONS.find(x => x.word == word) != null) {
        return this.TRANSLATIONS.find(x => x.word == word).translation;
      } else {
        return word;
      }
    } else {
      return word;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
