import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";
import {VideoPlaylistVideoEntity} from "./VideoPlaylistVideoEntity";
import {VideoFolderEntity} from "./VideoFolderEntity";
import {VideoEntity} from "./VideoEntity";

export class LibraryFolderSubfolderEntity {
  id: number;
  corpId: number;
  libraryFolderChildId: number;
  libraryFolderParentId: number;
  libraryFolderOrder: number;
  enabled: boolean;
  startDate: string;
  endDate: string;
}
