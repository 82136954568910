import {Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {CalendarService} from "../../../service/CalendarService";
import {StepContentEntity} from "../../../model/StepContentEntity";
import {StepEntity} from "../../../model/StepEntity";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {CourseTypeEntity} from "../../../model/CourseTypeEntity";
import {ModalityEntity} from "../../../model/ModalityEntity";
import {SiteEntity} from "../../../model/SiteEntity";
import {TurnEntity} from "../../../model/TurnEntity";
import {SubjectService} from "../../../service/SubjectService";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {PackageEntity} from "../../../model/CalendarPackage";
import {TypeOfEventEntity} from "../../../model/TypeOfEventEntity";
import {ForumEntity} from "../../../model/ForumEntity";
import {ForumService} from "../../../service/ForumService";
import {ForumMessageEntity} from "../../../model/ForumMessageEntity";
import {ForumCategoriesEntity} from "../../../model/ForumCategoriesEntity";
import {SurveyService} from "../../../service/SurveyService";
import {SurveyEntity} from "../../../model/SurveyEntity";
import {SurveyItemEntity} from "../../../model/SurveyItemEntity";

@Component({
  selector: 'app-survey-data',
  templateUrl: 'surveyData.html',
  styleUrls: ['surveyData.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyData {

  surveys: SurveyEntity[];
  surveyItems: SurveyItemEntity[];

  currentSurvey : SurveyEntity;

  constructor(private surveyService: SurveyService, private shared: Shared, private renderer: Renderer2) {
  }

  ngOnInit() {
   this.myInit();
  }

  async myInit(){
    this.surveyService.getSurveys().subscribe(x => {
      if (x != null) {
        this.surveys = x;
      }
    });
  }

  filterSurvey(id: number){
    this.currentSurvey = this.surveys.find(f=>f.id == id);
    this.surveyService.getSurveyItems(id).subscribe(x => {
      if (x != null) {
        this.surveyItems = x;
        console.log("Hello");
        this.renderer.selectRootElement('#surveyItemsHeader').click();
      }
    });
  }
}
