import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {UniversitiesRoutingModule} from "./universities-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {CourseService} from "../../../service/CourseService";
import {Universities} from "./universities";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {PlaceService} from "../../../service/PlaceService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    UniversitiesRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    DlDateTimePickerModule,
  ],
  providers: [PlaceService],
  exports: [
    Universities
  ],
  declarations: [Universities]
})
export class UniversitiesModule {}
