import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Flashcards} from './flashcards';

const routes: Routes = [
  {
    path: 'cards',
    component: Flashcards,
    children: [
      { path: ':reference', component: Flashcards },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FlashcardsRoutingModule {}
