import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {CourseService} from "../../../service/CourseService";
import {Shared} from "../../shared/shared";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {WorkgroupEntity} from "../../../model/WorkgroupEntity";
import {CourseEntity} from "../../../model/CourseEntity";
import {CalendarComponent} from "../generic/table/calendarComponent/calendarComponent";
import * as moment from "moment";
import {TutorEntity} from "../../../model/TutorEntity";
import {HospitalEntity} from "../../../model/HospitalEntity";
import {SpecialityEntity} from "../../../model/SpecialityEntity";

@Component({
  selector: 'app-tutor',
  templateUrl: 'tutor.html',
  styleUrls: ['tutor.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Tutor {

  tutors: TutorEntity[];

  hospitals: HospitalEntity[];
  specialities: SpecialityEntity[];

  showTable = true;
  modeAdd = false;

  newTutorEntity: TutorEntity;

  showStartDate = false;
  showEndDate = false;

  headers: any = {
    columns: {
      corpId: {title: 'corpId', hide: true, filter: false},
      id: {title: 'id', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.name = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      surname: {title: 'Surname', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.surname = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.alias = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      mobile: {title: 'Mobile', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.mobile = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      email: {title: 'Email', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.email = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      nif: {title: 'NIF', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.nif = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      address: {title: 'Address', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.address = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      province: {title: 'Province', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.province = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      town: {title: 'Town', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.town = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      cp: {title: 'CP', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.cp = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      country: {title: 'Country', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.country = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      hospitalId: {title: 'Hopital', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.hospitals}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.hospitalId = data.id;
              this.saveTutor(tutor);
            });
          }
        },
      },
      specialityId: {title: 'Speciality', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.specialities}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.specialityId = data.id;
              this.saveTutor(tutor);
            });
          }
        },
      },
      yearOfExam: {title: 'Year of exam', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.yearOfExam = data.text;
              this.saveTutor(tutor);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.showInCampus = data.bool;
              this.saveTutor(tutor);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let tutor = this.tutors.find(x => x.id == data.row.id)
              tutor.showInMagnament = data.bool;
              this.saveTutor(tutor);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private courseService: CourseService, private shared: Shared) {
  }

  ngOnInit() {
    this.courseService.getHospitals(this.shared.CORP_ID).subscribe( x=> {
      if(x!=null){
        this.hospitals = x;
        this.courseService.getSpecialities(this.shared.CORP_ID).subscribe( x=> {
          if(x!=null){
            this.specialities = x;
            this.courseService.getTutors(this.shared.CORP_ID).subscribe( x=> {
              if(x!=null){
                this.tutors = x;
              }
            });
          }
        });
      }
    });
  }

  async saveTutor(tutor: TutorEntity) {
    this.courseService.saveTutor(tutor).subscribe( t =>{
        if(t!=null) {
          let index = this.tutors.findIndex(x=> x.id == t.id);
          if(index != -1) {
            this.tutors[index] = t;
          }else{
            this.tutors.push(t);
          }
          this.tutors = this.tutors.filter(x => {return x});
        }
    });
  }

  async saveNewTutor() {
    this.saveTutor(this.newTutorEntity);
    this.modeAdd = false;
  }

  newTutor(){
    this.modeAdd = true;
    this.newTutorEntity = new TutorEntity();
    this.newTutorEntity.corpId = this.shared.CORP_ID;
    this.newTutorEntity.showInMagnament = true;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
