import {SurveySubitemEntity} from "./SurveySubitemEntity";

export class SurveyItemEntity {
  corpId: number;
  id: number;
  surveyId: number;
  statement: string;
  type: number;
  showInCampus: boolean;
  showInMagnament: boolean;
  order: number;
  subItems: SurveySubitemEntity[];
}


