import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import {Shared} from "../../../../shared/shared";

@Component({
  selector: 'dropdown-component' ,
  templateUrl: 'dropdownComponent.html',
  styleUrls: ['dropdownComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownComponent implements ViewCell, OnInit {
  @Input() value: any;
  @Output() changeDropdown:  EventEmitter<{row: any, id: number}> = new EventEmitter();
  rowData: any;
  options: any[];
  val: number;
  valString: string;
  showInput = false;

  showBlankSpace = false;
  @ViewChild('select') select: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    if(this.value != null) {
      this.val = this.value.cell;
      this.options = this.value.options;

      if(this.value.cell==null || this.value.cell.length ==0){
        this.showBlankSpace = true;
      }

    }else{
      this.val = 0;
      this.options = [];
      this.showBlankSpace = true;
    }

    if(this.options!= null && this.options.length > 0) {
      let obj:any = this.options.find(x => x.id == this.val);
      if(obj != null) {
        this.valString = obj.name;
      }else {
        this.valString = "";
      }
    }
  }

  append(){
    this.renderer.appendChild(document.body, this.select.nativeElement);
  }

  change(){
    this.showInput = false;
    this.valString = this.options.find(x=>x.id == this.val).name;
    this.changeDropdown.emit({row: this.rowData, id: this.val});
  }
}
