import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {VideoPlaylistVideoEntity} from "../../../../model/VideoPlaylistVideoEntity";
import {VideoPlaylistEntity} from "../../../../model/VideoPlaylistEntity";
import {VideoService} from "../../../../service/VideoService";

@Component({
  selector: 'app-video-item',
  templateUrl: 'videoItem.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class VideoItem {

  @Input() videoItems: VideoPlaylistVideoEntity[];
  @Input() videoPlaylist: VideoPlaylistEntity[];
  @Input() selectedVideoPlaylistId: number;

  @Output() filter = new EventEmitter<number>();

  newEntity: VideoPlaylistVideoEntity;
  modeAdd = false;

  constructor(private videoService: VideoService, private shared: Shared) {
  }

  ngOnInit() {
    this.videoItems.sort((a, b) => a.order - b.order);
  }

  nextStep(id: number){
    this.filter.emit(id);
  }

  saveVideoItem(videoItem: VideoPlaylistVideoEntity, bool) {
    videoItem.videoId = videoItem.video.id;
    videoItem.videoPlaylistId = videoItem.videoPlaylist.id;
    this.videoService.saveVideoItem(videoItem).subscribe( t =>{
      if(t!=null) {
        if(bool){this.videoItems.push(t);}
        this.videoItems = this.videoItems.filter(x => {return x});
      }
    });
  }

  async saveNewVideoItem() {
    this.saveVideoItem(this.newEntity, true);
    this.modeAdd = false;
  }

  reorderItems(ev) {
    if (ev.srcElement.id == "folder") {
      const itemMove = this.videoItems.splice(ev.detail.from, 1)[0];
      this.videoItems.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();

      this.videoItems = this.videoItems.map((element, index) => {
        element.order = index + 1;
        return element;
      })

      this.videoService.savePlayListItems(this.videoItems).subscribe(t => {
        if (t != null) {
          this.videoItems = this.videoItems.filter(f => f != undefined);
        }
      });
    }
  }

  deleteVideoItem(id){
    this.videoItems = this.videoItems.filter(x=> x.id != id);
    this.videoService.deleteVideoItem(id).subscribe();
  }

  newVideoItem(){
    const selectedBankPackage = this.videoPlaylist.find(videoItem => videoItem.id === this.selectedVideoPlaylistId);
    if (selectedBankPackage) {
      this.modeAdd = true;
      this.newEntity = new VideoPlaylistVideoEntity();
      this.newEntity.corpId = this.shared.CORP_ID;
      this.newEntity.enabled = false;
    }
  }
}
