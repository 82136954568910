import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";
import {LibraryEntity} from "./LibraryEntity";
import {VideoEntity} from "./VideoEntity";
import {VideoPlaylistEntity} from "./VideoPlaylistEntity";

export class VideoPlaylistVideoEntity {
  corpId: number;
  id: number;
  enabled: boolean;
  order: number;
  video: VideoEntity;
  videoPlaylist: VideoPlaylistEntity;
  videoId: number;
  videoPlaylistId: number;
}
