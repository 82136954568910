import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ForumMessageEntity} from "../../../../model/ForumMessageEntity";
import {Shared} from "../../../shared/shared";
import {ForumService} from "../../../../service/ForumService";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import WaveSurfer from "wavesurfer.js";
import {JustReferenceDto} from "../../../../dto/JustReferenceDto";

@Component({
  selector: 'app-message',
  templateUrl: 'message.html',
  styleUrls: ['message.scss']
})
export class Message {
  @Input() forumMessage: ForumMessageEntity;

  @Output() createNewMessage: EventEmitter<ForumMessageEntity> = new EventEmitter<ForumMessageEntity>();
  @Output() sortThreads: EventEmitter<any> = new EventEmitter<any>();

  isFirst = null;
  newMessage: ForumMessageEntity = null;

  waveSurferShow: any;
  @ViewChild('waveformShow') waveformRef!: ElementRef;

  private mediaRecorder: any;
  private audioChunks: BlobPart[] = [];
  private waveSurfer: any;
  public audioUrl: string = ''; // Aquí estará el URL del Blob creado
  isRecording: boolean = false;
  haveAudio: boolean = false;
  audioBlob = null;
  @ViewChild('waveform') waveform!: ElementRef; // Referencia al div donde se renderiza la onda

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '120px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  constructor(public shared: Shared, private forumService: ForumService) {}

  ngOnInit() {
    this.init();
  }

  async init() {
    if(this.forumMessage.forumMessageId == null){
      this.isFirst = true;
    }else{
      this.isFirst = false;
    }

    await this.delay(200);

    this.waveSurferShow = WaveSurfer.create({
      container: this.waveformRef.nativeElement,
      waveColor: this.shared.STYLE.primaryColor,
      progressColor: this.shared.STYLE.secondaryColor,
      cursorColor: this.shared.STYLE.secondaryColor,
      barWidth: 5,
      height: 50,
      barGap: 2,
      barRadius: 5,
    });

    await this.delay(200);

    this.waveSurferShow.load('https://assets.mediplus.es/'+this.forumMessage.audioUrl);

  }

  createNewMsg(msg){
    this.createNewMessage.emit(msg);
  }

  editMsg(message){
    this.newMessage = message;
  }

  async answerMsg(message){
    this.newMessage = new ForumMessageEntity();
    this.newMessage.forumId = this.forumMessage.forumId;
    this.newMessage.corpId = this.shared.CORP_ID;
    this.newMessage.showInCampus = true;
    this.newMessage.title = message.title;
    this.newMessage.forumMessageId = message.id;
    this.newMessage.forumPrincipalId = message.forumPrincipalId;
    this.newMessage.categoryId = message.categoryId;
    this.newMessage.authorId = this.shared.CURRENT_MANAGER.id;
    this.newMessage.authorType = 'ADMIN';
    this.newMessage.authorFullname = this.shared.CURRENT_MANAGER.name +' '+ this.shared.CURRENT_MANAGER.surname;
    this.newMessage.authorImage = this.shared.CURRENT_MANAGER.profileImage;

    await this.delay(200);

    this.waveSurfer = WaveSurfer.create({
      container: this.waveform.nativeElement,
      waveColor: '#d9dcff',
      progressColor: '#4353ff',
      cursorColor: '#4353ff',
      barWidth: 5,
      height: 50,
      barGap: 2,
      barRadius: 5,
    });

    this.waveSurfer.on('ready', () => {
      this.haveAudio = true;
      this.waveform.nativeElement.click();
    });
  }

  saveMessage(message){
    if(this.haveAudio){
      const audioFile = new File([this.audioBlob], 'audio.wav', { type: 'audio/wav' });
      this.forumService.uploadFileAudio(audioFile).subscribe((x: JustReferenceDto) =>{
        if(x != null){
          message.audioUrl = x.reference;

          this.forumService.saveForumMessage(message).subscribe((x: ForumMessageEntity) => {
            if (x != null) {
              this.createNewMessage.emit(x);
              this.executeSortThreads();
              this.newMessage = null;
            }
          });
        }
      });
    }else {
      this.forumService.saveForumMessage(message).subscribe((x: ForumMessageEntity) => {
        if (x != null) {
          this.createNewMessage.emit(x);
          this.executeSortThreads();
          this.newMessage = null;
        }
      });
    }
  }

  saveMessageWithoutNotification(message){
    this.forumService.saveForumMessageWithoutNotification(message).subscribe((x: ForumMessageEntity)=>{
      if(x!=null){
        this.createNewMessage.emit(x);
        this.executeSortThreads();
        this.newMessage = null;
      }
    });
  }

  executeSortThreads(){
    this.sortThreads.emit();
  }

  cancelMessage(){
    this.newMessage = null;
  }

  openThread(forumMessage){
    forumMessage.closed = false;
    this.saveMessageWithoutNotification(forumMessage);
  }

  closeThread(forumMessage){
    forumMessage.closed = true;
    this.saveMessageWithoutNotification(forumMessage);
  }

  hideThread(forumMessage){
    forumMessage.showInCampus = false;
    this.saveMessageWithoutNotification(forumMessage);
  }

  showThread(forumMessage){
    forumMessage.showInCampus = true;
    this.saveMessageWithoutNotification(forumMessage);
  }



  startRecording() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();
        this.isRecording = true;
        this.audioChunks = []; // Reiniciar los chunks de audio

        this.mediaRecorder.ondataavailable = (event: any) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
          this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
          this.audioUrl = URL.createObjectURL(this.audioBlob);
          this.waveSurfer.load(this.audioUrl);
          this.haveAudio = true;
        };
      }).catch(err => {
        console.error('Error al acceder al micrófono:', err);
      });
    } else {
      console.warn('El navegador no soporta la grabación de medios.');
    }
  }

  // Llamada para detener la grabación
  stopRecording() {
    if (this.isRecording && this.mediaRecorder) {
      this.mediaRecorder.stop(); // Detener la grabación
      this.isRecording = false;
    }
  }

  // Función para reproducir el audio con visualización de progreso
  playAudio() {
    if (this.waveSurfer) {
      this.waveSurfer.playPause(); // Alterna entre reproducir y pausar
    }
  }

  deleteAudio() {
    if (this.waveSurfer) {
      this.waveSurfer.empty();
      this.audioUrl = '';
      this.haveAudio = false;
      this.audioBlob = null;
    }
  }

  playAudioShow() {
    if (this.waveSurferShow) {
      this.waveSurferShow.playPause(); // Alterna entre reproducir y pausar
    }
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Formatear con dos dígitos (e.g., 03:04)
    const minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
    const secondsString = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds.toString();

    return minutesString + ':' + secondsString;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  protected readonly Math = Math;
}
