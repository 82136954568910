
export class SearchFlashcardDto {
  reference: string;
  corps: number[];
  statement: string;
  answer: string;
  subject: number[];
  topic: number[];
  difficulty: number[];

  constructor() {
  }
}
