import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {TranslationEntity} from "../../../model/TranslationEntity";
import {TranslationService} from "../../../service/TranslationService";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {CourseTypeEntity} from "../../../model/CourseTypeEntity";
import {ModalityEntity} from "../../../model/ModalityEntity";
import {SiteEntity} from "../../../model/SiteEntity";
import {TurnEntity} from "../../../model/TurnEntity";

@Component({
  selector: 'app-translation',
  templateUrl: 'translation.html',
  styleUrls: ['translation.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Translation {

  translations: TranslationEntity[];

  courses: CourseEntity[];
  coursesType: CourseTypeEntity[];
  modalities: ModalityEntity[];
  sites: SiteEntity[];
  turns: TurnEntity[];

  showTable = true;

  newEntity: TranslationEntity;
  modeAdd = false;

  headers: any = {
    columns: {
      corpId: {title: 'Statement', hide: true, filter: false},
      id: {title: 'Statement', hide: true, filter: false},
      language: {title: 'Language', hide: false, filter: true, type: "html"},
      subLanguage: {title: 'Sublanguage', hide: false, filter: true, type: "html"},
      word: {title: 'Word', hide: false, filter: true, type: "html"},
      translation: {
        title: 'Translation', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.translation = data.text;
              this.saveTranslation(translation);
            });
          }
        },
      },
      courseId: {
        title: 'Course', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.courses}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.courseId = data.id;
              this.saveTranslation(translation);
            });
          }
        },
      },
      courseTypeId: {
        title: 'Course type', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.coursesType}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.courseTypeId = data.id;
              this.saveTranslation(translation);
            });
          }
        },
      },
      modalityId: {
        title: 'Modality', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.modalities}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.modalityId = data.id;
              this.saveTranslation(translation);
            });
          }
        },
      },
      siteId: {
        title: 'Site', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.sites}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.siteId = data.id;
              this.saveTranslation(translation);
            });
          }
        },
      },
      turnId: {
        title: 'Turn', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.turns}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.turnId = data.id;
              this.saveTranslation(translation);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private translationService: TranslationService, private courseService: CourseService, private shared: Shared) {
  }

  ngOnInit() {
    this.translationService.getTranslations().subscribe( x=> {
      if(x!=null){
        this.translations = x;
      }
    });


    this.courseService.getCourses(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.courses = x;
      }
    });
    this.courseService.getCoursesType(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.coursesType = x;
      }
    });
    this.courseService.getModalities(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.modalities = x;
      }
    });
    this.courseService.getSites(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.sites = x;
      }
    });
    this.courseService.getTurns(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.turns = x;
      }
    });

  }

  async saveTranslation(translation: TranslationEntity) {
    this.translationService.saveTranslation(translation).subscribe( t =>{
        if(t!=null) {
          this.translations.push(t);
          this.translations = this.translations.filter(x => {return x});
        }
    });
  }

  async saveNewTranslation() {
    this.saveTranslation(this.newEntity);
    this.modeAdd = false;
  }

  newTranslation(){
    this.modeAdd = true;
    this.newEntity = new TranslationEntity();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
