import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {ChatSupportRoutingModule} from "./chatSupport-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {ChatSupport} from "./chatSupport";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {ContextService} from "../../../service/ContextService";
import {ImagePicker} from "@awesome-cordova-plugins/image-picker/ngx";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ChatSupportRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    DlDateTimePickerModule,

  ],
  providers: [ContextService, ImagePicker],
  exports: [
    ChatSupport
  ],
  declarations: [ChatSupport]
})
export class ChatSupportModule {}
