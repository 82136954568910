import {CorporationEntity} from "./CorporationEntity";
import {ExamEntity} from "./ExamEntity";

export class TranslationEntity {
  corpId: CorporationEntity;
  id: number;
  language: string;
  subLanguage: string;
  word: string;
  translation: string;

  courseId: number;
  courseTypeId: number;
  modalityId: number;
  siteId: number;
  turnId: number;
}
