import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {Video, VjsPlayerComponent} from './video';
import {VideoRoutingModule} from "./video-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {SubjectService} from "../../../service/SubjectService";
import {VideoService} from "../../../service/VideoService";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {SwiperModule} from "swiper/angular";
import {NgToggleModule} from "ng-toggle-button";
import {LibraryImagesModule} from "../../libraryImages/libraryImages.module";
import {UserService} from "../../../service/UserService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    VideoRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    NgxExtendedPdfViewerModule,
    SwiperModule,
    NgToggleModule,
    LibraryImagesModule,
  ],
  providers: [SubjectService, VideoService, UserService],
  exports: [
    Video
  ],
  declarations: [Video, VjsPlayerComponent]
})
export class VideoModule {}
