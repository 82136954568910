import {Component} from '@angular/core';
import {Shared} from "../shared/shared";

@Component({
  selector: 'app-toolbar',
  templateUrl: 'toolbar.html',
  styleUrls: ['toolbar.scss']
})
export class Toolbar {

  constructor(private shared: Shared) {}


  corpSelectedChange(ev){
    this.shared.CORP_ID = ev.id;
    this.shared.CORP_ALIAS = ev.alias;
    this.shared.CURRENT_CORP = ev;

    localStorage.setItem('CURRENT_CORP', JSON.stringify(this.shared.CURRENT_CORP));
    window.location.reload();
  }

}
