import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import { SurveyEntity } from 'src/app/model/SurveyEntity';
import {SurveyService} from "../../../../service/SurveyService";
import {CalendarComponent} from "../../generic/table/calendarComponent/calendarComponent";
import * as moment from "moment/moment";

@Component({
  selector: 'app-survey',
  templateUrl: 'survey.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Survey {

  @Input() surveys: SurveyEntity[] = [];

  @Output() filter = new EventEmitter<number>();

  newEntity: SurveyEntity;
  modeAdd = false;

  showStartDate = false;
  showEndDate = false;

  headers: any = {
    columns: {
      reference: {title: 'Reference', hide: false, filter: false},
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let survey = this.surveys.find(x => x.id == data.row.id)
              survey.name = data.text;
              this.saveSurvey(survey, false);
            });
          }
        },
      },
      startDate: {
        title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let survey = this.surveys.find(x => x.id == data.row.id)
              survey.startDate = data.date;
              this.saveSurvey(survey, false);
            });
          }
        },
      },
      endDate: {
        title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let survey = this.surveys.find(x => x.id == data.row.id)
              survey.endDate = data.date;
              this.saveSurvey(survey, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let survey = this.surveys.find(x => x.id == data.row.id)
              survey.showInMagnament = data.bool;
              this.saveSurvey(survey, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let survey = this.surveys.find(x => x.id == data.row.id)
              survey.showInMagnament = data.bool;
              this.saveSurvey(survey, false);
            });
          }
        },
      },

      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.insideSurvey(data);
            });
          }
        },
      },
      corpId: {title: '', hide: true, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: row.id, icon: 'cube-outline', text: 'Package', color: '#1c8aa5'}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.insideSurvey(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private surveyService: SurveyService, private shared: Shared) {
  }

  ngOnInit() {
  }

  insideSurvey(id: number){
    this.filter.emit(id);
  }

  saveSurvey(survey: SurveyEntity,bool) {
    this.surveyService.saveSurvey(survey).subscribe( t =>{
      if(t!=null) {
        if(bool){this.surveys.push(t);}
        this.surveys = this.surveys.filter(x => {return x});
      }
    });
  }

  async saveNewSurvey() {
    this.saveSurvey(this.newEntity,true);
    this.modeAdd = false;
  }

  newSurvey(){
    this.modeAdd = true;
    this.newEntity = new SurveyEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

  changeStartDate(event){
    this.newEntity.startDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event){
    this.newEntity.endDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }


}
