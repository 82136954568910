
export class CourseFrontPageEntity {
  corpId: number;
  id: number;
  courseId: number;
  courseTypeId: number;
  modalityId: number;
  siteId: number;
  turnId: number;
  html: string;
  backgroundImage: string;
  subscriptionImage: string;
  enabled: boolean;
}
