import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {ForumService} from "../../../../service/ForumService";
import {SingleThread} from "./singleThread";
import {SingleMessage} from "../singleMessage/singleMessage";
import {TableModule} from "../../generic/table/table.module";


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    DlDateTimePickerModule,
    NgSelectModule,
    NgbModule,
    AngularEditorModule,
    TableModule
  ],
  providers: [ForumService],
  exports: [
    SingleThread
  ],
    declarations: [SingleThread, SingleMessage]
})
export class SingleThreadModule {}
