import {Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {SubjectService} from "../../../service/SubjectService";

@Component({
  selector: 'app-subjects-data',
  templateUrl: 'subjectsData.html',
  styleUrls: ['subjectsData.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubjectsData {

  subjects: SubjectEntity[];
  topics: TopicEntity[];

  @ViewChild('topicHeader') topicHeader:ElementRef;

  constructor(private subjectService: SubjectService, private shared: Shared, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    (await this.subjectService.getSubjects()).subscribe(x => {
      if (x != null) {
        this.subjects = x;
      }
    });
    (await this.subjectService.getTopics()).subscribe(x => {
      if (x != null) {
        this.topics = x;
      }
    });
  }

  filterTopic(id: number){
    this.subjectService.getTopicsBySubject(id).subscribe(x => {
      if (x != null) {
        this.topics = x;
        this.renderer.selectRootElement('#topicHeader').click();
      }
    });
  }
}
