import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SurveyData } from './surveyData';
import {SurveyDataRoutingModule} from "./surveyData-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {SurveyItem} from "./surveyItem/surveyItem";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {ForumService} from "../../../service/ForumService";
import {Survey} from "./survey/survey";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {SurveyService} from "../../../service/SurveyService";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        SurveyDataRoutingModule,
        NgxDatatableModule,
        TableModule,
        DlDateTimePickerModule,
        NgSelectModule,
        NgbModule,
        AngularEditorModule
    ],
  providers: [SurveyService],
  exports: [
    SurveyData
  ],
    declarations: [SurveyData, SurveyItem, Survey]
})
export class SurveyDataModule {}
