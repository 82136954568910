import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {TypeOfEventEntity} from "../../../../model/TypeOfEventEntity";

@Component({
  selector: 'app-types-of-event',
  templateUrl: 'typesOfEvent.html',
  styleUrls: ['typesOfEvent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TypesOfEvent {

  @Input() typeOfEvents: TypeOfEventEntity[];
  @Input() id: number;

  constructor(private shared: Shared) {
  }

  ngOnInit() {
  }

  getType(){
    return this.typeOfEvents.find(x=>x.id === this.id);
  }

}
