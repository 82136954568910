import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Users } from './users';
import {UsersRoutingModule} from "./users-routing.module";
import {TableModule} from "../generic/table/table.module";
import {UserService} from "../../../service/UserService";
import {SwiperModule} from "swiper/angular";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimeDateModule, DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgToggleModule} from "ng-toggle-button";
import {StatePopover} from "./statePopover/statePopover";
import {TypePopover} from "./typePopover/typePopover";
import {NgCalendarModule} from "ionic2-calendar";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {RouterModule} from "@angular/router";
import { DatePipe } from '@angular/common';
import {FormatPopover} from "./formatPopover/formatPopover";


@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        UsersRoutingModule,
        TableModule,
        SwiperModule,
        NgSelectModule,
        DlDateTimeDateModule,
        DlDateTimePickerModule,
        NgToggleModule,
        NgCalendarModule,
        AngularEditorModule,
        PickerModule,
        RouterModule
    ],
  providers: [UserService, DatePipe],
  exports: [
    Users
  ],
  declarations: [Users, StatePopover, TypePopover, FormatPopover]
})
export class UsersModule {}
