import {Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {ModalityEntity} from "../../../model/ModalityEntity";
import {CourseTypeEntity} from "../../../model/CourseTypeEntity";
import {SiteEntity} from "../../../model/SiteEntity";
import {TurnEntity} from "../../../model/TurnEntity";
import {SubjectService} from "../../../service/SubjectService";

@Component({
  selector: 'app-courses-data',
  templateUrl: 'coursesData.html',
  styleUrls: ['coursesData.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoursesData {

  courses: CourseEntity[];
  coursesType: CourseTypeEntity[];
  modalities: ModalityEntity[];
  sites: SiteEntity[];
  turns: TurnEntity[];

  @ViewChild('courseTypeHeader') courseTypeHeader:ElementRef;

  constructor(private courseService: CourseService, private shared: Shared, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.courseService.getCourses(this.shared.CORP_ID).subscribe(x1 => {
      if (x1 != null) {
        this.courses = x1;
      }
    });
    this.courseService.getCoursesType(this.shared.CORP_ID).subscribe(x2 => {
      if (x2 != null) {
        this.coursesType = x2;
      }
    });
    this.courseService.getModalities(this.shared.CORP_ID).subscribe(x3 => {
      if (x3 != null) {
        this.modalities = x3;
      }
    });
    this.courseService.getSites(this.shared.CORP_ID).subscribe(x4 => {
      if (x4 != null) {
        this.sites = x4;
      }
    });
    this.courseService.getTurns(this.shared.CORP_ID).subscribe(x5 => {
      if (x5 != null) {
        this.turns = x5;
      }
    });
  }



  filterCourseType(id: number){
    this.courseService.getCoursesTypeByCourse(id).subscribe(x => {
      if (x != null) {
        this.coursesType = x;
        this.renderer.selectRootElement('#courseTypeHeader').click();
      }
    });
  }

  filterModality(id: number){
    this.courseService.getModalitiesByCourseType(id).subscribe(x => {
      if (x != null) {
        this.modalities = x;
        this.renderer.selectRootElement('#modalityHeader').click();
      }
    });
  }

  filterSite(id: number){
    this.courseService.getSitesByModality(id).subscribe(x => {
      if (x != null) {
        this.sites = x;
        this.renderer.selectRootElement('#siteHeader').click();
      }
    });
  }

  filterTurn(id: number){
    this.courseService.getTurnsBySite(id).subscribe(x => {
      if (x != null) {
        this.turns = x;
        this.renderer.selectRootElement('#turnHeader').click();
      }
    });
  }
}
