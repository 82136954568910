import {SubjectEntity} from "./SubjectEntity";

export class TopicEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  subjectId: number;
  showInCampus: boolean;
  showInMagnament: boolean;
}
