export class BasicDto {
  id: number;
  alias: string;
  name: string;
  list: BasicDto[];

  constructor(id: number, alias: string, name: string, list: BasicDto[]) {
    this.id = id;
    this.alias = alias;
    this.name = name;
    this.list = list;
  }


}
