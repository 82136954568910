export class  ImportQuestionDto {
  txt: string;
  corpId: number;
  examSettingId: number;


  constructor() {
  }
}

