import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {CourseTypeEntity} from "../../../model/CourseTypeEntity";
import {ModalityEntity} from "../../../model/ModalityEntity";
import {SiteEntity} from "../../../model/SiteEntity";
import {TurnEntity} from "../../../model/TurnEntity";
import {PricetableEntity} from "../../../model/PricetableEntity";
import {ContextService} from "../../../service/ContextService";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {DropdownMComponent} from "../generic/table/dropdownMComponent/dropdownMComponent";
import {BankService} from "../../../service/BankService";
import {BankPackageEntity} from "../../../model/BankPackageEntity";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'app-pricetable',
  templateUrl: 'pricetable.html',
  styleUrls: ['pricetable.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Pricetable {

  pricetables: PricetableEntity[];

  courses: CourseEntity[];
  coursesType: CourseTypeEntity[];
  modalities: ModalityEntity[];
  sites: SiteEntity[];
  turns: TurnEntity[];

  showTable = true;
  bankPackages: BankPackageEntity[];

  newEntity: PricetableEntity;
  modeAdd = false;
  current: PricetableEntity = null;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  headers: any = {
    columns: {
      corpId: {title: 'corp', hide: true, filter: false},
      id: {title: 'id', hide: true, filter: false},
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.name = data.text;
              this.savePrice(price, false);
            });
          }
        },
      },
      description:
        {title: 'Description', hide: false, filter: false, type: "custom",
          valuePrepareFunction: (cell, row) => {
            return {cell: row.id, icon: 'pencil-outline', text: '', color: ''}
          },
          renderComponent: ButtonComponent,
          onComponentInitFunction: (instance) => {
            if (instance != null) {
              instance.changeButton.subscribe(data => {
                this.showEditDescription(data);
              });
            }
          },
      },
      price: {title: 'Price', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.price = data.text;
              this.savePrice(price, false);
            });
          }
        },
      },
      enrollment: {title: 'Enrollment', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.enrollment = data.text;
              this.savePrice(price, false);
            });
          }
        },
      },
      monhtly: {title: 'Monthly', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.monthly = data.text;
              this.savePrice(price, false);
            });
          }
        },
      },
      numOfMonths: {title: 'Num of months', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.numOfMonths = data.text;
              this.savePrice(price, false);
            });
          }
        },
      },
      totality: {
        title: 'Totality', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.totality = data.bool;
              this.savePrice(price, false);
            });
          }
        },
      },
      totalityPercentaje: {title: 'Totality %', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.totalityPercentaje = data.text;
              this.savePrice(price, false);
            });
          }
        },
      },
      currency: {title: 'Currency', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.currency = data.text;
              this.savePrice(price, false);
            });
          }
        },
      },
      courseId: {
        title: 'Course', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.courses}; console.log(this.courses)},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.courseId = data.id;
              this.savePrice(price, false);
            });
          }
        },
      },
      courseTypeId: {
        title: 'Course type', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.coursesType}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.courseTypeId = data.id;
              this.savePrice(price, false);
            });
          }
        },
      },
      modalityId: {
        title: 'Modality', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.modalities}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.modalityId = data.id;
              this.savePrice(price, false);
            });
          }
        },
      },
      siteId: {
        title: 'Site', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.sites}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.siteId = data.id;
              this.savePrice(price, false);
            });
          }
        },
      },
      turnId: {
        title: 'Turn', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.turns}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.turnId = data.id;
              this.savePrice(price, false);
            });
          }
        },
      },
      multisubscription: {
        title: 'MultiSub', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.courses}},
        renderComponent: DropdownMComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.multisubscription = data.id;
              this.savePrice(price, false);
            });
          }
        },
      },
      bankPackageId: {
        title: 'Bank package', hide: false, filter: true, width: '180px', type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.bankPackages}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let price = this.pricetables.find(x => x.id == data.row.id)
              price.bankPackageId = data.id;
              this.savePrice(price, false);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private contextService: ContextService, private courseService: CourseService, private bankService: BankService, private shared: Shared) {
  }

  ngOnInit() {
    this.contextService.getMultisubscriptions().subscribe( t=> {
      if(t!=null){
      this.contextService.getPrices().subscribe( x=> {
        if(x!=null){
          this.pricetables = x;
          this.pricetables.map(z=> z.multisubscription = t.filter(m=> m.tablePriceId == z.id).map(n=> n.courseId));
        }
      });
      }
    });

    this.bankService.getBankPackages().subscribe(x => {
      if(x!=null){
        this.bankPackages = x;
      }
    });

    this.courseService.getTurns(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.turns = x;
        this.courseService.getSites(this.shared.CORP_ID).subscribe(x => {
          if (x != null) {
            this.sites = x;
            this.courseService.getModalities(this.shared.CORP_ID).subscribe(x => {
              if (x != null) {
                this.modalities = x;
                this.courseService.getCoursesType(this.shared.CORP_ID).subscribe(x => {
                  if (x != null) {
                    this.coursesType = x;
                    this.courseService.getCourses(this.shared.CORP_ID).subscribe(x => {
                      if (x != null) {
                        this.courses = x;
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

  }

  async savePrice(pricetable: PricetableEntity, isNew: boolean) {
    let obj = {
      'priceTable': pricetable,
      'multisubscription': pricetable.multisubscription
    }
    this.contextService.savePrice(obj).subscribe( t =>{
        if(t!=null) {
          if(isNew){
            this.pricetables.push(t);
            this.pricetables = this.pricetables.filter(x => {return x});
          }
        }
    });
    this.current = null;
  }

  showEditDescription(data){
    console.log(data);
    this.current = this.pricetables.find(x => x.id == data)
  }

  async saveNewPrice() {
    this.savePrice(this.newEntity, true);
    this.modeAdd = false;
  }

  newPrice(){
    this.modeAdd = true;
    this.newEntity = new PricetableEntity();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
