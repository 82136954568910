import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Workgroup} from "./workgroup";


const routes: Routes = [
  {
    path: 'workgroups',
    component: Workgroup,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkgroupRoutingModule {}
