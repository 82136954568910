import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";
import {LibraryEntity} from "./LibraryEntity";
import {VideoEntity} from "./VideoEntity";

export class VideoPlaylistEntity {
  corpId: number;
  id: number;
  enabled: boolean;
  name: string;
}
