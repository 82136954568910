import {Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {SubjectService} from "../../../service/SubjectService";
import {BankPackageEntity} from "../../../model/BankPackageEntity";
import {BankItemEntity} from "../../../model/BankItemEntity";
import {BankService} from "../../../service/BankService";

@Component({
  selector: 'app-bank-data',
  templateUrl: 'bankData.html',
  styleUrls: ['bankData.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankData {

  bankPackages: BankPackageEntity[];
  bankItems: BankItemEntity[];
  selectedBankPackageId: number;

  @ViewChild('topicHeader') topicHeader:ElementRef;

  constructor(private bankService: BankService, private shared: Shared, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.bankService.getBankPackages().subscribe(x => {
      if (x != null) {
        this.bankPackages = x;
      }
    });
  }

  filterBankItems(id: number){
    this.selectedBankPackageId = id;
    this.bankService.getBankItems(id).subscribe(x => {
      if (x != null) {
        this.bankItems = x;
        this.renderer.selectRootElement('#topicHeader').click();
      }
    });
  }
}
