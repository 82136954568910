import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {LibraryFolderEntity} from "../model/LibraryFolderEntity";
import {LibraryEntity} from "../model/LibraryEntity";
import {VideoEntity} from "../model/VideoEntity";
import {VideoFolderEntity} from "../model/VideoFolderEntity";
import {VideoCommentEntity} from "../model/VideoCommentEntity";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class VideoService extends ParentService{

  public getVideoFolders(obj){
      return this.http.post<VideoFolderEntity[]>(this.shared.MAGNAMENT_URL+'/getVideoFolders', obj, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getVideoFolder(corpId){
    return this.http.get<VideoFolderEntity[]>(this.shared.MAGNAMENT_URL+'/getVideoFolders/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getVideos(vidFolderId){
    return this.http.get<VideoEntity[]>(this.shared.MAGNAMENT_URL+'/getVideos/'+vidFolderId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveVideoFolder(folder){
    return this.http.post<VideoFolderEntity>(this.shared.MAGNAMENT_URL+'/saveVideoFolder',folder, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveVideo(video){
    return this.http.post<VideoEntity>(this.shared.MAGNAMENT_URL+'/saveVideo/'+this.shared.CORP_ID,video, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getVideo(reference){
    return this.http.get<VideoEntity>(this.shared.MAGNAMENT_URL+'/getVideo/'+reference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getAllVideos(corpId){
    return this.http.get<VideoEntity[]>(this.shared.MAGNAMENT_URL+'/getAllVideos/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public searchAllVideos(obj){
    return this.http.post<VideoEntity[]>(this.shared.MAGNAMENT_URL+'/searchAllVideos', obj, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public updateVideoByTitle(title){
    return this.http.get<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/updateVideoByTitle/'+title+'/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadVideoToVimeo(file, title){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadVideo/'+title+'/'+this.shared.CORP_ID, formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadTranscriptionDocument(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadTranscriptionDocument', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
}
