import {Component} from '@angular/core';
import {Shared} from "../../shared/shared";
import {RegisterLinkEntity} from "../../../model/RegisterLinkEntity";
import {BasicDto} from "../../../dto/BasicDto";
import {UserService} from "../../../service/UserService";
import {CommonUserDto} from "../../../dto/CommonUserDto";
import {CouponEntity} from "../../../model/CouponEntity";
import * as moment from "moment";
import * as copy from "copy-to-clipboard";
import {RegisterLinkObjEntity} from "../../../model/RegisterLinkObjEntity";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {CalendarComponent} from "../generic/table/calendarComponent/calendarComponent";

@Component({
  selector: 'app-register-links',
  templateUrl: 'registerLinks.html',
  styleUrls: ['registerLinks.scss']
})
export class RegisterLinks {

  rl: RegisterLinkObjEntity;
  registerLinks: RegisterLinkEntity[];

  basicData: BasicDto[];
  commonUserData: CommonUserDto;
  coupons: CouponEntity[];

  link: string;
  name: string;
  reference: string;

  courseIndex: number;
  courseTypeIndex: number;
  modalityIndex: number;
  siteIndex: number;

  showSubStartDate: boolean = false;
  showSubEndDate: boolean = false;

  showSubStartDateSub: boolean = false;
  showSubEndDateSub: boolean = false;

  modeAdd: boolean = false;
  showTable = true;

  headers: any = {
    columns: {
      corpId: {title: 'corpId', hide: true, filter: false},
      id: {title: 'id', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let registerLink = this.registerLinks.find(x => x.id == data.row.id)
              registerLink.name = data.text;
              this.saveRegisterLink(registerLink);
            });
          }
        },
      },
      date: {title: 'Date', hide: false, filter: true,  type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let registerLink = this.registerLinks.find(x => x.id == data.row.id)
              registerLink.date = data.date;
              this.saveRegisterLink(registerLink);
            });
          }
        },
      },
      link: {title: 'Link', hide: false, filter: false,
        valuePrepareFunction: (statement: string) => {
          return statement.substring(0,130);
        }
      },
      options: {title: 'Options', hide: true, filter: false},
      enabled: {
        title: 'Enabled', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let registerLink = this.registerLinks.find(x => x.id == data.row.id)
              registerLink.enabled = data.bool;
              this.saveRegisterLink(registerLink);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private shared: Shared, private userService: UserService) {
  }

  ngOnInit() {
    this.userService.getRegisterLink().subscribe( t =>{
      if(t!=null) {
        this.registerLinks = t;
        console.log(this.registerLinks);
      }
    });
  }

  async addNew(){
    this.modeAdd = true;
    this.rl = new RegisterLinkObjEntity();

    this.userService.getRegisterLinkReference().subscribe(x=>{
      if(x!=null) {
        this.rl.reference = x.reference;
      }
    });

    this.rl.mobile = false;
    this.rl.nif = false;
    this.rl.address = false;
    this.rl.province = false;
    this.rl.town = false;
    this.rl.cp = false;
    this.rl.country = false;
    this.rl.university = false;
    this.rl.hospital = false;

    this.rl.mobileReq = false;
    this.rl.nifReq = false;
    this.rl.addressReq = false;
    this.rl.provinceReq = false;
    this.rl.townReq = false;
    this.rl.cpReq = false;
    this.rl.countryReq = false;
    this.rl.universityReq = false;
    this.rl.hospitalReq = false;
    this.rl.singleCourse = false;

    this.rl.payment = false;
    this.rl.testSubscription = false;
    this.rl.startDate = "";
    this.rl.endDate = "";
    this.rl.startDateSub = "";
    this.rl.endDateSub = "";

    (await this.userService.getBasicDataByCorp()).subscribe(async p => {
      if (p != null) {
        this.basicData = p;

        (await this.userService.getCommonUserData()).subscribe(t => {
          if (t != null) {
            this.commonUserData = t;
          }
        });

        this.userService.getCoupons().subscribe(z=>{
          if(z!=null) {
            this.coupons = z;
          }
        });
      }
    });
  }

  rowSelectedEmitter(row: RegisterLinkEntity) {
    this.link = row.link;
  }

  async saveRegisterLink(registerLink: RegisterLinkEntity) {
    this.userService.saveRegisterLink(registerLink).subscribe( t =>{
      if(t!=null) {
        let index = this.registerLinks.findIndex(x=> x.id == t.id);
        if(index != -1) {
          this.registerLinks[index] = t;
        }else{
          this.registerLinks.push(t);
        }
        this.registerLinks = this.registerLinks.filter(x => {return x});
      }
    });
  }

  generateLink(){
    this.link = this.shared.CURRENT_CORP.appUrl+'#/signup/'+btoa(JSON.stringify(this.rl));

    let rle: RegisterLinkEntity = new RegisterLinkEntity();
    rle.corpId = this.shared.CORP_ID;
    rle.reference = this.rl.reference;
    rle.name = this.name;
    rle.date = moment().format('yyyy-MM-DDTHH:mm:ss');
    rle.link = this.link;
    rle.options = JSON.stringify(this.rl);
    rle.enabled = true;

    this.saveRegisterLink(rle);
    this.modeAdd = false;
  }

  copyTo(){
    copy(this.link);
  }

  changeCourse(bool) {
    this.courseIndex = this.basicData.findIndex(x => x.id == this.rl.course);
    this.rl.course = this.basicData.find(x => x.id == this.rl.course).id;
    if (bool) {
      this.courseTypeIndex = null;
      this.modalityIndex = null;
      this.siteIndex = null;
      this.rl.courseType = null;
      this.rl.modality = null;
      this.rl.site = null;
      this.rl.turn = null;
    }
  }

  changeCourseType(bool) {
    this.courseTypeIndex = this.basicData[this.courseIndex].list.findIndex(x => x.id == this.rl.courseType);
    this.rl.courseType = this.basicData[this.courseIndex].list.find(x => x.id == this.rl.courseType).id;
    if (bool) {
      this.modalityIndex = null;
      this.siteIndex = null;
      this.rl.modality = null;
      this.rl.site = null;
      this.rl.turn = null;
    }
  }

  changeModality(bool) {
    this.modalityIndex = this.basicData[this.courseIndex].list[this.courseTypeIndex].list.findIndex(x => x.id == this.rl.modality);
    this.rl.modality = this.basicData[this.courseIndex].list[this.courseTypeIndex].list.find(x => x.id == this.rl.modality).id;
    if (bool) {
      this.siteIndex = null;
      this.rl.site = null;
      this.rl.turn = null;
    }
  }

  changeSite(bool) {
    console.log(this.basicData);

    this.siteIndex = this.basicData[this.courseIndex].list[this.courseTypeIndex].list[this.modalityIndex].list.findIndex(x => x.id == this.rl.site);
    this.rl.site = this.basicData[this.courseIndex].list[this.courseTypeIndex].list[this.modalityIndex].list.find(x => x.id == this.rl.site).id;

    if (bool) {
      this.rl.turn = null;
    }
  }

  changeTurn() {
    this.rl.turn = this.basicData[this.courseIndex].list[this.courseTypeIndex].list[this.modalityIndex].list[this.siteIndex].list.find(x => x.id == this.rl.turn).id;
  }


  deleteStartDate(){
    this.rl.startDate = null;
  }

  deleteEndDate(){
    this.rl.endDate = null;
  }

  deleteStartDateSub(){
    this.rl.startDateSub = null;
  }

  deleteEndDateSub(){
    this.rl.endDateSub = null;
  }

  saveStartDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.rl.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.rl.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveStartDateSub(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.rl.startDateSub = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDateSub(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.rl.endDateSub = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }
}
