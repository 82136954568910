
export class LogEntity {
  corpId: number;
  managerId: number;
  dateTime: string;
  ip: string;
  url: string;
  device: string;
  body: string;

  constructor() {
  }

}
