
export class OriginEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  startDate: string;
  endDate: string;
  showInCampus: boolean;
  showInMagnament: boolean;


  constructor(id: number) {
    this.id = id;
  }
}
