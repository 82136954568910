import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Exams } from './exams';
import {ExamsRoutingModule} from "./exams-routing.module";
import {TableModule} from "../generic/table/table.module";
import {ExamService} from "../../../service/ExamService";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgToggleModule} from "ng-toggle-button";
import {NgSelectModule} from "@ng-select/ng-select";
import {UserService} from "../../../service/UserService";
import {QuestionsModule} from "../questions/questions.module";
import {StatisticsService} from "../../../service/StatisticsService";
import {LibraryImagesModule} from "../../libraryImages/libraryImages.module";
import {SafeHtmlPipe} from "../../../pipe/safe-html.pipe";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {SwiperModule} from "swiper/angular";


@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ExamsRoutingModule,
        TableModule,
        DlDateTimePickerModule,
        NgToggleModule,
        NgSelectModule,
        QuestionsModule,
        LibraryImagesModule,
        NgxExtendedPdfViewerModule,
        SwiperModule,
    ],
  providers: [ExamService, UserService, StatisticsService, SafeHtmlPipe],
  exports: [
    Exams
  ],
    declarations: [Exams, SafeHtmlPipe]
})
export class ExamsModule {}
