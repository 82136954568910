import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {SubjectService} from "../../../../service/SubjectService";
import {BankPackageEntity} from "../../../../model/BankPackageEntity";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {BankService} from "../../../../service/BankService";

@Component({
  selector: 'app-bank-package',
  templateUrl: 'bankPackage.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class BankPackage {

  @Input() bankPackages: BankPackageEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: BankPackageEntity;
  modeAdd = false;
  currencies = [
    {id: 'EUR', name: 'EUR'},
    {id: 'USD', name: 'USD'},
    {id: 'MXN', name: 'MXN'},
    {id: 'ARS', name: 'ARS'},
    {id: 'CRC', name: 'CRC'},
  ];

  headers: any = {
    columns: {
      corpId: {title: 'Statement', hide: true, filter: false},
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let bankPackage = this.bankPackages.find(x => x.id == data.row.id)
              bankPackage.name = data.text;
              this.saveBankPackage(bankPackage, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let bankPackage = this.bankPackages.find(x => x.id == data.row.id);
              bankPackage.showInMagnament = data.bool;
              this.saveBankPackage(bankPackage, false);
            });
          }
        },
      },
      currency: {title: 'Currency', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.currencies}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let bankPackage = this.bankPackages.find(x => x.id == data.row.id)
              bankPackage.currency = data.id;
              this.saveBankPackage(bankPackage, false);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
    },
    actions: false,
  };

  constructor(private bankService: BankService, private shared: Shared) {
  }

  ngOnInit() {
  }

  nextStep(id: number){
    this.filter.emit(id);
  }

  saveBankPackage(bankPackage: BankPackageEntity,bool) {
    this.bankService.saveBankPackage(bankPackage).subscribe( t =>{
      if(t!=null) {
        if(bool){this.bankPackages.push(t);}
        this.bankPackages = this.bankPackages.filter(x => {return x});
      }
    });
  }

  async saveNewBankPackage() {
    this.saveBankPackage(this.newEntity,true);
    this.modeAdd = false;
  }

  newBankPackage(){
    this.modeAdd = true;
    this.newEntity = new BankPackageEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

}
