import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StepsData } from './stepsData';
import {StepsDataRoutingModule} from "./stepsData-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {StepContent} from "./stepContents/stepContent";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {SubjectService} from "../../../service/SubjectService";
import {Step} from "./step/step";
import {CalendarService} from "../../../service/CalendarService";
import {Package} from "./packages/package";
import {TypesOfEvent} from "../generic/typesOfEvent/typesOfEvent";
import {VideoService} from "../../../service/VideoService";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {LibraryService} from "../../../service/LibraryService";
import {ExamService} from "../../../service/ExamService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    StepsDataRoutingModule,
    NgxDatatableModule,
    TableModule,
    DlDateTimePickerModule,
    NgSelectModule,
    NgbModule
  ],
  providers: [SubjectService, CalendarService, VideoService, LibraryService, ExamService],
  exports: [
    StepsData
  ],
    declarations: [StepsData, StepContent, Step, Package, TypesOfEvent]
})
export class StepsDataModule {}
