
export class StudentEntity {
  corpId: number;
  corpGroupId: number;
  reference: string
  id: number;
  name: string;
  surname: string;
  mobile: string;
  email: string;
  password: string;
  nif: string;
  address: string;
  province: string;
  town: string;
  cp: string;
  country: number;
  universityId: number;
  hospitalId: number;
  profileImage: string;
  privacy1: boolean;
  privacy2: boolean;

  created: string;
  updated: string;

  constructor() {
  }
}
