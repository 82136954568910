import {VideoEntity} from "./VideoEntity";
import {VideoFoldeCourseEntity} from "./VideoFolderCourseEntity";
import {VideoFolderSubfolderEntity} from "./VideoFolderSubfolderEntity";

export class  VideoFolderEntity {
  corpId: number;
  id: number;
  title: string;
  description: string;
  front: string;
  frontUrl: string;
  subjectId: number;
  topicId: number;
  folderParentId: number;
  folderParent: boolean;
  showInCampus: boolean;
  showInMagnament: boolean;
  list: VideoEntity[];
  videoFolderCourses: VideoFoldeCourseEntity[];
  videoFolderSubfolder: VideoFolderSubfolderEntity[];
}
