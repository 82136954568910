import {CorporationEntity} from "./CorporationEntity";
import {ExamEntity} from "./ExamEntity";
import {ExamSettingCourseEntity} from "./ExamSettingCourseEntity";
import {ExamSettingGroupEntity} from "./ExamSettingGroupEntity";

export class ExamSettingEntity {
  corpId: number;
  id: number;
  reference: string;
  alias: string;
  name: string;
  type: string;
  frontUrl: string;
  duration: number;
  questions: number;
  reservedQuestions: number;
  startDate: string;
  endDate: string;
  currentExam: ExamEntity;
  typeOfAnswers: boolean;
  typeOfResults: boolean;
  typeOfContent: string;
  typeOfExamSkin: string;

  courses: ExamSettingCourseEntity[];
  groupsId: number[];

  showInCampus: boolean;
  showInMagnament: boolean;
  successValue: number;
  wrongValue: number;
  approveValue: number;
  courseId: number;
  courseTypeId: number;
  modalityId: number;
  siteId: number;
  turnId: number;
}
