import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EmailTemplate} from "./emailTemplate";


const routes: Routes = [
  {
    path: 'emailTemplate',
    component: EmailTemplate,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailTemplateRoutingModule {}
