import {Component, Input} from '@angular/core';
import {Shared} from "../../shared/shared";
import {CalendarService} from "../../../service/CalendarService";
import {UserService} from "../../../service/UserService";
import {BasicDto} from "../../../dto/BasicDto";
import {StaticCalendarEntity} from "../../../model/StaticCalendarEntity";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {StaticCalendarEventEntity} from "../../../model/StaticCalendarEventEntity";
import {CalendarComponent} from "../generic/table/calendarComponent/calendarComponent";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import * as moment from "moment";
import {CalendarMode, Step} from "ionic2-calendar/calendar";
import {AlertController, PopoverController} from "@ionic/angular";
import {VideoService} from "../../../service/VideoService";
import {LibraryService} from "../../../service/LibraryService";
import {ExamService} from "../../../service/ExamService";
import {VideoFolderEntity} from 'src/app/model/VideoFolderEntity';
import {LibraryFolderEntity} from "../../../model/LibraryFolderEntity";
import {ExamSettingEntity} from "../../../model/ExamSettingEntity";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {TypeOfEventEntity} from "../../../model/TypeOfEventEntity";
import {SubjectService} from "../../../service/SubjectService";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SearchEventDto} from "../../../dto/SearchEventDto";
import {CourseStructureDto} from "../../../dto/CourseStructureDto";
import {ModuleEntity} from "../../../model/ModuleEntity";
import {ModuleFolderEntity} from "../../../model/ModuleFolderEntity";
import {ModuleItemEntity} from "../../../model/ModuleItemEntity";
import {ModuleService} from "../../../service/ModuleService";


@Component({
  selector: 'app-module',
  templateUrl: 'module.html',
  styleUrls: ['module.scss']
})
export class Module {

  modules: ModuleEntity[];
  moduleFolders: ModuleFolderEntity[];
  moduleItems: ModuleItemEntity[];

  current: ModuleEntity;
  currentFolder: ModuleFolderEntity;
  currentVideo: ModuleItemEntity;

  availableVideos: CourseStructureDto[];
  availableLibraries: CourseStructureDto[];
  availableExams: CourseStructureDto[];
  availableCourseStructure: CourseStructureDto[];

  basicData: BasicDto[];

  modeAdd: boolean = false;
  modeFolderAdd: boolean = false;
  modeItemAdd: boolean = false;

  showStartDate = false;
  showEndDate = false;

  newEntity: ModuleEntity;
  newFolderEntity: ModuleFolderEntity;
  newItemEntity: ModuleItemEntity;

  showVideos: boolean = false;
  showExams: boolean = false;
  showDocuments: boolean = false;

  typeOfEvents: TypeOfEventEntity[];

  headers: any = {
    columns: {
      name: {
        title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let cal = this.modules.find(x => x.id == data.row.id)
              cal.name = data.text;
              this.saveModule(cal, false);
            });
          }
        },
      },
      startDate: {
        title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let obj = this.modules.find(x => x.id == data.row.id)
              obj.startDate = data.date;
              this.saveModule(obj, false);
            });
          }
        },
      },
      endDate: {
        title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let obj = this.modules.find(x => x.id == data.row.id)
              obj.endDate = data.date;
              this.saveModule(obj, false);
            });
          }
        },
      },
      courseId: {
        title: 'Course', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, options: this.basicData}
        },
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let obj = this.modules.find(x => x.id == data.row.id)
              obj.courseId = data.id;
              this.saveModule(obj, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let cal = this.modules.find(x => x.id == data.row.id)
              cal.showInCampus = data.bool;
              this.saveModule(cal, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let cal = this.modules.find(x => x.id == data.row.id)
              cal.showInMagnament = data.bool;
              this.saveModule(cal, false);
            });
          }
        },
      },
      id: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.openModule(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private popoverCtrl: PopoverController, private shared: Shared, private modalService: NgbModal, private moduleService: ModuleService, private calendarService: CalendarService, private userService: UserService) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    (await this.userService.getBasicDataByCorp()).subscribe(p => {
      if (p != null) {
        this.basicData = p;

        this.moduleService.getModules(this.shared.CORP_ID).subscribe(x => {
          if (x != null) {
            this.modules = x;
          }
        });

        this.calendarService.getTypeOfEvents().subscribe(x => {
          if (x != null) {
            this.typeOfEvents = x;
          }
        });
      }
    });
  }

  openModule(moduleId) {
    this.current = this.modules.find(x => x.id == moduleId);

    this.availableCourseStructure = null;
    this.calendarService.getCourseStructure(this.current.courseId).subscribe(x => {
      if (x != null) {
        this.availableCourseStructure = x;
        this.availableExams = x.filter(t => t.type == 'EXAM');
        this.availableVideos = x.filter(t => t.type == 'VIDEO_FOLDER');
        this.availableLibraries = x.filter(t => t.type == 'LIBRARY_FOLDER');
      }
    });

    this.moduleService.getModuleFolders(moduleId).subscribe(x => {
      if (x != null) {
        this.moduleFolders = x;
      }
    });
  }

  openModuleFolder(moduleFolderId) {
    this.moduleService.getModuleItems(moduleFolderId).subscribe(x => {
      if (x != null) {
        this.moduleItems = x;
      }
    });
  }

  newModule() {
    this.newEntity = new ModuleEntity();
    this.modeAdd = true;
  }

  newModuleFolder() {
    this.newFolderEntity = new ModuleFolderEntity();
    this.modeFolderAdd = true;
    this.newFolderEntity.folderOrder = this.moduleFolders.length + 1;
  }

  newModuleItem(folderId) {
    this.newItemEntity = new ModuleItemEntity();
    this.modeItemAdd = true;
    this.newItemEntity.moduleFolderId = folderId;
  }

  saveNewModule() {
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
    this.saveModule(this.newEntity, true);
    this.modeAdd = false;
  }

  saveNewModuleFolder() {
    this.newFolderEntity.corpId = this.shared.CORP_ID;
    this.newFolderEntity.showInMagnament = true;
    this.saveModuleFolder(this.newFolderEntity, true);
    this.modeFolderAdd = false;
  }

  saveNewModuleItem() {
    this.newItemEntity.corpId = this.shared.CORP_ID;
    this.newItemEntity.showInCampus = true;
    this.newItemEntity.showInMagnament = true;
    this.newItemEntity.itemOrder = this.moduleFolders.find(t => t.id == this.newItemEntity.moduleFolderId).items.length + 1;
    this.saveModuleItem(this.newItemEntity, true);
    this.modeItemAdd = false;
  }

  saveModule(module: ModuleEntity, bool: Boolean) {
    this.moduleService.saveModule(module).subscribe(t => {
      if (t != null) {
        if (bool) {
          this.modules.push(t);
        }
        this.modules = this.modules.filter(x => {
          return x
        });
      }
    });
  }

  saveModuleFolder(moduleFolder: ModuleFolderEntity, bool: Boolean) {
    this.moduleService.saveModuleFolder(moduleFolder).subscribe(t => {
      if (t != null) {
        if (bool) {
          this.moduleFolders.push(t);
        }
        this.moduleFolders = this.moduleFolders.filter(x => {
          return x
        });
      }
    });
  }

  saveModuleItem(moduleItem: ModuleItemEntity, bool: Boolean) {
    this.moduleService.saveModuleItem(moduleItem).subscribe(t => {
      if (t != null) {
        if (bool) {
          this.moduleFolders.find(t => t.id == moduleItem.moduleFolderId).items.push(t);
        }
      }
    });
  }

  deleteModuleFolder(folderId) {
    this.moduleService.deleteModuleFolder(folderId).subscribe();
  }

  deleteModuleItem(itemId) {
    this.moduleService.deleteModuleItem(itemId).subscribe();
  }

  reorderItems(ev, list) {
    if (ev.srcElement.id == "item") {
      const itemMove = list.splice(ev.detail.from, 1)[0];
      list.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();

      list = list.map((element, index) => {
        element.itemOrder = index + 1;
        return element;
      })

      this.moduleService.saveModuleItems(list).subscribe(t => {
        if (t != null) {
          list = list.filter(f => f != undefined);
        }
      });
    }
  }

  reorderFolders(ev) {
    console.log(ev);
    if (ev.srcElement.id == "folder") {
      const itemMove = this.moduleFolders.splice(ev.detail.from, 1)[0];
      this.moduleFolders.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();

      this.moduleFolders = this.moduleFolders.map((element, index) => {
        element.folderOrder = index + 1;
        return element;
      })

      this.moduleService.saveModuleFolders(this.moduleFolders).subscribe(t => {
        if (t != null) {
          this.moduleFolders = this.moduleFolders.filter(f => f != undefined);
        }
      });
    }
  }

  changeStartDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newEntity.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newEntity.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  getExam(pack: ModuleItemEntity) {
    return this.availableExams.find(x => x.reference === pack.reference);
  }

  getVideo(pack: ModuleItemEntity) {
    let fullList = [];

    this.availableVideos.forEach(x1 => {
      fullList.push(x1);
      if (x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if (x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return fullList.find(x => x.reference == pack.reference);
  }

  getDocument(pack: ModuleItemEntity) {
    let fullList = [];

    this.availableLibraries.forEach(x1 => {
      fullList.push(x1);
      if (x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if (x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return fullList.find(x => x.reference == pack.reference);
  }

  checkData(item: ModuleItemEntity, thisType) {
    let type = this.typeOfEvents.find(t => t.id == item.type);
    if (['EX', 'EC'].includes(type.alias) && thisType == 'EXAM') {
      return true;
    } else if (['CL', 'LC', 'TU', 'LT', 'VI'].includes(type.alias) && (thisType == 'VIDEO' || thisType == 'VIDEO_FOLDER')) {
      return true;
    } else if (['ST', 'RV', 'DO'].includes(type.alias) && (thisType == 'LIBRARY' || thisType == 'LIBRARY_FOLDER')) {
      return true;
    } else if (['QE'].includes(type.alias) && thisType == 'URL') {
      //this.item.auxType = 'URL';
      return true;
    }
    return false;
  }

  showData(item: ModuleItemEntity, template) {
    if (item.type == null) {
      return false;
    }
    this.showExams = this.checkData(item, 'EXAM');
    this.showVideos = this.checkData(item, 'VIDEO');
    this.showDocuments = this.checkData(item, 'LIBRARY');

    this.modalService.open(template, {centered: true});
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
