export class SurveySubitemEntity {
  corpId: number;
  id: number;
  surveyItemId: number;
  statement: string;
  order: number;
  showInCampus: boolean;
  showInMagnament: boolean;
}


