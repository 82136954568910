import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CourseEntity} from "../model/CourseEntity";
import {CourseTypeEntity} from "../model/CourseTypeEntity";
import {ModalityEntity} from "../model/ModalityEntity";
import {TurnEntity} from "../model/TurnEntity";
import {SiteEntity} from "../model/SiteEntity";
import {WorkgroupEntity} from "../model/WorkgroupEntity";
import {TopicEntity} from "../model/TopicEntity";
import {SubjectEntity} from "../model/SubjectEntity";
import { catchError } from 'rxjs/operators';
import {HttpErrorResponse} from "@angular/common/http";


@Injectable()
export class SubjectService extends ParentService{

  public async getSubjects() {
    const corps = await this.shared.getCorpGroups();
    return this.http.get<SubjectEntity[]>(this.shared.MAGNAMENT_URL+'/getSubjects/'+corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveSubject(subject) {
    return this.http.post<SubjectEntity>(this.shared.MAGNAMENT_URL+'/saveSubject', subject, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }




  public async getTopics() {
    const corps = await this.shared.getCorpGroups();
    return this.http.get<TopicEntity[]>(this.shared.MAGNAMENT_URL+'/getTopics/'+corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getTopicsBySubject(subjectId) {
    return this.http.get<TopicEntity[]>(this.shared.MAGNAMENT_URL+'/getTopicsBySubject/'+subjectId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveTopic(topic) {
    return this.http.post<TopicEntity>(this.shared.MAGNAMENT_URL+'/saveTopic', topic, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

}
