import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {TranslationEntity} from "../model/TranslationEntity";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class TranslationService extends ParentService{

  public getTranslations(){
    return this.http.get<TranslationEntity[]>(this.shared.MAGNAMENT_URL+'/getTranslations', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveTranslation(translation: TranslationEntity){
    return this.http.post<TranslationEntity>(this.shared.MAGNAMENT_URL+'/saveTranslation', translation, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

}
