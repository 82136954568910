import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {TopicEntity} from "../../../../model/TopicEntity";
import {SubjectEntity} from "../../../../model/SubjectEntity";
import {SubjectService} from "../../../../service/SubjectService";
import {StepEntity} from "../../../../model/StepEntity";
import {StepContentEntity} from "../../../../model/StepContentEntity";
import {CalendarService} from "../../../../service/CalendarService";
import {ForumEntity} from "../../../../model/ForumEntity";
import {ForumMessageEntity} from "../../../../model/ForumMessageEntity";
import {ForumService} from "../../../../service/ForumService";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ForumCategoriesEntity} from "../../../../model/ForumCategoriesEntity";
import {SurveyEntity} from "../../../../model/SurveyEntity";
import {SurveyItemEntity} from "../../../../model/SurveyItemEntity";
import {SurveyService} from "../../../../service/SurveyService";
import {VideoPlaylistVideoEntity} from "../../../../model/VideoPlaylistVideoEntity";
import {SurveySubitemEntity} from "../../../../model/SurveySubitemEntity";

@Component({
  selector: 'app-surveyItem',
  templateUrl: 'surveyItem.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class SurveyItem {

  @Input() survey: SurveyEntity;
  @Input() surveyItems: SurveyItemEntity[];

  newEntity: SurveyItemEntity;
  newSubItemEntity: SurveySubitemEntity;
  modeAdd = false;
  modeSubitemAdd = false;

  selectedSurveyItemId: Number;

  typeOfSurveyItems = [
    {id: 'TEXT', name: 'Simple text'},
    {id: 'STARS', name: 'Stars'},
    {id: 'OPTIONS', name: 'Options'},
    {id: 'OPTIONS_MULTIPLE', name: 'Options multiple'},
    {id: 'ARRAY', name: 'Array'},
  ];

  constructor(private surveyService: SurveyService, private shared: Shared) {
  }

  ngOnInit() {
  }

  saveSurveyItem(surveyItem: SurveyItemEntity, bool) {
    if(bool){this.surveyItems.push(surveyItem);}
    this.surveyService.saveSurveyItems(this.surveyItems).subscribe( t =>{
      if(t!=null) {
        this.surveyItems = this.surveyItems.filter(x => {return x});
        this.modeAdd = false;
      }
    });
  }

  saveSurveySubitem(surveySubitem: SurveySubitemEntity, bool) {
    if(bool){this.surveyItems.find(f=> f.id == surveySubitem.surveyItemId).subItems.push(surveySubitem);}
    this.surveyService.saveSurveyItems(this.surveyItems).subscribe( t =>{
      if(t!=null) {
        this.surveyItems = this.surveyItems.filter(x => {return x});
        this.modeAdd = false;
      }
    });
  }

  reorderItems(ev) {
    if (ev.srcElement.id == "folder") {
      const itemMove = this.surveyItems.splice(ev.detail.from, 1)[0];
      this.surveyItems.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();

      this.surveyItems = this.surveyItems.map((element, index) => {
        element.order = index + 1;
        return element;
      })

      this.surveyService.saveSurveyItems(this.surveyItems).subscribe(t => {
        if (t != null) {
          this.surveyItems = this.surveyItems.filter(f => f != undefined);
        }
      });
    }
  }

  reorderSubitems(ev, items) {
    if (ev.srcElement.id == "item") {
      let subitems = this.surveyItems.find(f=> f == items).subItems;
      const itemMove = subitems.splice(ev.detail.from, 1)[0];
      subitems.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();

      this.surveyItems.find(f=> f == items).subItems = subitems.map((element, index) => {
        element.order = index + 1;
        return element;
      })

      this.surveyService.saveSurveyItems(this.surveyItems).subscribe(t => {
        if (t != null) {
          this.surveyItems = this.surveyItems.filter(f => f != undefined);
        }
      });
    }
  }

  saveSubItem(i, s){
    this.surveyService.saveSurveyItems(this.surveyItems).subscribe( t =>{
      if(t!=null) {
        this.surveyItems = this.surveyItems.filter(x => {return x});
        this.modeAdd = false;
      }
    });
  }

  deleteSurveySubitem(i, id){
    this.surveyItems.find(t => t == i).subItems = this.surveyItems.find(t => t == i).subItems.filter(p=> p.id != id);
    this.surveyService.saveSurveyItems(this.surveyItems).subscribe( t =>{
      if(t!=null) {
        this.surveyItems = this.surveyItems.filter(x => {return x});
        this.modeAdd = false;
      }
    });
  }

  deleteSurveyItem(id){
    this.surveyItems = this.surveyItems.filter(x=> x.id != id);
    this.surveyService.deleteSurveyItem(id).subscribe();
  }

  newVideoItem() {
    this.modeAdd = true;
    this.newEntity = new SurveyItemEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.surveyId = this.survey.id;
    this.newEntity.showInCampus = true;
    this.newEntity.showInMagnament = true;
    if(this.surveyItems == null || this.surveyItems.length == 0){
      this.newEntity.order = 1;
    }else {
      this.newEntity.order = this.surveyItems.length + 1;
    }
  }

  newVideoSubitem(item) {
    this.modeSubitemAdd = true;
    this.newSubItemEntity = new SurveySubitemEntity();
    this.newSubItemEntity.corpId = this.shared.CORP_ID;
    this.newSubItemEntity.surveyItemId = item.id;
    this.newSubItemEntity.showInCampus = true;
    this.newSubItemEntity.showInMagnament = true;
    if(item.subItems == null || item.subItems.length == 0){
      this.newSubItemEntity.order = 1;
    }else {
      this.newSubItemEntity.order = item.subItems.length + 1;
    }

    item.subItems.push(this.newSubItemEntity);
  }

}
