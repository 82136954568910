export class ForumEntity {
  corpId: number;
  id: number;
  name: string;
  allowVideo: boolean;
  allowAudio: boolean;
  showInCampus: boolean;
  showInMagnament: boolean;
}


