export class StepEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  startDate: string;
  endDate: string;
  courseId: number;
  courseTypeId: number;
  modalityId: number;
  siteId: number;
  turnId: number;
  showInMagnament: boolean;
  activeDays: string;
  activeMorningFrom: string;
  activeMorningTo: string;
  activeAfternoonFrom: string;
  activeAfternoonTo: string;
}
