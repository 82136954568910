import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Questions } from './questions';

const routes: Routes = [
  {
    path: 'questions',
    component: Questions,
    children: [
      { path: ':reference', component: Questions },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionsRoutingModule {}
