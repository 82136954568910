
export class QuestionMultimediaEntity {
  corpId: number;
  id: number;
  questionId: number;
  title: string;
  type: string;
  position: string;
  fileUrl: string;
  showInCampus: boolean;
  showInMagnament: boolean;
  imageAux: string;
}
