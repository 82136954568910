import {Payment} from "./Payment";

export class SubscriptionPaymentsEntity {
  corpId: number;
  id: number;
  studentId: number;
  subscriptionId: number;
  reference: string;
  coursePrice: number;
  enrollment: number;
  monthly: number;
  firstMonthDate: string;
  mode: string;
  numOfMonths: number;
  discountRate: number;
  discountPrice: number;
  discountCoupon: string;
  currency: string;
  payments: Payment[];
  bankPackageId: number;
}
