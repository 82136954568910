import {Component, Input} from '@angular/core';
import {Shared} from "../../shared/shared";
import {CalendarService} from "../../../service/CalendarService";
import {UserService} from "../../../service/UserService";
import {BasicDto} from "../../../dto/BasicDto";
import {StaticCalendarEntity} from "../../../model/StaticCalendarEntity";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {StaticCalendarEventEntity} from "../../../model/StaticCalendarEventEntity";
import {CalendarComponent} from "../generic/table/calendarComponent/calendarComponent";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import * as moment from "moment";
import {CalendarMode, Step} from "ionic2-calendar/calendar";
import {AlertController, PopoverController} from "@ionic/angular";
import {VideoService} from "../../../service/VideoService";
import {LibraryService} from "../../../service/LibraryService";
import {ExamService} from "../../../service/ExamService";
import { VideoFolderEntity } from 'src/app/model/VideoFolderEntity';
import {LibraryFolderEntity} from "../../../model/LibraryFolderEntity";
import {ExamSettingEntity} from "../../../model/ExamSettingEntity";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {TypeOfEventEntity} from "../../../model/TypeOfEventEntity";
import {SubjectService} from "../../../service/SubjectService";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SearchEventDto} from "../../../dto/SearchEventDto";
import {CourseStructureDto} from "../../../dto/CourseStructureDto";


@Component({
  selector: 'app-static-calendar',
  templateUrl: 'staticCalendar.html',
  styleUrls: ['staticCalendar.scss']
})
export class StaticCalendar {

  staticCalendars: StaticCalendarEntity[];
  staticCalendarEvents: StaticCalendarEventEntity[];
  lastCalendarEventDragged: StaticCalendarEventEntity = null;

  searchEvent: SearchEventDto = new SearchEventDto();

  current: StaticCalendarEntity;
  currentEvent: StaticCalendarEventEntity;

  availableVideos: CourseStructureDto[];
  availableLibraries: CourseStructureDto[];
  availableExams: CourseStructureDto[];
  availableCourseStructure: CourseStructureDto[];

  subjects: SubjectEntity[];
  topics: TopicEntity[];
  topicsFull: TopicEntity[];
  typeOfEvents: TypeOfEventEntity[];

  basicData: BasicDto[];

  showCalendar:boolean = true;
  showTable:boolean = false;

  modeAdd: boolean = false;
  modeAddEvent: boolean = false;
  newEntity: StaticCalendarEntity;

  showStartDate = false;
  showEndDate = false;
  showStartDateEvent = false;
  showEndDateEvent = false;
  showSearch = false;

  showSearchToStartDateEvent = false;
  showSearchFromStartDateEvent = false;
  showSearchToEndDateEvent = false;
  showSearchFromEndDateEvent = false;

  showVideos: boolean = false;
  showExams: boolean = false;
  showDocuments: boolean = false;

  showTopSide: boolean = true;

  genericEvent;
  genericStartDate;
  genericEndDate;
  eventSource;
  viewTitle;

  language = "en_US";
  isToday: boolean;
  daySelected;
  staticDaySelected;
  flagDaySelected;
  calendar = {
    mode: 'month' as CalendarMode,
    locale: this.language,
    startingDayWeek: 1,
    step: 30 as Step,
    currentDate: new Date()
  };

  headers: any = {
    columns : {
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let cal = this.staticCalendars.find(x => x.id == data.row.id)
              cal.name = data.text;
              this.saveStaticCalendar(cal, false);
            });
          }
        },
      },
      startDate: {title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let obj = this.staticCalendars.find(x => x.id == data.row.id)
              obj.startDate = data.date;
              this.saveStaticCalendar(obj, false);
            });
          }
        },
      },
      endDate: {title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let obj = this.staticCalendars.find(x => x.id == data.row.id)
              obj.endDate = data.date;
              this.saveStaticCalendar(obj, false);
            });
          }
        },
      },
      courseId: {title: 'Course', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.basicData}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let obj = this.staticCalendars.find(x => x.id == data.row.id)
              obj.courseId = data.id;
              this.saveStaticCalendar(obj, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let cal = this.staticCalendars.find(x => x.id == data.row.id)
              cal.showInCampus = data.bool;
              this.saveStaticCalendar(cal, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let cal = this.staticCalendars.find(x => x.id == data.row.id)
              cal.showInMagnament = data.bool;
              this.saveStaticCalendar(cal, false);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
      corpId: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'copy-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.cloneStaticCalendar(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private popoverCtrl: PopoverController,private shared: Shared,private subjectService: SubjectService, private modalService: NgbModal, private calendarService: CalendarService, private userService: UserService, private videoService: VideoService,private libraryService: LibraryService, private examService: ExamService) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    this.calendarService.getStaticCalendars().subscribe(x=>{
      if(x != null) {
        this.staticCalendars = x;
      }
    });

    (await this.userService.getBasicDataByCorp()).subscribe(p => {
      if (p != null) {
        this.basicData = p;
        this.showTable = true;
      }
    });

    (await this.subjectService.getSubjects()).subscribe(x => {
      if (x != null) {
        this.subjects = x;
      }
    });
    (await this.subjectService.getTopics()).subscribe(x => {
      if (x != null) {
        this.topics = x;
        this.topicsFull = x;
      }
    });
    this.calendarService.getTypeOfEvents().subscribe(x => {
      if (x != null) {
        this.typeOfEvents = x;
      }
    });
  }

  async newEvent(){
    while(!this.flagDaySelected) {
      await this.delay(200);
    }
      this.currentEvent = new StaticCalendarEventEntity();
      this.currentEvent.corpId = this.current.corpId;
      this.currentEvent.staticCalendarId = this.current.id;
      this.currentEvent.showInCampus = true;
      this.currentEvent.showInMagnament = true;
      this.staticDaySelected = new Date(moment.tz(this.daySelected, this.shared.CURRENT_LOCATION.timezone).format('yyyy-MM-DD'));
      this.modeAddEvent = true;
      this.flagDaySelected = false;
      this.resetCalendar();
  }

  newStaticCalendar(){
    this.newEntity = new StaticCalendarEntity();
    this.modeAdd = true;
  }

  saveNewStaticCalendar(){
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInCampus = false;
    this.newEntity.showInMagnament = true;
    this.saveStaticCalendar(this.newEntity, true);
    this.modeAdd = false;
  }

  saveStaticCalendar(cal: StaticCalendarEntity, bool: Boolean){
    this.calendarService.saveStaticCalendar(cal).subscribe(t=>{
      if(t!=null) {
        if(bool){this.staticCalendars.push(t);}
        this.staticCalendars = this.staticCalendars.filter(x => {return x});
      }
    });
  }

  async saveStaticCalendarEvent(evt: StaticCalendarEventEntity){
    if(evt.type != null) {
      this.calendarService.saveStaticCalendarEvent(evt).subscribe(t => {
        if (t != null) {
          if (this.modeAddEvent) {
            this.staticCalendarEvents.push(t);
          } else {
            this.staticCalendarEvents[this.staticCalendarEvents.findIndex(x => x.id == t.id)] = t;
          }
          this.currentEvent = null;
          this.modeAddEvent = false;
          this.resetCalendar();
        }
      });
    }else{
      this.shared.toastr.error('Type of event cant be null', 'Error');
    }
  }

  cloneStaticCalendar(staticCalendarId){
    this.calendarService.cloneStaticCalendar(staticCalendarId).subscribe(t=>{
      if(t!=null) {
        this.staticCalendars = t;
        this.resetCalendar();
      }
    });
  }

  copyStaticCalendarEvent(cal: StaticCalendarEventEntity){
    this.currentEvent = JSON.parse(JSON.stringify(cal));
    this.currentEvent.id = null;
    this.currentEvent.title = this.currentEvent.title+'-copy';
    this.modeAddEvent = true;
    this.resetCalendar();
  }

  deleteStaticCalendarEvent(cal: StaticCalendarEventEntity){
    this.calendarService.deleteStaticCalendarEvent(cal).subscribe(t=>{
      if(t!=null) {
        if(t.bool){
          this.staticCalendarEvents = this.staticCalendarEvents.filter(x=> x.id != cal.id);
          this.currentEvent = null;
          this.resetCalendar();
        }
      }
    });
  }

  subjectChanges(){
    this.currentEvent.title = this.subjects.find(x=> x.id == this.currentEvent.subjectId).name;
    this.filterTopics();
  }

  filterTopics(){
    this.topics = this.topicsFull.filter(x=> x.subjectId == this.currentEvent.subjectId);
  }

  topicChanges(){
    this.currentEvent.title = this.topics.find(x=> x.id == this.currentEvent.topicId).name;
  }

  changeStartDate(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newEntity.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newEntity.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeStartDateEvent(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.currentEvent.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDateEvent(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.currentEvent.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }


  changeSearchFromStartDateEvent(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchEvent.fromStart = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
    this.filterEvents();
  }

  changeSearchToStartDateEvent(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchEvent.toStart = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
    this.filterEvents();
  }

  changeSearchFromEndDateEvent(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchEvent.fromEnd = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
    this.filterEvents();
  }

  changeSearchToEndDateEvent(event){
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchEvent.toEnd = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
    this.filterEvents();
  }

  nextStep(data){
    this.calendarService.getStaticCalendarEvents(data).subscribe(x=>{
      if(x != null) {
        this.staticCalendarEvents = x;
        this.current = this.staticCalendars.find(x=>x.id == data);

        /*let obj = {
          'courseId': this.current.courseId,
          'courseTypeId': null,
          'modalityId': null,
          'siteId': null,
          'turnId': null
        }
        this.videoService.getVideoFolders(obj).subscribe(x=>{
          if(x!=null){
            console.log(x);
            this.availableVideos = x;
          }
        });

        this.libraryService.getLibraryFolders(obj).subscribe(x=>{
          if(x!=null){
            this.availableLibraries = x;
          }
        });

        this.examService.getExamsSettings(obj).subscribe(x=>{
          if(x!=null){
            this.availableExams = x;
          }
        });*/

        this.availableCourseStructure = null;
        this.availableExams = null;
        this.availableVideos = null;
        this.availableLibraries = null;
        this.calendarService.getCourseStructure(this.current.courseId).subscribe(x=>{
          if(x!=null){
            this.availableCourseStructure = x;
            this.availableExams = x.filter(t => t.type == 'EXAM');
            this.availableVideos = x.filter(t => t.type == 'VIDEO_FOLDER');
            this.availableLibraries = x.filter(t => t.type == 'LIBRARY_FOLDER');

            console.log(this.availableVideos);
          }
        });

        if (this.shared.CURRENT_LANGUAGE == 'ES') {
          this.language = "es_ES"
        } else if (this.shared.CURRENT_LANGUAGE == 'US') {
          this.language = "en_US"
        }
        this.resetCalendar();
      }
    });
    this.eventSource = [];
  }

  getExam( pack: StaticCalendarEventEntity){
    return this.availableExams.find(x=>x.reference === pack.auxReference);
  }

  getVideo( pack: StaticCalendarEventEntity){
    let fullList = [];

    this.availableVideos.forEach(x1=>{
      fullList.push(x1);
      if(x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if(x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    return fullList.find(x=> x.reference == pack.auxReference);
  }

  getDocument( pack: StaticCalendarEventEntity){
    let fullList = [];

    this.availableLibraries.forEach(x1=>{
      fullList.push(x1);
      if(x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if(x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return fullList.find(x=> x.reference == pack.auxReference);
  }

  async nextMonth(){
    this.showCalendar = false;
    let temp = this.calendar.currentDate;
    temp.setMonth(this.calendar.currentDate.getMonth()+1);
    temp.setDate(this.calendar.currentDate.getDate());
    temp.setFullYear(this.calendar.currentDate.getFullYear());
    this.calendar.currentDate = temp;
    await this.delay(200);
    this.showCalendar = true;
  }

  async previousMonth(){
    this.showCalendar = false;
    let temp = this.calendar.currentDate;
    temp.setMonth(this.calendar.currentDate.getMonth()-1);
    temp.setDate(this.calendar.currentDate.getDate());
    temp.setFullYear(this.calendar.currentDate.getFullYear());
    this.calendar.currentDate = temp;
    await this.delay(200);
    this.showCalendar = true;
  }

  async selectEvent(ev){
    this.currentEvent = ev;
    this.modeAddEvent = false;
    this.staticDaySelected = moment.tz(ev.startDate, this.shared.CURRENT_LOCATION.timezone).format(moment.HTML5_FMT.DATE);
    this.resetCalendar();
  }

  async resetCalendar(){
    this.showCalendar = false;

    var events = [];
    this.staticCalendarEvents.forEach(obj => {
      obj.fullType = this.typeOfEvents.find(x=> x.id == obj.type);
      events.push({
        title: obj.title,
        startTime: new Date(moment(obj.startDate).tz(this.shared.CURRENT_CORP.timezone).toDate()),
        endTime: new Date(moment(obj.endDate).tz(this.shared.CURRENT_CORP.timezone).toDate()),
        allDay: false,
        myEvent: obj
      });
    });
    this.eventSource = events;

    await this.delay(200);
    this.showCalendar = true;

    this.calendar.currentDate = new Date(moment.tz(this.staticDaySelected, this.shared.CURRENT_LOCATION.timezone).format('yyyy-MM-DDTHH:mm:ss'));
  }

  filterEvents(){
    let temp: StaticCalendarEventEntity[] = JSON.parse(JSON.stringify(this.staticCalendarEvents));

    temp = temp.filter(x=>{
      let returnThis = true;
      if(this.searchEvent.title!=undefined){
        returnThis = false;
        if(x.title.toUpperCase().includes(this.searchEvent.title.toUpperCase())){
          returnThis = true;
        }
      }

      if(this.searchEvent.fromStart!=undefined && this.searchEvent.fromStart!=null && this.searchEvent.toStart!=undefined && this.searchEvent.toStart!=null ){
        returnThis = false;
        if(this.searchEvent.fromStart < x.startDate && this.searchEvent.toStart > x.startDate){
          returnThis = true;
        }
      }else {
        if (this.searchEvent.fromStart != undefined && this.searchEvent.fromStart != null) {
          returnThis = false;
          if (this.searchEvent.fromStart < x.startDate) {
            returnThis = true;
          }
        }
        if (this.searchEvent.toStart != undefined && this.searchEvent.toStart != null) {
          returnThis = false;
          if (this.searchEvent.toStart > x.startDate) {
            returnThis = true;
          }
        }
      }

      if(this.searchEvent.fromEnd!=undefined && this.searchEvent.fromEnd!=null && this.searchEvent.toEnd!=undefined && this.searchEvent.toEnd!=null ){
        returnThis = false;
        if(this.searchEvent.fromEnd < x.endDate && this.searchEvent.toEnd > x.endDate){
          returnThis = true;
        }
      }else {
        if (this.searchEvent.fromEnd != undefined && this.searchEvent.fromEnd != null) {
          returnThis = false;
          if (this.searchEvent.fromEnd < x.endDate) {
            returnThis = true;
          }
        }
        if (this.searchEvent.toEnd != undefined && this.searchEvent.toEnd != null) {
          returnThis = false;
          if (this.searchEvent.toEnd > x.endDate) {
            returnThis = true;
          }
        }
      }

      if(this.searchEvent.typeOfEvent!=undefined && this.searchEvent.typeOfEvent.length > 0){
        returnThis = false;
        if(this.searchEvent.typeOfEvent.includes(x.type)){
          returnThis = true;
        }
      }
      if(this.searchEvent.subject!=undefined && this.searchEvent.typeOfEvent.length > 0){
        returnThis = false;
        if(this.searchEvent.subject.includes(x.subjectId)){
          returnThis = true;
        }
      }
      if(this.searchEvent.topic!=undefined && this.searchEvent.typeOfEvent.length > 0){
        returnThis = false;
        if(this.searchEvent.topic.includes(x.topicId)){
          returnThis = true;
        }
      }


      if(this.searchEvent.enabled!=undefined && this.searchEvent.enabled!=null){
        returnThis = false;
        if(x.showInCampus == this.searchEvent.enabled){
          returnThis = true;
        }
      }
      if(this.searchEvent.haveReference!=undefined && this.searchEvent.haveReference!=null){
        returnThis = false;
        if(this.searchEvent.haveReference && x.auxReference != null){
          returnThis = true;
        }else if (!this.searchEvent.haveReference && x.auxReference == null){
          returnThis = true;
        }
      }

      if(returnThis){
        return x;
      }
    });

    var events = [];
    temp.forEach(obj => {
      obj.fullType = this.typeOfEvents.find(x=> x.id == obj.type);
      events.push({
        title: obj.title,
        startTime: new Date(moment(obj.startDate).tz(this.shared.CURRENT_CORP.timezone).toDate()),
        endTime: new Date(moment(obj.endDate).tz(this.shared.CURRENT_CORP.timezone).toDate()),
        allDay: false,
        myEvent: obj
      });
    });
    this.eventSource = events;
  }

  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  onEventSelected(event) {
    this.genericEvent = event;
  }

  onCurrentDateChanged(event: Date) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    event.setHours(0, 0, 0, 0);
    this.isToday = today.getTime() === event.getTime();
    this.daySelected = event;
    this.flagDaySelected = true;
  }

  checkData(item: StaticCalendarEventEntity, thisType){
    let type = this.typeOfEvents.find(t=> t.id == item.type);
    if(['EX','EC'].includes(type.alias) && thisType == 'EXAM'){
      return true;
    }else if(['CL','LC','TU','LT','VI'].includes(type.alias) && (thisType == 'VIDEO' || thisType == 'VIDEO_FOLDER')){
      return true;
    }else if(['ST','RV','DO'].includes(type.alias) && (thisType == 'LIBRARY' || thisType == 'LIBRARY_FOLDER')){
      return true;
    }else if(['QE'].includes(type.alias) && thisType == 'URL'){
      this.currentEvent.auxType = 'URL';
      return true;
    }
    return false;
  }

  showData(item: StaticCalendarEventEntity, template){
    if(item.type==null){
      return false;
    }
    this.showExams = this.checkData(item, 'EXAM');
    this.showVideos = this.checkData(item, 'VIDEO');
    this.showDocuments = this.checkData(item, 'LIBRARY');

    this.modalService.open(template, { centered: true, windowClass: 'custom-modal' });
  }

  onDrop(newDate) {
    if(this.lastCalendarEventDragged != null) {
      this.modeAddEvent = false;
      this.lastCalendarEventDragged.startDate = moment(this.lastCalendarEventDragged.startDate).set({
          year: moment(newDate).year(),
          month: moment(newDate).month(),
          date: moment(newDate).date()
      }).format('yyyy-MM-DDTHH:mm:ss');

      this.lastCalendarEventDragged.endDate = moment(this.lastCalendarEventDragged.endDate).set({
          year: moment(newDate).year(),
          month: moment(newDate).month(),
          date: moment(newDate).date()
      }).format('yyyy-MM-DDTHH:mm:ss');
      this.saveStaticCalendarEvent(this.lastCalendarEventDragged);
      this.lastCalendarEventDragged = null;
    }
  }

  onDragStart(event) {
    this.lastCalendarEventDragged = event;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
