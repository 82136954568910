import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {TopicEntity} from "../../../../model/TopicEntity";
import {SubjectEntity} from "../../../../model/SubjectEntity";
import {SubjectService} from "../../../../service/SubjectService";
import {StepEntity} from "../../../../model/StepEntity";
import {StepContentEntity} from "../../../../model/StepContentEntity";
import {CalendarService} from "../../../../service/CalendarService";
import {ForumEntity} from "../../../../model/ForumEntity";
import {ForumMessageEntity} from "../../../../model/ForumMessageEntity";
import {ForumService} from "../../../../service/ForumService";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ForumCategoriesEntity} from "../../../../model/ForumCategoriesEntity";

@Component({
  selector: 'app-thread',
  templateUrl: 'thread.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Thread {

  @Input() forum: ForumEntity;
  @Input() forumMessages: ForumMessageEntity[];
  @Input() forumCategories: ForumCategoriesEntity[];

  forumMessagesPrincipals : ForumMessageEntity[];
  newMessage: ForumMessageEntity = null;
  currentMessage: ForumMessageEntity;
  searchText: string;
  priv= false;
  answerMode = false;

  mediaRecorder: any;
  audioChunks: any[] = [];
  audioUrl: string | null = null;
  isRecording: boolean = false;

  selectedCategory = null;
  forumMessagesPrincipalsFiltered: ForumMessageEntity[] = null;
  categoriesFiltered : ForumCategoriesEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: ForumMessageEntity;
  modeAdd = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '180px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  constructor(private forumService: ForumService, private shared: Shared) {
  }

  ngOnInit() {
    this.categoriesFiltered = this.forumCategories;
    console.log(this.categoriesFiltered);
    this.sortThreads();
  }

  saveForumMessage(forumMessage: ForumMessageEntity, bool) {
    this.forumService.saveForumMessage(forumMessage).subscribe( t =>{
      if(t!=null) {
        if(bool){this.forumMessages.push(t);}
        this.forumMessages = this.forumMessages.filter(x => {return x});
      }
    });
  }

  selectCategory(cat : ForumCategoriesEntity){
    this.forumMessagesPrincipalsFiltered = this.forumMessagesPrincipals.filter(f=> f.categoryId == cat.id);
    this.selectedCategory = cat;
  }

  isSelected(cat){
    if(this.selectedCategory != null && cat.id == this.selectedCategory.id){
      return true;
    }
    return false;
  }

  async saveNewForumMessage() {
    this.saveForumMessage(this.newEntity, true);
    this.modeAdd = false;
  }

  newForumMessage(){
    this.modeAdd = true;
    this.newEntity = new ForumMessageEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
  }

  saveMessage(){
    this.forumService.saveForumMessage(this.newMessage).subscribe((x: ForumMessageEntity)=>{
      if(x!=null){
        if(this.newMessage.id == null) {
          this.forumMessages.push(x);
        }else{
          this.currentMessage = this.newMessage;
        }
        this.newMessage = null;
      }
    });
  }

  cancelMessage(){
    this.newMessage = null;
  }

  createdMessage(createdMessage){
    this.forumMessages.push(createdMessage);
    this.selectMessage(this.forumMessages.find(c=> c.id == createdMessage.forumPrincipalId));
    this.forumMessagesPrincipals = this.forumMessages.filter(p=> p.forumMessageId == null);
  }

  createNewMessage(){
    this.newMessage = new ForumMessageEntity();
    this.newMessage.forumId = this.forum.id;
    this.newMessage.corpId = this.shared.CORP_ID;
    this.newMessage.showInCampus = true;
    this.newMessage.authorId = this.shared.CURRENT_MANAGER.id;
    this.newMessage.authorFullname = this.shared.CURRENT_MANAGER.name +' '+ this.shared.CURRENT_MANAGER.surname;
    this.newMessage.authorImage = this.shared.CURRENT_MANAGER.profileImage;
    this.newMessage.authorType = 'ADMIN';
    if(this.selectedCategory != null){
      this.newMessage.categoryId = this.selectedCategory.id;
    }
  }

  answerMessage(messageId){
    let message = this.forumMessages.find(p => p.id == messageId);
    this.newMessage = new ForumMessageEntity();
    this.newMessage.forumId = this.forum.id;
    this.newMessage.corpId = this.shared.CORP_ID;
    this.newMessage.showInCampus = true;
    this.newMessage.forumMessageId = message.id;
    this.newMessage.title = message.title;
    this.newMessage.forumPrincipalId = message.forumPrincipalId;
    this.newMessage.categoryId = message.categoryId;
    this.newMessage.authorId = this.shared.CURRENT_MANAGER.id;
    this.newMessage.authorFullname = this.shared.CURRENT_MANAGER.name +' '+ this.shared.CURRENT_MANAGER.surname;
    this.newMessage.authorImage = this.shared.CURRENT_MANAGER.profileImage;
    this.newMessage.authorType = 'ADMIN';

    this.answerMode = true;
  }

  selectMessage(message){
    this.currentMessage = message
    this.currentMessage.answers = this.getRecursiveAnswers(message.id);
    this.newMessage = null
  }

  editMessage(messageId){
    let message = this.forumMessages.find(p => p.id == messageId);
    this.newMessage = message;
  }

  search(){
    let myT = 'EMPTY';
    if(this.searchText != null && this.searchText.length>1){
      myT = this.searchText;
    }

    this.forumService.getForumMessages(this.forum.id, myT).subscribe(x=>{
      if(x!=null) {
        this.forumMessages = x;
        this.sortThreads();
      }
    });
  }

  sortThreads(){
    let myT = 'EMPTY';
    if(this.searchText != null && this.searchText.length>1){
      myT = this.searchText;
    }

    this.forumService.getForumMessagesSorted(this.forum.id, myT).subscribe(x=>{
      if(x!=null) {
        let aux = x.map(i => i.forumPrincipalId);
        aux = aux.filter((item, index) => {
          return aux.indexOf(item) === index;
        });

        this.forumMessagesPrincipals = x.filter(p=> p.forumMessageId == null);

        this.forumMessagesPrincipals.sort((a, b) => {
          return aux.indexOf(a.id) - this.forumMessagesPrincipals.indexOf(b);
        });
      }
    });
  }

  getRecursiveAnswers(forumMessageId) {
    let output: ForumMessageEntity[] = [];
    if (forumMessageId != null) {
      let answers = this.forumMessages.filter(p => p.forumMessageId == forumMessageId);
      answers.forEach(p => {
        let f = p;
        f.answers = this.getRecursiveAnswers(p.id);
        output.push(f);
      });
    }
    return output;
  }

  getCategory(categoryId){
    if(categoryId != null) {
      return this.forumCategories.find(p => p.id == categoryId);
    }
  }


  startRecording() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();
        this.isRecording = true;

        // Almacenar los datos de audio en chunks
        this.mediaRecorder.ondataavailable = (event: any) => {
          this.audioChunks.push(event.data);
        };

        // Al detener la grabación, crear un archivo de audio
        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
          this.audioUrl = URL.createObjectURL(audioBlob); // Crear URL para el audio grabado
          this.audioChunks = []; // Limpiar los chunks para la siguiente grabación
        };
      }).catch(err => {
        console.error('Error al acceder al micrófono:', err);
      });
    } else {
      console.warn('El navegador no soporta la grabación de medios.');
    }
  }

  // Detener la grabación
  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.isRecording = false;
    }
  }

  // Reproducir el audio grabado
  playAudio() {
    const audio = new Audio(this.audioUrl!);
    audio.play();
  }

}
