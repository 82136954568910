import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Login } from './login';
import {LoginRoutingModule} from "./login-routing.module";
import {LoginService} from "../../service/LoginService";


@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        LoginRoutingModule,
    ],
    providers: [LoginService],
    exports: [
        Login
    ],
    declarations: [Login]
})
export class LoginModule {}
