import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {CorpStyleRoutingModule} from "./corpStyle-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {CorpStyle} from "./corpStyle";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {ContextService} from "../../../service/ContextService";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {NgToggleModule} from "ng-toggle-button";
import {LibraryImagesModule} from "../../libraryImages/libraryImages.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    CorpStyleRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    DlDateTimePickerModule,
    PickerModule,
    AngularEditorModule,
    NgToggleModule,
    LibraryImagesModule,
  ],
  providers: [ContextService],
  exports: [
    CorpStyle
  ],
  declarations: [CorpStyle]
})
export class CorpStyleModule {}
