export class UniversityEntity {
  corpId: number;
  id: number;
  name: string;
  alias: string;
  address: string;
  province: string;
  town: string;
  cp: string;
  country: string;
  showInCampus: boolean;
  showInMagnament: boolean;
}
