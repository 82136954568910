
export class BankItemEntity {
  id: number;
  corpId: number;
  bankPackageId: number;
  name: string;
  type: number;
  primaryToken: string;
  secondaryToken: string;
  tertiaryToken: string;
  enabled: boolean;
  currency: string;
  showInMagnament: boolean;
}
