import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {CourseService} from "../../../service/CourseService";
import {Shared} from "../../shared/shared";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {WorkgroupEntity} from "../../../model/WorkgroupEntity";
import {CourseEntity} from "../../../model/CourseEntity";
import {CalendarComponent} from "../generic/table/calendarComponent/calendarComponent";
import * as moment from "moment";

@Component({
  selector: 'app-workgroup',
  templateUrl: 'workgroup.html',
  styleUrls: ['workgroup.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Workgroup {

  workgroups: WorkgroupEntity[];
  courses: CourseEntity[];

  showTable = true;
  modeAdd = false;

  newWorkgroupEntity: WorkgroupEntity;

  showStartDate = false;
  showEndDate = false;

  headers: any = {
    columns: {
      corpId: {title: 'Statement', hide: true, filter: false},
      id: {title: 'Statement', hide: true, filter: false},
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let workgroup = this.workgroups.find(x => x.id == data.row.id)
              workgroup.alias = data.text;
              this.saveWorkgroup(workgroup);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let workgroup = this.workgroups.find(x => x.id == data.row.id)
              workgroup.name = data.text;
              this.saveWorkgroup(workgroup);
            });
          }
        },
      },
      courseId: {title: 'Course', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.courses}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let workgroup = this.workgroups.find(x => x.id == data.row.id)
              workgroup.courseId = data.id;
              this.saveWorkgroup(workgroup);
            });
          }
        },
      },
      startDate: {
        title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let workgroup = this.workgroups.find(x => x.id == data.row.id)
              workgroup.startDate = data.date;
              this.saveWorkgroup(workgroup);
            });
          }
        },
      },
      endDate: {
        title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let workgroup = this.workgroups.find(x => x.id == data.row.id)
              workgroup.endDate = data.date;
              this.saveWorkgroup(workgroup);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let workgroup = this.workgroups.find(x => x.id == data.row.id)
              workgroup.showInCampus = data.bool;
              this.saveWorkgroup(workgroup);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let workgroup = this.workgroups.find(x => x.id == data.row.id)
              workgroup.showInMagnament = data.bool;
              this.saveWorkgroup(workgroup);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private courseService: CourseService, private shared: Shared) {
  }

  ngOnInit() {
    this.courseService.getCourses(this.shared.CORP_ID).subscribe( x=> {
      if(x!=null){
        this.courses = x;
      }
    });

    this.courseService.getWorkgroups(this.shared.CORP_ID).subscribe( x=> {
      if(x!=null){
        this.workgroups = x;
      }
    });
  }

  async saveWorkgroup(workgroup: WorkgroupEntity) {
    this.courseService.saveWorkgroup(workgroup).subscribe( t =>{
        if(t!=null) {
          this.workgroups.push(t);
          this.workgroups = this.workgroups.filter(x => {return x});
        }
    });
  }

  async saveNewWorkgroup() {
    this.saveWorkgroup(this.newWorkgroupEntity);
    this.modeAdd = false;
  }

  newWorkgroup(){
    this.modeAdd = true;
    this.newWorkgroupEntity = new WorkgroupEntity();
    this.newWorkgroupEntity.corpId = this.shared.CORP_ID;
    this.newWorkgroupEntity.showInMagnament = true;
  }

  changeStartDate(event){
    this.newWorkgroupEntity.startDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event){
    this.newWorkgroupEntity.endDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
