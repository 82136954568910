import {Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";

import * as moment from "moment";

import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {QuestionEntity} from "../../../model/QuestionEntity";
import {QuestionService} from "../../../service/QuestionService";
import {CommonQuestionDto} from "../../../dto/CommonQuestionDto";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AnswerEntity} from "../../../model/AnswerEntity";
import {QuestionMultimediaEntity} from "../../../model/QuestionMultimediaEntity";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {QuestionTagEntity} from "../../../model/QuestionTagEntity";
import {OriginEntity} from "../../../model/OriginEntity";
import {QuestionAdminDto} from "../../../dto/QuestionAdminDto";
import {BasicDto} from "../../../dto/BasicDto";
import {SearchQuestionDto} from "../../../dto/SearchQuestionDto";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {QuestionGroupEntity} from "../../../model/QuestionGroupEntity";
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import {StudentEntity} from "../../../model/StudentEntity";
import {SubscriptionEntity} from "../../../model/SubscriptionEntity";

const swiper = new Swiper('.swiper', {
  modules: [Navigation, Pagination],
});

@Component({
  selector: 'app-questions',
  templateUrl: 'questions.html',
  styleUrls: ['questions.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Questions {

  @Output() addQuestion = new EventEmitter<string>();

  questionsData: QuestionAdminDto[];
  questions: QuestionEntity[];
  current: QuestionEntity;

  commonQuestion: CommonQuestionDto;
  answers: AnswerEntity[];
  multimedia: QuestionMultimediaEntity[];
  selectedFile4: any;

  searchOrigins: BasicDto[];
  searchTags: BasicDto[];
  searchGroups: BasicDto[];
  searchSubjects: BasicDto[];
  searchTopics: BasicDto[];
  searchExamSetting: BasicDto[];

  origins: OriginEntity[];
  questionTags: QuestionTagEntity[];
  questionGroups: QuestionGroupEntity[];

  searchQuestion: SearchQuestionDto;

  rightDiv: number = 1;
  currentTab = 1;
  bigImage = -1;
  currentSubjectIndex = -1;
  isDownloading = false;

  subjectId = null;
  topicId = null;

  showTable = true;
  searchDiv: boolean = false;

  searchD1: boolean = false;
  searchD2: boolean = false;
  searchD3: boolean = false;
  searchD4: boolean = false;
  searchD5: boolean = false;
  uploadQuestionListResult = 0;

  isAddModeToExam: boolean = false;

  showQuestionStartDate = false;
  showQuestionEndDate = false;

  newMultimediaTitle: string = "";
  newMultimediafileUrl: string = "";
  newMultimediaPosition: string = "STATEMENT";
  newMultimediaShowInCampus: boolean = false;

  headers: any = {
    columns: {
      statement: {title: 'Statement', hide: false, filter: false,
        valuePrepareFunction: (statement: string) => {
          return statement.substring(0,80);
        }
      },
      comment: {title: 'Comment', hide: false, filter: false,
        valuePrepareFunction: (comment: string) => {
          return comment.substring(0,80);
        }
      },
      exams: {
        title: 'Exámenes', hide: false, filter: false,
        valuePrepareFunction: (cell, row) => {
          if(row.exams != null) {
            return row.exams.join(', ');
          }
          return '';
        }
      },
      subject: {
        title: 'Subject', hide: false, filter: false,
        valuePrepareFunction: (subject: SubjectEntity) => {
          if(subject!=null) {
            return subject.name;
          }else{
            return "";
          }
        }
      },
      topic: {
        title: 'Topic', hide: true, filter: false,
        valuePrepareFunction: (topic: TopicEntity) => {
          if(topic!=null) {
            return topic.name;
          }else{
            return "";
          }
        }
      },

      corpId: {title: '', hide: this.isAddModeToExam, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: row.id, icon: 'add-outline', text: 'Add', color: '#1c8aa5'}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.addQuestionToExam(data);
            });
          }
        },
      },
      correctAnswer: {title: 'Correct answer', hide: true, filter: false},
      difficulty: {title: 'Difficulty', hide: true, filter: false},
      endDate: {title: 'End date', hide: true, filter: false},
      id: {title: 'ID', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      showInCampus: {title: 'Show in campus', hide: true, filter: false},
      showInMagnament: {title: 'Show in magnament', hide: true, filter: false},
      startDate: {title: 'Start date', hide: true, filter: false},
      successValue: {title: 'Success value', hide: true, filter: false},
      wrongValue: {title: 'Wrong value', hide: true, filter: false}
    },
    actions: false,
  }

  constructor(private questionService: QuestionService, public shared: Shared) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    let questionReference = this.shared.getLastVariableFromUrl('reference');

    this.isAddModeToExam = !this.shared.checkUrl('exams');
    this.headers.columns.corpId.hide = this.isAddModeToExam;

    (await this.questionService.getQuestionsAdmin()).subscribe(async x => {
      (await this.questionService.getCommonQuestion()).subscribe(t => {
        if (t != null) {
          this.commonQuestion = t;

          if (questionReference != null) {
            this.questionService.getQuestion(questionReference).subscribe(x => {
              if (x != null) {
                this.rowSelectedEmitter(x);
              }
            });
          }

          this.searchSubjects = this.commonQuestion.subject;
          this.searchTopics = [];
          this.commonQuestion.subject.map(r => {
            return r.list.map(w => {
              w.name = w.name + ' (' + r.alias + ')';
              this.searchTopics.push(w);
            })
          })
          this.searchTags = this.commonQuestion.questionTag;
          this.searchOrigins = this.commonQuestion.origin;
          this.searchGroups = this.commonQuestion.questionGroup;
          this.searchExamSetting = this.commonQuestion.examSetting;
        }
        if (x != null) {
          this.questions = x;
        }
      })
    });
  }

  rowSelectedEmitter(row: QuestionEntity) {
    this.rightDiv = 1;
    this.current = row;
    this.searchDiv = false;

    this.questionService.getAnswerQuestion(this.current.reference).subscribe(p => {
      if (p != null) {
        this.answers = p;
      }
    });
    this.questionService.getMultimediaQuestion(this.current.reference).subscribe(p => {
      if (p != null) {
        this.multimedia = p;
      }
    });
    this.questionService.getOriginTagQuestion(this.current.reference).subscribe(p => {
      if (p != null) {
        this.questionTags = p.questionTag;
        this.origins = p.origin;
        this.questionGroups = p.questionGroup;
      }
    });
    this.subjectId = null;
    this.topicId = null;
    if(this.current.subject != null) {
      this.currentSubjectIndex = this.commonQuestion.subject.findIndex(x => x.id == this.current.subject.id);
      this.subjectId = this.current.subject.id;
    }
    if(this.current.topic != null){
      this.topicId = this.current.topic.id;
    }
  }


  openSearchQuestion(){
    this.searchQuestion = new SearchQuestionDto();
    this.searchDiv = true;
    this.rightDiv = 0;
  }

  newQuestion() {
    this.current = new QuestionEntity();
    this.current.corpId = this.shared.CORP_ID;
    this.current.subject = new SubjectEntity();
    this.current.subject.id = -1;
    this.current.topic = new TopicEntity();
    this.current.topic.id = -1;
    this.currentSubjectIndex = 0;
    this.answers = [];
    this.multimedia = [];
    this.questionTags = [];
    this.questionGroups = [];
    this.origins = [];
    this.current.comment = '&nbsp;';
    this.rightDiv = 1;
  }

  getCorporation(corpId){
    return this.shared.CORPS.find(x=> x.id == corpId);
  }

  addQuestionToExam(data){
    this.addQuestion.emit(data);
  }

  newAnswer(){
    let newNumQuestion = 1;
    if(this.answers.length>0){
      newNumQuestion = this.answers[this.answers.length-1].numAnswer+1;
    }
    this.answers.push(new AnswerEntity(this.shared.CORP_ID,newNumQuestion));
  }

  deleteAnswer(answer){
    this.answers = this.answers.filter(x=>{if(x!=answer){return x}});
  }

  changeSubject() {
    this.current.subject = new SubjectEntity();
    this.current.subject.id = this.subjectId;
    if(this.current.subject != null) {
      this.currentSubjectIndex = this.commonQuestion.subject.findIndex(x => x.id == this.current.subject.id);
    }
  }

  changeTopic() {
    this.current.topic = new TopicEntity();
    this.current.topic.id = this.topicId;
  }

  saveMultimedia(mul: QuestionMultimediaEntity) {
    this.questionService.saveMultimedia(mul).subscribe();
  }

  saveNewMultimedia() {
    let mul: QuestionMultimediaEntity = new QuestionMultimediaEntity();
    mul.title = this.newMultimediaTitle;
    mul.showInCampus = this.newMultimediaShowInCampus;
    mul.fileUrl = this.newMultimediafileUrl;

    mul.corpId = this.current.corpId;
    mul.questionId = this.current.id;
    mul.showInMagnament = true;

    mul.type = 'PHOTO';
    mul.position = this.newMultimediaPosition;

    this.questionService.saveMultimedia(mul).subscribe(x => {
      this.questionService.getMultimediaQuestion(this.current.reference).subscribe(t => {
        if (t != null) {
          this.multimedia = t;
        }
      });
    });

    this.newMultimediaTitle = "";
    this.newMultimediaShowInCampus = false;
    this.newMultimediafileUrl = "";
  }

  uploadMultimedia(evt){
    this.questionService.uploadMultimedia(evt.target.files[0]).subscribe(x=>{
      if(x!=null){
        this.newMultimediafileUrl = x.reference;
      }
    });
  }

  checkCorrect(numCorrect) {
    if (numCorrect == this.current.correctAnswer) {
      this.current.correctAnswer = 0;
    } else {
      this.current.correctAnswer = numCorrect;
    }
  }

  checkTag(tagId) {
    let index = -1;
    if (this.questionTags != null && this.questionTags.length > 0) {
      index = this.questionTags.findIndex(x => x.id == tagId);
    } else {
      this.questionTags = [];
    }

    if (index == -1) {
      this.questionTags.push(new QuestionTagEntity(tagId));
    } else {
      this.questionTags = this.questionTags.filter(x => x.id != tagId);
    }
  }

  checkGroup(tagId) {
    let index = -1;
    if (this.questionGroups != null && this.questionGroups.length > 0) {
      index = this.questionGroups.findIndex(x => x.id == tagId);
    } else {
      this.questionGroups = [];
    }

    if (index == -1) {
      this.questionGroups.push(new QuestionTagEntity(tagId));
    } else {
      this.questionGroups = this.questionGroups.filter(x => x.id != tagId);
    }
  }

  async searchQuestions(){
    this.searchQuestion.difficulty = [];
    if(this.searchD1)this.searchQuestion.difficulty.push(1);
    if(this.searchD2)this.searchQuestion.difficulty.push(2);
    if(this.searchD3)this.searchQuestion.difficulty.push(3);
    if(this.searchD4)this.searchQuestion.difficulty.push(4);
    if(this.searchD5)this.searchQuestion.difficulty.push(5);

    const groups = await this.shared.getCorpGroups();
    this.searchQuestion.corps = groups.split(',').map(Number);

    this.questionService.searchQuestions(this.searchQuestion).subscribe(x => {
      if(x != null) {
        this.questions = x;
      }
    });
  }

  isTagChecked(tagId) {
    if (this.questionTags != null && this.questionTags.length > 0) {
      let index = this.questionTags.findIndex(x => x.id == tagId);
      if (index != -1) {
        return true;
      }
    }
    return false;
  }

  isGroupChecked(tagId) {
    if (this.questionGroups != null && this.questionGroups.length > 0) {
      let index = this.questionGroups.findIndex(x => x.id == tagId);
      if (index != -1) {
        return true;
      }
    }
    return false;
  }

  checkOrigin(originId) {
    let index = -1;
    if (this.origins != null && this.origins.length > 0) {
      index = this.origins.findIndex(x => x.id == originId);
    } else {
      this.origins = [];
    }

    if (index == -1) {
      this.origins.push(new OriginEntity(originId));
    } else {
      this.origins = this.origins.filter(x => x.id != originId);
    }
  }

  isOriginChecked(originId) {
    if (this.origins != null && this.origins.length > 0) {
      let index = this.origins.findIndex(x => x.id == originId);
      if (index != -1) {
        return true;
      }
    }
    return false;
  }

  async saveQuestion() {
    this.showTable = false;
    var obj = {
      question: this.current,
      answers: this.answers,
      questionTags: this.questionTags,
      questionGroups: this.questionGroups,
      origins: this.origins
    }

    this.questionService.saveQuestion(obj).subscribe(x=>{
      if(x!=null){
        let i = this.questions.findIndex(t=>t.id == x.id);
        if(i!=-1){
          this.questions[i] = x;
        }else{
          this.questions.push(x);
        }
      }
    });
    let currentQ = this.questions.findIndex(x => x.id == this.current.id);
    this.questions[currentQ] = this.current;
    await this.delay(200);
    this.showTable = true;
  }

  checkBigImage(questionId) {
    if (this.bigImage != questionId) {
      this.bigImage = questionId
    } else {
      this.bigImage = -1
    }
  }

  selFile4(evt) {
    this.selectedFile4 = evt.target.files[0];
  }

  saveStartDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.current.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.current.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  async downloadContent() {
    this.isDownloading = true;

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    const header = [
      'ID', 'STATEMENT', 'N1', 'ANSWER1', 'N2', 'ANSWER2', 'N3', 'ANSWER3', 'N4', 'ANSWER4',
      'N5', 'ANSWER5', 'N6', 'ANSWER6', 'N7', 'ANSWER7', 'N8', 'ANSWER8', 'N9', 'ANSWER9', 'CORRECT',
      'SUBJECT', 'TOPIC', 'COMMENT', 'DIFFICULT', 'OFFICIAL_EXAM', 'OFFICIAL_YEAR',
      'OFFICIAL_NUM', 'ORIGINS', 'TAGS', 'GROUPS', 'REFERENCE'
    ];

    const rows: any[] = [];

    this.questions.forEach(q => {
      const row = [
        q.id ?? '',
        q.statement ?? '',
      ];

      const flattenedAnswers: any[] = [];
      for(let i= 0;i<9;i++) {
        if(q.answers[i] != null) {
          flattenedAnswers.push(q.answers[i].numAnswer ?? '');
          flattenedAnswers.push(q.answers[i].statement ?? '');
        }else{
          flattenedAnswers.push('','');
        }
      }
      row.push(...flattenedAnswers);
      row.push(
        q.correctAnswer ?? '0',
        q.subject?.alias ?? '',
        q.topic?.alias ?? '',
        q.comment ?? '',
        q.difficulty ?? '',
        this.getOfficialExam(q.officialExam) ?? '',
        q.officialYear ?? '',
        q.officialNum ?? '',
        this.getQuestionOrigins(q.origins) ?? '',
        this.getQuestionTags(q.tags) ?? '',
        this.getQuestionGroups(q.groups) ?? '',
        q.reference ?? ''
      );

      rows.push(row);

    });

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header, ...rows]);

    for (let i = 1; i <= rows.length; i++) {
      const firstCell = ws[XLSX.utils.encode_cell({ r: i, c: 0 })];
      if (firstCell) {
        firstCell.s = { fill: { fgColor: { rgb: 'FF0000' } } };
      }
      const lastCell = ws[XLSX.utils.encode_cell({ r: i, c: header.length - 1 })];
      if (lastCell) {
        lastCell.s = { fill: { fgColor: { rgb: 'FF0000' } } };
      }
    }

    const header2 = ['ALIAS', 'NAME'];
    const rows2: any[] = [];
    this.commonQuestion.origin.forEach(q => {
      rows2.push([q.alias ?? '',q.name ?? '']);
    });
    const rows3: any[] = [];
    this.commonQuestion.questionGroup.forEach(q => {
      rows3.push([q.alias ?? '',q.name ?? '']);
    });
    const rows4: any[] = [];
    this.commonQuestion.questionTag.forEach(q => {
      rows4.push([q.alias ?? '',q.name ?? '']);
    });
    const rows5: any[] = [];
    this.commonQuestion.subject.forEach(q => {
      rows5.push([q.alias ?? '',q.name ?? '']);
    });

    const ws2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header2, ...rows2]);
    const ws3: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header2, ...rows3]);
    const ws4: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header2, ...rows4]);
    const ws5: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header2, ...rows5]);

    XLSX.utils.book_append_sheet(wb, ws, 'ExportQuestions');
    XLSX.utils.book_append_sheet(wb, ws2, 'Origins');
    XLSX.utils.book_append_sheet(wb, ws3, 'Groups');
    XLSX.utils.book_append_sheet(wb, ws4, 'Tags');
    XLSX.utils.book_append_sheet(wb, ws5, 'Subjects');
    XLSX.writeFile(wb, 'ExportQuestions_' + moment().format('yyyyMMDDHHmmss') + '.xlsx');
    this.isDownloading = false;
  }


  async readExcel() {
    const reader = new FileReader();
    reader.onload = async (e) => {
      if (!e.target) return;
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, {type: 'array'});
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const filas: any[] = XLSX.utils.sheet_to_json(worksheet, {header: 1, raw: false, dateNF: "yyyy-mm-ddThh:mm:ss"});
      let questionList = [];

      for (let index = 1; index < filas.length; index++) {
        const fila = filas[index];

        let question = new QuestionEntity();
        question.corpId = this.shared.CORP_ID;
        question.id = fila[0];
        question.statement = fila[1];
        let answers : AnswerEntity[] = []
        if(fila[2] != null && fila[2].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[2]);
          ans.statement = fila[3];
          answers.push(ans);
        }
        if(fila[4] != null && fila[4].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[4]);
          ans.statement = fila[5];
          answers.push(ans);
        }
        if(fila[6] != null && fila[6].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[6]);
          ans.statement = fila[7];
          answers.push(ans);
        }
        if(fila[8] != null && fila[8].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[8]);
          ans.statement = fila[9];
          answers.push(ans);
        }
        if(fila[10] != null && fila[10].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[10]);
          ans.statement = fila[11];
          answers.push(ans);
        }
        if(fila[12] != null && fila[12].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[12]);
          ans.statement = fila[13];
          answers.push(ans);
        }
        if(fila[14] != null && fila[14].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[14]);
          ans.statement = fila[15];
          answers.push(ans);
        }
        if(fila[16] != null && fila[16].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[16]);
          ans.statement = fila[17];
          answers.push(ans);
        }
        if(fila[18] != null && fila[18].length > 0){
          let ans = new AnswerEntity(question.corpId, fila[18]);
          ans.statement = fila[19];
          answers.push(ans);
        }
        question.answers = answers;
        if(fila[20] != null && fila[20].length > 0){
          question.correctAnswer = fila[20];
        }else{
          question.correctAnswer = 0;
        }

        if(fila[21] != null && fila[21].length > 0){
          let s = new SubjectEntity();
          s.id = this.commonQuestion.subject.find(f=> f.alias == fila[21]).id;
          question.subject = s;
        }

        if(fila[21] != null  && fila[21].length > 0 && fila[22] != null && fila[22].length > 0){
          let t = new TopicEntity();
          t.id = this.commonQuestion.subject.find(f=> f.alias == fila[21]).list.find(t=> t.alias == fila[22]).id;
          question.topic = t;
        }

        question.comment = fila[23];
        question.difficulty = fila[24];
        if(fila[25] != null && fila[25].length > 0){
          if(fila[25] == 1){
            question.officialExam = 1;
          }else if(fila[25] == 2){
            question.officialExam = 2;
          }
        }
        if(fila[26] != null && fila[26].length > 0){
          question.officialYear = fila[26];
        }
        if(fila[27] != null && fila[27].length > 0){
          question.officialNum = fila[27];
        }
        if(fila[28] != null && fila[28].length > 0){
          let o = fila[28].split(',');
          question.origins = [];
          o.forEach(c=>{
            question.origins.push(this.commonQuestion.origin.find(p=> p.alias == c).id);
          });
        }
        if(fila[29] != null && fila[29].length > 0){
          let o = fila[29].split(',');
          question.tags = [];
          o.forEach(c=>{
            question.tags.push(this.commonQuestion.questionTag.find(p=> p.alias == c).id);
          });
        }
        if(fila[30] != null && fila[30].length > 0){
          let o = fila[30].split(',');
          question.groups = [];
          o.forEach(c=>{
            question.groups.push(this.commonQuestion.questionGroup.find(p=> p.alias == c).id);
          });
        }
        question.reference = fila[31];

        questionList.push(question);
      }

      this.uploadQuestionListResult = -1;
      this.questionService.saveAdminQuestionList(questionList).subscribe(p => {
        if (p != null) {
          this.uploadQuestionListResult = Number(p.reference);
        }
      });

    };
    reader.readAsArrayBuffer(this.selectedFile4);
  }


  getOfficialExam(officialExam){
    if(officialExam == 1){
      return 'MIR';
    }else if(officialExam == 2){
      return 'EIR';
    }
    return null;
  }

  getQuestionOrigins(origins){
    if(origins != null && origins.length > 0 && this.origins != null && this.origins.length > 0){
      return origins
        .map(o => this.origins.find(os => os.id === o)?.alias)
        .filter(alias => alias !== undefined)
        .join(",");
    }
    return null;
  }

  getQuestionTags(tags){
    if(tags != null && tags.length > 0 && this.questionTags != null && this.questionTags.length > 0){
      return tags
        .map(o => this.questionTags.find(os => os.id === o)?.alias)
        .filter(alias => alias !== undefined)
        .join(",");
    }
    return null;
  }

  getQuestionGroups(groups){
    if(groups != null && groups.length > 0 && this.questionGroups != null && this.questionGroups.length > 0){
      return groups
        .map(o => this.questionGroups.find(os => os.id === o)?.alias)
        .filter(alias => alias !== undefined)
        .join(",");
    }
    return null;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

