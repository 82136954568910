import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";

export class SiteEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  modalityId: number;
  startDate: string;
  endDate: string;
  openSignupDate: string;
  closeSignupDate: string;
  defaultRole: number;
  contentNotification: boolean;
  showInRegister: boolean;
  showInCampus: boolean;
  showInMagnament: boolean;
}
