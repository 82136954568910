import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {Shared} from "../shared/shared";
import {IonModal} from "@ionic/angular";
import {ContextService} from "../../service/ContextService";
import {ImagesService} from "../../service/ImagesService";
import {ImageEntity} from "../../model/ImageEntity";

@Component({
  selector: 'app-library-images',
  templateUrl: 'libraryImages.html',
  styleUrls: ['libraryImages.scss']
})
export class LibraryImages {

  constructor(private shared: Shared, private imageService: ImagesService) {}

  @Output() output = new EventEmitter<string>();
  @Output() closeImages = new EventEmitter<any>();

  images: ImageEntity[] = [];
  imagesFavorites: ImageEntity[] = [];
  imagesOthers: ImageEntity[] = [];

  searchText = "";

  ngOnInit(){
    this.imageService.getImages().subscribe(x=>{
      if(x!=null){
        this.splitImages(x);
      }
    });
  }

  splitImages(x){
    this.images = x;
    this.imagesFavorites = this.images.filter(x=> x.favorite).reverse();
    this.imagesOthers = this.images.filter(x=> !x.favorite).reverse();
  }

  uploadMultimedia(evt){
    this.imageService.uploadImage(evt.target.files[0]).subscribe(x=>{
      if(x!=null){
        this.images.push(x);
        this.splitImages(this.images);
      }
    });
  }

  saveImage(image){
    this.imageService.saveImage(image).subscribe(x=>{
      if(x!=null){
        let num = this.images.findIndex(x=> x.reference == image.reference);
        if(num != -1){
          this.images[num] = x;
        }
        this.splitImages(this.images);
      }
    });
  }

  selectImage(image){
    this.output.emit(image.url);
  }

  closePopup(){
    this.closeImages.emit();
  }

  filterBy(){
    this.imagesFavorites = this.images.filter(x=> x.favorite && x.name.toUpperCase().includes(this.searchText.toUpperCase())).reverse();
    this.imagesOthers = this.images.filter(x=> !x.favorite && x.name.toUpperCase().includes(this.searchText.toUpperCase())).reverse();
  }

}
