import {Injectable, Injector} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Shared} from "../view/shared/shared";

@Injectable()
export class MyInterceptor implements HttpInterceptor {

  private shared: Shared;

  constructor(private readonly injector: Injector) {
    setTimeout(() => {
      this.shared = this.injector.get(Shared);
    }, 200);
  }

  intercept(req : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {

    if(this.shared != null && !req.url.includes('saveLog') && !req.url.includes('get')){
        this.shared.saveLog(JSON.parse(JSON.stringify(req.body)));
    }
    return next.handle(req);
  }
}

export const MyHttpInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: MyInterceptor,
  multi: true,
};
