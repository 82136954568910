import {PackageItemEntity} from "./CalendarPackageItem";

export class PackageEntity {
  corpId: number;
  id: number;
  stepId: number;
  subjectId: number;
  customText: string;
  customIcon: string;
  maxTime: number;
  minTime: number;
  orderNum: number;
  showInMagnament: boolean;
  items: PackageItemEntity[];
  modeEdit: boolean = false;
}
