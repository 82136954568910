import {VideoFolderEntity} from "./VideoFolderEntity";

export class VideoFoldeCourseEntity {
  corpId: number;
  id: string;
  videoFolder: VideoFolderEntity;
  videoFolderId: number;
  courseId: number;
  courseTypeId: number;
  modalityId: number;
  siteId: number;
  turnId: number;
  folderOrder: number;
  startDate: string;
  endDate: string;
  enabled: boolean;
  typeOfRequirement: string;
  requirementReference: string;
  requirementPercentaje: number;
  requirementMoreThan: boolean;
  showRequirement: boolean;
  typeOfAssociation: number;
}
