export class TutorEntity {
  corpId: number;
  id: number;
  reference: string;
  name: string;
  surname: string;
  alias: string;
  mobile: string;
  email: string;
  nif: string;
  address: string;
  province: string;
  town: string;
  cp: string;
  country: string;
  profileImage: string;
  hospitalId: number;
  specialityId: number;
  yearOfExam: number;
  showInCampus: boolean;
  showInMagnament: boolean;
}
