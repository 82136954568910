import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {LibraryImages} from "./libraryImages";
import {ImagesService} from "../../service/ImagesService";
import {TableModule} from "../magnament/generic/table/table.module";


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TableModule,
  ],
  providers: [ImagesService],
  exports: [
    LibraryImages
  ],
  declarations: [LibraryImages]
})
export class LibraryImagesModule {}
