import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {LibraryFolderEntity} from "../model/LibraryFolderEntity";
import {LibraryEntity} from "../model/LibraryEntity";
import {HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {catchError} from "rxjs/operators";

@Injectable()
export class LibraryService extends ParentService{

  public getLibraryFolders(obj){
    return this.http.post<LibraryFolderEntity[]>(this.shared.MAGNAMENT_URL+'/getLibraryFolders', obj, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getLibraryFolder(corpId){
    return this.http.get<LibraryFolderEntity[]>(this.shared.MAGNAMENT_URL+'/getLibraryFolders/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getDocuments(libFolderId){
    return this.http.get<LibraryEntity[]>(this.shared.MAGNAMENT_URL+'/getDocuments/'+libFolderId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveLibraryFolder(folder){
    return this.http.post<LibraryFolderEntity>(this.shared.MAGNAMENT_URL+'/saveLibraryFolder/',folder, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveLibrary(library){
    return this.http.post<LibraryEntity>(this.shared.MAGNAMENT_URL+'/saveLibrary/',library, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getLibrary(reference){
    return this.http.get<LibraryEntity>(this.shared.MAGNAMENT_URL+'/getLibrary/'+reference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadDocument(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadDocument', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

}
