
export class SearchPaymentsDto {
  endDateF: string;
  endDateT: string;
  startDateF: string;
  startDateT: string;
  doneDateF: string;
  doneDateT: string;
  restoreDateF: string;
  restoreDateT: string;
  done: boolean;
  restore: boolean;
  typeOfPayment: number[];
  formatOfPayment: number[];
  priceF: number;
  priceT: number;
  currency: string[];
}
