import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CalendarDto} from "../dto/CalendarDto";
import {StepEntity} from "../model/StepEntity";
import {StepContentEntity} from "../model/StepContentEntity";
import {TurnEntity} from "../model/TurnEntity";
import {PackageEntity} from "../model/CalendarPackage";
import {PackageItemEntity} from "../model/CalendarPackageItem";
import {TypeOfEventEntity} from "../model/TypeOfEventEntity";
import {StaticCalendarEntity} from "../model/StaticCalendarEntity";
import {StaticCalendarEventEntity} from "../model/StaticCalendarEventEntity";
import {JustBooleanDto} from "../dto/JustBooleanDto";
import {CourseStructureDto} from "../dto/CourseStructureDto";
import { catchError } from 'rxjs/operators';
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class CalendarService extends ParentService{

  public getCalendar(subReference){
      return this.http.get<CalendarDto[]>(this.shared.MAGNAMENT_URL+'/getCalendar/'+subReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSteps(corpId){
    return this.http.get<StepEntity[]>(this.shared.MAGNAMENT_URL+'/getSteps/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getStepContents(corpId){
    return this.http.get<StepContentEntity[]>(this.shared.MAGNAMENT_URL+'/getStepContents/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getStepContentsByStep(stepId){
    return this.http.get<StepContentEntity[]>(this.shared.MAGNAMENT_URL+'/getStepContentsByStep/'+stepId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveStep(step) {
    return this.http.post<StepEntity>(this.shared.MAGNAMENT_URL+'/saveStep', step, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveStepContent(stepContent) {
    return this.http.post<StepContentEntity>(this.shared.MAGNAMENT_URL+'/saveStepContent', stepContent, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getPackages(corpId){
    return this.http.get<PackageEntity[]>(this.shared.MAGNAMENT_URL+'/getPackages/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getTypeOfEvents(){
    return this.http.get<TypeOfEventEntity[]>(this.shared.MAGNAMENT_URL+'/getTypeOfEvents', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public savePackageItem(item: PackageItemEntity) {
    return this.http.post<PackageItemEntity>(this.shared.MAGNAMENT_URL+'/savePackageItem', item, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public savePackage(pack) {
    return this.http.post<PackageEntity>(this.shared.MAGNAMENT_URL+'/savePackage', pack, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public savePackageItems(items) {
    return this.http.post<PackageItemEntity>(this.shared.MAGNAMENT_URL+'/savePackageItems', items, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public savePackages(packs) {
    return this.http.post<PackageEntity>(this.shared.MAGNAMENT_URL+'/savePackages', packs, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public deletePackage(id) {
    return this.http.get<any>(this.shared.MAGNAMENT_URL+'/deletePackage/'+id, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public deletePackageItem(id) {
    return this.http.get<any>(this.shared.MAGNAMENT_URL+'/deletePackageItem/'+id, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCourseStructure(courseId){
    return this.http.get<CourseStructureDto[]>(this.shared.MAGNAMENT_URL+'/getCourseStructure/'+courseId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getStaticCalendars(){
    return this.http.get<StaticCalendarEntity[]>(this.shared.MAGNAMENT_URL+'/getStaticCalendars/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public cloneStaticCalendar(staticCalendarId){
    return this.http.get<StaticCalendarEntity[]>(this.shared.MAGNAMENT_URL+'/cloneStaticCalendar/'+staticCalendarId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public deleteStaticCalendarEvent(staticCalendarEvent){
    return this.http.get<JustBooleanDto>(this.shared.MAGNAMENT_URL+'/deleteStaticCalendarEvent/'+staticCalendarEvent.id, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getStaticCalendarEvents(data){
    return this.http.get<StaticCalendarEventEntity[]>(this.shared.MAGNAMENT_URL+'/getStaticCalendarEvents/'+data, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveStaticCalendar(staticCalendar) {
    return this.http.post<StaticCalendarEntity>(this.shared.MAGNAMENT_URL+'/saveStaticCalendar', staticCalendar, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveStaticCalendarEvent(staticCalendarEvent) {
    return this.http.post<StaticCalendarEventEntity>(this.shared.MAGNAMENT_URL+'/saveStaticCalendarEvent', staticCalendarEvent, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
}
