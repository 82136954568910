export class PricetableEntity {
  corpId: number;
  id: number;
  name: string;
  description: string;
  price: number;
  enrollment: number;
  monthly: number;
  numOfMonths: number;
  totality: boolean;
  totalityPercentaje: number;
  courseId: number;
  courseTypeId: number;
  modalityId: number;
  siteId: number;
  turnId: number;
  currency: string;
  multisubscription: number[];
  bankPackageId: number;
}

