
export class NotificationEntity {
  corpId: number;
  id: number;
  message: string;
  type: string;
  startDate: string;
  endDate: string;
  videoReference: string;
  libraryReference: string;
  examReference: string;
  url: string;
  showed: boolean;
  subscriptionId: number[];
}
