import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";

export class ModuleItemEntity {
  corpId: number;
  id: number;
  type: number;
  reference: string;
  moduleFolderId: number;
  showInCampus: boolean;
  showInMagnament: boolean;
  itemOrder: number;
}
