import {Component} from '@angular/core';
import {Shared} from "../../shared/shared";
import {LoginService} from "../../../service/LoginService";
import {AchievementService} from "../../../service/AchievementService";
import {AchievementEntity} from "../../../model/AchievementEntity";

@Component({
  selector: 'app-achievements',
  templateUrl: 'achievements.html',
  styleUrls: ['achievements.scss']
})
export class Achievements {

  achievements: AchievementEntity[];
  current: AchievementEntity;

  headers: any = {
    columns : {
      alias: {
        title: 'Alias'
      },
      corpId: {
        title: 'Corp ID',
        hide: true
      },
      id: {
        title: 'ID',
        hide: true
      },
      img: {
        title: 'Image',
        hide: true
      },
      title: {
        title: 'Title'
      },
      type: {
        title: 'Type'
      },
      value: {
        title: 'Value'
      }
    },
    actions: false,
  }

  constructor(private shared: Shared, private achievementService: AchievementService) {
  }

  ngOnInit() {
    this.achievementService.getAchievement().subscribe(x=>{
      if(x != null) {
        this.achievements = x;
      }
    });
  }

  rowSelectedEmitter(row: AchievementEntity){
    this.current = row;
  }

}
