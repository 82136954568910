import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'boolean-component' ,
  templateUrl: 'booleanComponent.html',
  styleUrls: ['booleanComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BooleanComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Output() changeBool:  EventEmitter<{row: any, bool: boolean}> = new EventEmitter();
  rowData: any;

  bool: boolean;

  ngOnInit() {
    if(this.value == null || this.value == undefined || this.value.length == 0) {
      this.bool = false;
    }else{
      this.bool = JSON.parse(this.value);
    }
  }

  change(){
    this.bool = !this.bool;
    this.changeBool.emit({row: this.rowData, bool: this.bool});
  }
}
