import {TypeOfEventEntity} from "../model/TypeOfEventEntity";

export class SearchEventDto {
  title: string;
  fromStart: string;
  toStart: string;
  fromEnd: string;
  toEnd: string;
  typeOfEvent: number[];
  subject: number[];
  topic: number[];
  enabled: boolean;
  haveReference: boolean;


  constructor() {
  }
}
