import {Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {CalendarService} from "../../../service/CalendarService";
import {StepContentEntity} from "../../../model/StepContentEntity";
import {StepEntity} from "../../../model/StepEntity";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {CourseTypeEntity} from "../../../model/CourseTypeEntity";
import {ModalityEntity} from "../../../model/ModalityEntity";
import {SiteEntity} from "../../../model/SiteEntity";
import {TurnEntity} from "../../../model/TurnEntity";
import {SubjectService} from "../../../service/SubjectService";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {PackageEntity} from "../../../model/CalendarPackage";
import {TypeOfEventEntity} from "../../../model/TypeOfEventEntity";

@Component({
  selector: 'app-steps-data',
  templateUrl: 'stepsData.html',
  styleUrls: ['stepsData.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepsData {

  steps: StepEntity[];
  stepContents: StepContentEntity[];
  packages: PackageEntity[];
  typeOfEvents: TypeOfEventEntity[];

  courses: CourseEntity[];
  coursesType: CourseTypeEntity[];
  modalities: ModalityEntity[];
  sites: SiteEntity[];
  turns: TurnEntity[];

  subjects: SubjectEntity[];
  topics: TopicEntity[];

  currentStep : number;

  @ViewChild('stepContentHeader') stepContentHeader:ElementRef;

  constructor(private calendarService: CalendarService, private courseService: CourseService, private subjectService: SubjectService, private shared: Shared, private renderer: Renderer2) {
  }

  ngOnInit() {
   this.myInit();
  }

  async myInit(){
    this.calendarService.getSteps(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.steps = x;
      }
    });
    this.calendarService.getStepContents(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.stepContents = x;
      }
    });

    //Courses
    this.courseService.getCourses(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.courses = x;
      }
    });
    this.courseService.getCoursesType(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.coursesType = x;
      }
    });
    this.courseService.getModalities(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.modalities = x;
      }
    });
    this.courseService.getSites(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.sites = x;
      }
    });
    this.courseService.getTurns(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.turns = x;
      }
    });


    (await this.subjectService.getSubjects()).subscribe(x => {
      if (x != null) {
        this.subjects = x;
      }
    });
    (await this.subjectService.getTopics()).subscribe(x => {
      if (x != null) {
        this.topics = x;
      }
    });
    this.calendarService.getTypeOfEvents().subscribe(x => {
      if (x != null) {
        this.typeOfEvents = x;
      }
    });
  }

  filterStepContents(id: number){
    this.calendarService.getStepContentsByStep(id).subscribe(x => {
      if (x != null) {
        this.stepContents = x;
        this.renderer.selectRootElement('#stepContentHeader').click();
      }
    });
  }

  filterPackage(id: number){
    this.currentStep = id;
    this.packages = [];
    this.calendarService.getPackages(id).subscribe(x => {
      if (x != null) {
        this.packages = x;
      }
    });
  }
}
