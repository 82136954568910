import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {CourseService} from "../../../../service/CourseService";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {CalendarComponent} from "../../generic/table/calendarComponent/calendarComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {ModalityEntity} from "../../../../model/ModalityEntity";
import {CourseTypeEntity} from "../../../../model/CourseTypeEntity";
import {CourseEntity} from "../../../../model/CourseEntity";
import {SiteEntity} from "../../../../model/SiteEntity";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {TurnEntity} from "../../../../model/TurnEntity";
import * as moment from "moment";
import {BasicDto} from "../../../../dto/BasicDto";
import {ContextService} from "../../../../service/ContextService";
import {CourseFrontPageEntity} from "../../../../model/CourseFrontPageEntity";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'app-site',
  templateUrl: 'site.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Site {

  @Input() sites: SiteEntity[];
  @Input() modalities: ModalityEntity[];
  @Input() coursesType: CourseTypeEntity[];
  @Input() courses: CourseEntity[];

  @Output() filter = new EventEmitter<number>();

  aux: ModalityEntity[];
  roles: BasicDto[];

  newEntity: SiteEntity;
  modeAdd = false;

  showStartDate = false;
  showEndDate = false;
  showOpenSignupDate = false;
  showCloseSignupDate = false;

  currentSite: SiteEntity;
  currentCourseFrontPage: CourseFrontPageEntity;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  headers: any = {
    columns: {
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.alias = data.text;
              this.saveSite(site, false);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.name = data.text;
              this.saveSite(site, false);
            });
          }
        },
      },
      modalityId: {title: 'Modality', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.aux}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.modalityId = data.id;
              this.saveSite(site, false);
            });
          }
        },
      },
      startDate: {
        title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.startDate = data.date;
              this.saveSite(site, false);
            });
          }
        },
      },
      endDate: {
        title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.endDate = data.date;
              this.saveSite(site, false);
            });
          }
        },
      },
      openSignupDate: {
        title: 'Open signup date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.openSignupDate = data.date;
              this.saveSite(site, false);
            });
          }
        },
      },
      closeSignupDate: {
        title: 'Close signup date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.closeSignupDate = data.date;
              this.saveSite(site, false);
            });
          }
        },
      },
      defaultRole: {
        title: 'Default role', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, options: this.roles}
        },
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.defaultRole = data.id;
              this.saveSite(site, false);
            });
          }
        },
      },
      contentNotification: {
        title: 'Content notification', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.contentNotification = data.bool;
              this.saveSite(site, false);
            });
          }
        },
      },
      showInRegister: {
        title: 'Show in Register', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.showInRegister = data.bool;
              this.saveSite(site, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.showInCampus = data.bool;
              this.saveSite(site, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let site = this.sites.find(x => x.id == data.row.id)
              site.showInMagnament = data.bool;
              this.saveSite(site, false);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
      corpId: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'brush-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.customizeCourse(data);
            });
          }
        },
      },
      row2: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'copy-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.cloneSite(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private courseService: CourseService, private contextService: ContextService, private shared: Shared) {
  }

  ngOnInit() {
    this.aux = this.modalities.map(x=> {
      let courseType = this.coursesType.find(y=> y.id == x.courseTypeId)
      let course = this.courses.find(p=> p.id == courseType.courseId);
      x.name = x.name+" ("+courseType.alias+" - "+course.alias+")";
      return x;
    });

    this.contextService.getRoles().subscribe(t => {
      if (t != null) {
        this.roles = t;
      }
    });
  }

  customizeCourse(id: number) {
    this.currentSite = this.sites.find(x => x.id == id);
    this.currentCourseFrontPage = new CourseFrontPageEntity();
    this.currentCourseFrontPage.corpId = this.shared.CORP_ID;
    this.currentCourseFrontPage.siteId = id;
    this.currentCourseFrontPage.html = "";
    this.currentCourseFrontPage.backgroundImage = "";
    this.currentCourseFrontPage.enabled = true;
    this.courseService.getCourseFrontPageBySite(this.currentSite.id).subscribe(t => {
      if (t != null) {
        this.currentCourseFrontPage = t;
      }
    });
  }

  saveCourseFrontPage() {
    this.courseService.saveCourseFrontPage(this.currentCourseFrontPage).subscribe(t => {
      if (t != null) {
        this.currentCourseFrontPage = t;
      }
    });
    this.currentSite = null;
  }


  nextStep(id: number){
    this.filter.emit(id);
  }

  cloneSite(id){
    let bool = confirm("¿Estás seguro que deseas duplicar el site?");

    if(bool) {
      this.courseService.cloneSite(id).subscribe((w: SiteEntity[]) => {
        if (w != null) {
          this.sites = w;
        }
      });
    }
  }

  saveSite(site: SiteEntity, bool) {
    this.courseService.saveSite(site).subscribe( t =>{
      if(t!=null) {
        if(bool){this.sites.push(t);}
        this.sites = this.sites.filter(x => {return x});
      }
    });
  }

  async saveNewSite() {
    this.saveSite(this.newEntity, true);
    this.modeAdd = false;
  }

  newSite(){
    this.modeAdd = true;
    this.newEntity = new SiteEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

  changeStartDate(event){
    this.newEntity.startDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event){
    this.newEntity.endDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeOpenSingupDate(event){
    this.newEntity.openSignupDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeCloseSingupDate(event){
    this.newEntity.closeSignupDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  convertFileToBase64DocumentB(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentCourseFrontPage.backgroundImage = reader.result.toString();
    };
  }

  convertFileToBase64DocumentS(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentCourseFrontPage.subscriptionImage = reader.result.toString();
    };
  }

}
