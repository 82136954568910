import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {FlashcardEntity} from "../model/FlashcardEntity";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class FlashcardService extends ParentService{

  public async getFlashcards() {
    const corps = await this.shared.getCorpGroups();
    return this.http.get<FlashcardEntity[]>(this.shared.MAGNAMENT_URL + '/getFlashcards/' + corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getFlashcard(reference){
    return this.http.get<FlashcardEntity>(this.shared.MAGNAMENT_URL+'/getFlashcard/'+reference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public searchFlashcards(searchFlashcards){
    return this.http.post<FlashcardEntity[]>(this.shared.MAGNAMENT_URL+'/searchFlashcards', searchFlashcards, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadFlashcardMultimedia', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveFlashcard(fce){
    console.log(fce)
    return this.http.post<FlashcardEntity>(this.shared.MAGNAMENT_URL+'/saveFlashcard', fce, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
}
