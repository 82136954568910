import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {PlaceService} from "../../../service/PlaceService";
import {UniversityEntity} from "../../../model/UniversityEntity";

@Component({
  selector: 'app-universities',
  templateUrl: 'universities.html',
  styleUrls: ['universities.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Universities {

  universities: UniversityEntity[];

  showTable = true;
  modeAdd = false;

  newUniversityEntity: UniversityEntity;

  headers: any = {
    columns: {
      corpId: {title: 'corpId', hide: true, filter: false},
      id: {title: 'id', hide: true, filter: false},
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.alias = data.text;
              this.saveUniversity(university);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.name = data.text;
              this.saveUniversity(university);
            });
          }
        },
      },
      address: {title: 'Address', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.address = data.text;
              this.saveUniversity(university);
            });
          }
        },
      },
      province: {title: 'Province', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.province = data.text;
              this.saveUniversity(university);
            });
          }
        },
      },
      town: {title: 'Town', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.town = data.text;
              this.saveUniversity(university);
            });
          }
        },
      },
      cp: {title: 'CP', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.cp = data.text;
              this.saveUniversity(university);
            });
          }
        },
      },
      country: {title: 'Country', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.country = data.text;
              this.saveUniversity(university);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.showInCampus = data.bool;
              this.saveUniversity(university);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let university = this.universities.find(x => x.id == data.row.id)
              university.showInMagnament = data.bool;
              this.saveUniversity(university);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private placeService: PlaceService, private shared: Shared) {
  }

  ngOnInit() {
    this.placeService.getUniversities(this.shared.CORP_ID).subscribe( x=> {
      if(x!=null){
        this.universities = x;
      }
    });
  }

  async saveUniversity(university: UniversityEntity) {
    this.placeService.saveUniversity(university).subscribe( t =>{
        if(t!=null) {
          let index = this.universities.findIndex(x=> x.id == t.id);
          if(index != -1) {
            this.universities[index] = t;
          }else{
            this.universities.push(t);
          }
          this.universities = this.universities.filter(x => {return x});
        }
    });
  }

  async saveNewUniversity() {
    this.saveUniversity(this.newUniversityEntity);
    this.modeAdd = false;
  }

  newUniversity(){
    this.modeAdd = true;
    this.newUniversityEntity = new UniversityEntity();
    this.newUniversityEntity.corpId = this.shared.CORP_ID;
    this.newUniversityEntity.showInMagnament = true;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
