import {SubjectEntity} from "./SubjectEntity";
import {TopicEntity} from "./TopicEntity";
import {AnswerEntity} from "./AnswerEntity";
import {AnswerSubscriptionDto} from "../dto/AnswerSubscriptionDto";
import {QuestionMultimediaEntity} from "./QuestionMultimediaEntity";
import {QuestionTagEntity} from "./QuestionTagEntity";
import {OriginEntity} from "./OriginEntity";

export class QuestionEntity {
  corpId: number;
  id: number;
  reference: string;
  statement: string;
  comment: string;
  subject: SubjectEntity;
  topic: TopicEntity;
  startDate: string;
  endDate: string;
  difficulty: number;
  correctAnswer: number;
  successValue: number;
  wrongValue: number;
  officialQuestion: boolean;
  officialNum: number;
  officialYear: number
  officialExam: number;

  answers: AnswerEntity[];
  origins: number[];
  tags: number[];
  groups: number[];

  remaining: number;
  saved: boolean;

  answerSubscription: AnswerSubscriptionDto;
  multimedia: QuestionMultimediaEntity[];

  exams: String[];
}
