import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Questions } from './questions';
import {QuestionsRoutingModule} from "./questions-routing.module";
import {TableModule} from "../generic/table/table.module";
import {SwiperModule} from "swiper/angular";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimeDateModule, DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgToggleModule} from "ng-toggle-button";
import {QuestionService} from "../../../service/QuestionService";
import {AngularEditorModule} from "@kolkov/angular-editor";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        QuestionsRoutingModule,
        TableModule,
        SwiperModule,
        NgSelectModule,
        DlDateTimeDateModule,
        DlDateTimePickerModule,
        NgToggleModule,
        AngularEditorModule
    ],
  providers: [QuestionService],
  exports: [Questions],
  declarations: [Questions]
})
export class QuestionsModule {}
