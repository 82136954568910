import {CorporationEntity} from "./CorporationEntity";

export class SubjectEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  icon: string;
  showInCampus: boolean;
  showInMagnament: boolean;


}
