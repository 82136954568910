import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";

export class CourseTypeEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  courseId: number;
  startDate: string;
  endDate: string;
  openSignupDate: string;
  closeSignupDate: string;
  defaultRole: number;
  contentNotification: boolean;
  showInRegister: boolean;
  showInCampus: boolean;
  showInMagnament: boolean;

  examsParent: number;
  examsParentType: string;
  documentsParent: number;
  documentsParentType: string;
  videosParent: number;
  videosParentType: string;
}
