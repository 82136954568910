import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {StudentEntity} from "../model/StudentEntity";
import {SubscriptionEntity} from "../model/SubscriptionEntity";
import {BasicDto} from "../dto/BasicDto";
import {Payment} from "../model/Payment";
import {CommonUserDto} from "../dto/CommonUserDto";
import {HomologationStudentEntity} from "../model/HomologationStudent";
import {HomologationStudentRequestEntity} from "../model/HomologationStudentRequest";
import {HomologationsEntity} from "../model/HomologationsEntity";
import {HomologationRequestEntity} from "../model/HomologationRequest";
import {EmailTemplateEntity} from "../model/EmailTemplateEntity";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {CouponEntity} from "../model/CouponEntity";
import {RegisterLinkEntity} from "../model/RegisterLinkEntity";
import {SubscriptionPaymentsEntity} from "../model/SubscriptionPaymentsEntity";
import {StaticCalendarEntity} from "../model/StaticCalendarEntity";
import {SubscriptionStatisticsDto} from "../dto/SubscriptionStatisticsDto";
import {TutorEntity} from "../model/TutorEntity";
import {ScholarshipEntity} from "../model/ScholarshipEntity";
import {SubscriptionScholarshipEntity} from "../model/SubscriptionScholarshipEntity";
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {JustBooleanDto} from "../dto/JustBooleanDto";

@Injectable()
export class UserService extends ParentService{

  public checkEmail(email) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/checkEmail/'+email+'/'+this.shared.CURRENT_CORP.corpGroup, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public checkNif(nif) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/checkNif/'+nif+'/'+this.shared.CURRENT_CORP.corpGroup, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public checkEmailByReference(email, reference) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/checkEmail/'+email+'/'+reference+'/'+this.shared.CURRENT_CORP.corpGroup, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public checkNifByReference(nif, reference) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/checkNif/'+nif+'/'+reference+'/'+this.shared.CURRENT_CORP.corpGroup, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveNotifications(){
    return this.http.get(this.shared.MAGNAMENT_URL+'/saveNotifications/'+this.shared.CURRENT_SUBSCRIPTION.reference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveStudent(student){
    return this.http.post<StudentEntity>(this.shared.MAGNAMENT_URL+'/saveStudent', student, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public saveStudentByExcel(student){
    return this.http.post<StudentEntity>(this.shared.MAGNAMENT_URL+'/saveStudentByExcel', student, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public async getStudents(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<StudentEntity[]>(this.shared.MAGNAMENT_URL+'/getStudents/'+corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public searchStudents(obj){
    return this.http.post<StudentEntity[]>(this.shared.MAGNAMENT_URL+'/searchStudents', obj, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getBasicData(){
    return this.http.get<BasicDto[]>(this.shared.MAGNAMENT_URL+'/getBasicData', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public async getBasicDataByCorp(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<BasicDto[]>(this.shared.MAGNAMENT_URL+'/getBasicData/'+corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public async getCommonUserData(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<CommonUserDto>(this.shared.MAGNAMENT_URL+'/getCommonUserData/'+corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSubscriptions(studentReference){
    return this.http.get<SubscriptionEntity[]>(this.shared.MAGNAMENT_URL+'/getSubscriptions/'+studentReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSubscriptionsPayments(subscriptionReference){
    return this.http.get<SubscriptionPaymentsEntity>(this.shared.MAGNAMENT_URL+'/getSubscriptionPayments/'+subscriptionReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSubscriptionsStatistics(subscriptionReference){
    return this.http.get<SubscriptionStatisticsDto[]>(this.shared.MAGNAMENT_URL+'/getSubscriptionStatistics/'+subscriptionReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getLeads(){
    return this.http.get<StudentEntity[]>(this.shared.MAGNAMENT_URL+'/getLeads', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public async getCoupons(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<CouponEntity[]>(this.shared.MAGNAMENT_URL+'/getCoupons/'+corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getStaticCalendars(courseId){
    return this.http.get<StaticCalendarEntity[]>(this.shared.MAGNAMENT_URL+'/getStaticCalendarsByCourse/'+courseId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public generatePayments(subscriptionPayments: SubscriptionPaymentsEntity){
    return this.http.get<SubscriptionPaymentsEntity>(this.shared.MAGNAMENT_URL+'/generatePayments/'+subscriptionPayments.id, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public savePayment(payment: Payment){
    return this.http.post<Payment>(this.shared.MAGNAMENT_URL+'/savePayment',payment, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public deletePayment(payment: Payment){
    return this.http.post(this.shared.MAGNAMENT_URL+'/deletePayment',payment, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveSubscriptionPayment(spe: SubscriptionPaymentsEntity){
    return this.http.post(this.shared.MAGNAMENT_URL+'/saveSubscriptionPayment',spe, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public saveSubscriptionData(sub: SubscriptionEntity){
    return this.http.post(this.shared.MAGNAMENT_URL+'/saveSubscriptionData', sub, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public testCalendar(sub: SubscriptionEntity){
    return this.http.get(this.shared.MAGNAMENT_URL+'/testCalendar/'+sub.reference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getHomologations(homologationId){
    return this.http.get<HomologationsEntity[]>(this.shared.MAGNAMENT_URL+'/getHomologations/'+homologationId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getHomologationsRequest(homologationId){
    return this.http.get<HomologationRequestEntity[]>(this.shared.MAGNAMENT_URL+'/getHomologationsRequest/'+homologationId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getUserHomologations(userReference){
    return this.http.get<HomologationStudentEntity[]>(this.shared.MAGNAMENT_URL+'/getUserHomologations/'+userReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getSubscriptionScholarships(subReference){
    return this.http.get<SubscriptionScholarshipEntity[]>(this.shared.MAGNAMENT_URL+'/getSubscriptionScholarships/'+subReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getUserRequestHomologations(homologationStudentId){
    return this.http.get<HomologationStudentRequestEntity[]>(this.shared.MAGNAMENT_URL+'/getUserRequestHomologations/'+homologationStudentId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getScholarships() {
    return this.http.get<ScholarshipEntity[]>(this.shared.MAGNAMENT_URL+'/getScholarships', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveScholarship(scholarship) {
    return this.http.post<ScholarshipEntity>(this.shared.MAGNAMENT_URL+'/saveScholarship', scholarship, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveSubscriptionScholarship(subscriptionScholarship) {
    return this.http.post<SubscriptionScholarshipEntity>(this.shared.MAGNAMENT_URL+'/saveSubscriptionScholarship', subscriptionScholarship, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveHSRequest(hsRequest){
    return this.http.post(this.shared.MAGNAMENT_URL+'/saveHomologationStudentRequest',hsRequest, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public saveHStudent(hStudent){
    return this.http.post(this.shared.MAGNAMENT_URL+'/saveHomologationStudent',hStudent, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }


  public saveRegisterLink(registerLink){
    return this.http.post<RegisterLinkEntity>(this.shared.MAGNAMENT_URL+'/saveRegisterLink',registerLink, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getRegisterLink(){
    return this.http.get<RegisterLinkEntity[]>(this.shared.MAGNAMENT_URL+'/getRegisterLinks/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getRegisterLinkReference(){
    return this.http.get<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/getRegisterLinkReference', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getEmailTemplates(){
    return this.http.get<EmailTemplateEntity[]>(this.shared.MAGNAMENT_URL+'/getEmailTemplates/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSubscriptionsNotifications(studentsId){
    return this.http.post<SubscriptionEntity[]>(this.shared.MAGNAMENT_URL+'/getSubscriptionsNotifications', studentsId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public sendEmail(newEmail){
    return this.http.post<any>(this.shared.MAGNAMENT_URL+'/sendEmail', newEmail, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public sendNotification(newNotification){
    return this.http.post<any>(this.shared.MAGNAMENT_URL+'/sendNotification', newNotification, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public downloadContent(obj){
    return this.http.post<JustBooleanDto>(this.shared.MAGNAMENT_URL+'/executeDownloadNow', obj, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCountries() {
    return this.http.get<BasicDto[]>(this.shared.MAGNAMENT_URL+'/getCountries', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public get() {
    return this.http.get<BasicDto[]>(this.shared.MAGNAMENT_URL+'/getCountries', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadAttachment(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadAttachment', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadPaymentFile(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadPaymentFile', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadScholarshipFile(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadScholarshipFile', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public generateCalendarSub(sub: string){
    return this.http.get<any>(this.shared.MAGNAMENT_URL+'/createCalendar/'+sub, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getUserToken(ref){
    return this.http.get<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/getUserToken/'+ref, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
}
