import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {VideoPlaylistEntity} from "../../../../model/VideoPlaylistEntity";
import {VideoService} from "../../../../service/VideoService";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";

@Component({
  selector: 'app-video-playlist',
  templateUrl: 'videoPlaylist.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlaylist {

  @Input() videoPlaylist: VideoPlaylistEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: VideoPlaylistEntity;
  modeAdd = false;

  headers: any = {
    columns: {
      corpId: {title: 'Statement', hide: true, filter: false},
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let vp = this.videoPlaylist.find(x => x.id == data.row.id)
              vp.name = data.text;
              this.saveVideoPlaylist(vp, false);
            });
          }
        },
      },
      enabled: {
        title: 'Enabled', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let vp = this.videoPlaylist.find(x => x.id == data.row.id)
              vp.enabled = data.bool;
              this.saveVideoPlaylist(vp, false);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
    },
    actions: false,
  };

  constructor(private videoService: VideoService, private shared: Shared) {
  }

  ngOnInit() {
    console.log("Hola");
  }

  nextStep(id: number){
    this.filter.emit(id);
  }

  saveVideoPlaylist(videoPlaylist: VideoPlaylistEntity,bool) {
    this.videoService.saveVideoPlaylist(videoPlaylist).subscribe( t =>{
      if(t!=null) {
        if(bool){this.videoPlaylist.push(t);}
        this.videoPlaylist = this.videoPlaylist.filter(x => {return x});
      }
    });
  }

  async saveNewBankPackage() {
    this.saveVideoPlaylist(this.newEntity,true);
    this.modeAdd = false;
  }

  newBankPackage(){
    this.modeAdd = true;
    this.newEntity = new VideoPlaylistEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
  }

}
