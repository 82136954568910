import {TypeOfEventEntity} from "../model/TypeOfEventEntity";

export class DownloadStudentSettingDto {

  bsCorporation: boolean;
  bsName: boolean;
  bsSurname: boolean;
  bsAddress: boolean;
  bsProvince: boolean;
  bsEmail: boolean;
  bsTown: boolean;
  bsMobile: boolean;
  bsCP: boolean;
  bsNIF: boolean;
  bsCountry: boolean;
  bsUniversity: boolean;
  bsHospital: boolean;

  bbCorporation: boolean;
  bbReference: boolean;
  bbEnabled: boolean;
  bbTestsubscription: boolean;
  bbEnrolled: boolean;
  bbDisabledreason: boolean;
  bbWorkgroup: boolean;
  bbTutor: boolean;
  bbScholarship: boolean;
  bbCourse: boolean;
  bbCoursetype: boolean;
  bbModality: boolean;
  bbSite: boolean;
  bbTurn: boolean;
  bbStartdate: boolean;
  bbEnddate: boolean;
  bbComments: boolean;
  bbRegisterLinkUsed: boolean;

  bcCourseprice: boolean;
  bcEnrollment: boolean;
  bcMonthly: boolean;
  bcNumofmonths: boolean;
  bcCoupons: boolean;

  bpPaid: boolean;
  bpRestore: boolean;
  bpStartdate: boolean;
  bpEnddate: boolean;
  bpDonedate: boolean;
  bpRestoredate: boolean;
  bpTypeofpayment: boolean;
  bpFormatofpayment: boolean;
  bpPrice: boolean;
  bpCurrency: boolean;

  constructor() {
    this.bsName = false;
    this.bsSurname = false;
    this.bsAddress = false;
    this.bsProvince = false;
    this.bsEmail = false;
    this.bsTown = false;
    this.bsMobile = false;
    this.bsCP = false;
    this.bsNIF = false;
    this.bsCountry = false;
    this.bsUniversity = false;
    this.bsHospital = false;

    this.bbReference = false;
    this.bbEnabled = false;
    this.bbTestsubscription = false;
    this.bbDisabledreason = false;
    this.bbWorkgroup = false;
    this.bbTutor = false;
    this.bbScholarship = false;
    this.bbCourse = false;
    this.bbCoursetype = false;
    this.bbModality = false;
    this.bbSite = false;
    this.bbTurn = false;
    this.bbStartdate = false;
    this.bbEnddate = false;
    this.bbComments = false;

    this.bcCourseprice = false;
    this.bcEnrollment = false;
    this.bcMonthly = false;
    this.bcNumofmonths = false;
    this.bcCoupons = false;

    this.bpPaid = false;
    this.bpRestore = false;
    this.bpStartdate = false;
    this.bpEnddate = false;
    this.bpDonedate = false;
    this.bpRestoredate = false;
    this.bpTypeofpayment = false;
    this.bpFormatofpayment = false;
    this.bpPrice = false;
    this.bpCurrency = false;
  }
}
