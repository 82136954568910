import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CourseEntity} from "../model/CourseEntity";
import {CourseTypeEntity} from "../model/CourseTypeEntity";
import {ModalityEntity} from "../model/ModalityEntity";
import {TurnEntity} from "../model/TurnEntity";
import {SiteEntity} from "../model/SiteEntity";
import {WorkgroupEntity} from "../model/WorkgroupEntity";
import {TopicEntity} from "../model/TopicEntity";
import {SubjectEntity} from "../model/SubjectEntity";
import {ExamStatisticsDto} from "../dto/ExamStatisticsDto";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";


@Injectable()
export class StatisticsService extends ParentService{

  public getStatistics(examSettingId, isComplete) {
    return this.http.get<ExamStatisticsDto[]>(this.shared.MAGNAMENT_URL+'/getExamStatistics/'+examSettingId+'/'+isComplete, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

}
