import {SubjectEntity} from "./SubjectEntity";
import {TopicEntity} from "./TopicEntity";

export class FlashcardEntity {
  corpId: number;
  id: number;
  reference: string;
  statement: string;
  statementFileUrl: string;
  subject: SubjectEntity;
  topic: TopicEntity;
  difficulty: number;
  answer: string;
  answerFileUrl: string;
  showInCampus: boolean;
  showInMagnament: boolean;
}
