import {TypeOfEventEntity} from "./TypeOfEventEntity";

export class StaticCalendarEventEntity {
  corpId: number;
  id: number;
  staticCalendarId: number;
  title: string;
  type: number;
  fullType: TypeOfEventEntity;
  subjectId: number;
  topicId: number;
  startDate: string;
  endDate: string;
  showInCampus: boolean;
  showInMagnament: boolean;
  auxReference: string;
  auxType: string;
}
