import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {CourseService} from "../../../service/CourseService";
import {Shared} from "../../shared/shared";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {TutorEntity} from "../../../model/TutorEntity";
import {HospitalEntity} from "../../../model/HospitalEntity";
import {SpecialityEntity} from "../../../model/SpecialityEntity";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import { ScholarshipEntity } from 'src/app/model/ScholarshipEntity';
import {UserService} from "../../../service/UserService";
import {AlertController} from "@ionic/angular";

@Component({
  selector: 'app-scholarship',
  templateUrl: 'scholarship.html',
  styleUrls: ['scholarship.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Scholarship {

  scholarships: ScholarshipEntity[];

  showTable = true;
  modeAdd = false;

  caretPos: number = 0;
  selectedFile: any;

  newScholarshipEntity: ScholarshipEntity;
  currentScholship: ScholarshipEntity;

  headers: any = {
    columns: {
      title: {title: 'Title', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let scholarship = this.scholarships.find(x => x.id == data.row.id)
              scholarship.title = data.text;
              this.saveScholarship(scholarship);
            });
          }
        },
      },
      description: {title: 'Description', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let scholarship = this.scholarships.find(x => x.id == data.row.id)
              scholarship.description = data.text;
              this.saveScholarship(scholarship);
            });
          }
        },
      },
      baseDocument: {title: 'Base document', hide: false, filter: true},
      requiredDocument: {
        title: 'Required document', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let scholarship = this.scholarships.find(x => x.id == data.row.id)
              scholarship.requiredDocument = data.bool;
              this.saveScholarship(scholarship);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let scholarship = this.scholarships.find(x => x.id == data.row.id)
              scholarship.showInCampus = data.bool;
              this.saveScholarship(scholarship);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let scholarship = this.scholarships.find(x => x.id == data.row.id)
              scholarship.showInMagnament = data.bool;
              this.saveScholarship(scholarship);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {return {cell: cell, icon: 'cloud-upload-outline', text: '', color: ''}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
               this.changeBaseDocument(data, false);
            });
            instance.changeContextButton.subscribe(data => {
              this.changeBaseDocument(data, true);
            });
          }
        },
      },
    },
    actions: false,
  }

  @ViewChild('fileButton') fileButton: ElementRef;

  constructor(private userService: UserService, private shared: Shared, private alertController: AlertController) {
  }

  ngOnInit() {
    this.userService.getScholarships().subscribe( x=> {
      if(x!=null){
        this.scholarships = x;
      }
    });
  }

  async saveScholarship(scholarship: ScholarshipEntity) {
    scholarship.corpId = this.shared.CORP_ID;
    this.userService.saveScholarship(scholarship).subscribe( t =>{
        if(t!=null) {
          let index = this.scholarships.findIndex(x=> x.id == t.id);
          if(index != -1) {
            this.scholarships[index] = t;
          }else{
            this.scholarships.push(t);
          }
          this.scholarships = this.scholarships.filter(x => {return x});
        }
    });
  }

  async changeBaseDocument(id, bool) {
    let row = this.scholarships.find(x=> x.id == id);

    if(row.baseDocument == null || bool) {
      this.currentScholship = row;
      this.fileButton.nativeElement.click();
    }else{
      window.open('https://assets.mediplus.es/'+row.baseDocument, '_blank');
    }
  }

  selFile2(evt) {
    this.selectedFile = evt.target.files[0];

    this.userService.uploadScholarshipFile(this.selectedFile).subscribe(x => {
      if (x != null) {
        evt.target.files = null;
        this.attachDocument(x);
      }
    });
  }

  async attachDocument(x) {
    const alert = await this.alertController.create({
      header: 'Upload base document',
      subHeader: x.reference,
      buttons: [{
        text: 'Upload and save',
        role: 'save',
        handler: () => {
          this.currentScholship.baseDocument = x.reference;
          this.saveScholarship(this.currentScholship);
        },
      }]
    });

    await alert.present();
  }

  async saveNewScholarship() {
    this.saveScholarship(this.newScholarshipEntity);
    this.modeAdd = false;
  }

  newScholarship(){
    this.modeAdd = true;
    this.newScholarshipEntity = new ScholarshipEntity();
    this.newScholarshipEntity.showInMagnament = true;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
