import {SubjectEntity} from "./SubjectEntity";
import {TopicEntity} from "./TopicEntity";

export class AnswerEntity {
  corpId: number;
  id: number;
  questionId: number;
  numAnswer: number;
  statement: string;

  constructor(corpId: number, numAnswer: number) {
    this.corpId = corpId;
    this.numAnswer = numAnswer;
  }
}
