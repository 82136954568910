import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Library } from './library';
import {LibraryRoutingModule} from "./library-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {SubjectService} from "../../../service/SubjectService";
import {LibraryService} from "../../../service/LibraryService";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {SwiperModule} from "swiper/angular";
import {NgToggleModule} from "ng-toggle-button";
import {LibraryImagesModule} from "../../libraryImages/libraryImages.module";
import {UserService} from "../../../service/UserService";


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    LibraryRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    NgxExtendedPdfViewerModule,
    SwiperModule,
    NgToggleModule,
    LibraryImagesModule
  ],
  providers: [SubjectService, LibraryService, UserService],
  exports: [
    Library
  ],
  declarations: [Library]
})
export class LibraryModule {}
