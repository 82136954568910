import {Component} from '@angular/core';
import {Shared} from "../shared/shared";

@Component({
  selector: 'app-menu',
  templateUrl: 'menu.html',
  styleUrls: ['menu.scss']
})
export class Menu {

  constructor(private shared: Shared) {}
}
