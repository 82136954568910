import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ForumData } from './forumData';
import {ForumDataRoutingModule} from "./forumData-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {Thread} from "./thread/thread";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {ForumService} from "../../../service/ForumService";
import {Forum} from "./forum/forum";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {Message} from "./message/message";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ForumDataRoutingModule,
        NgxDatatableModule,
        TableModule,
        DlDateTimePickerModule,
        NgSelectModule,
        NgbModule,
        AngularEditorModule
    ],
  providers: [ForumService],
  exports: [
    ForumData
  ],
    declarations: [ForumData, Thread, Forum, Message]
})
export class ForumDataModule {}
