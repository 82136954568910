import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import * as moment from "moment";
import {Payment} from "../../../../model/Payment";

@Component({
  selector: 'type-popover' ,
  templateUrl: 'typePopover.html',
  styleUrls: ['./../users.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TypePopover implements ViewCell, OnInit {
  @Input() value: string;
  @Output() typeSave:  EventEmitter<any> = new EventEmitter();

  showTypePopover: Boolean = false;
  rowData: Payment;
  tempRow: Payment;

  constructor() { }

  ngOnInit() {
    this.tempRow = this.rowData;
  }

  saveDataAndClose(){
    this.rowData = this.tempRow;
    this.typeSave.emit(this.rowData);
    this.showTypePopover = false;
  }

  openPopover(){
    this.showTypePopover = true;
  }

  selectPopover(val){
    this.tempRow.typeOfPayment=val;
  }

}
