import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {TopicEntity} from "../../../../model/TopicEntity";
import {SubjectEntity} from "../../../../model/SubjectEntity";
import {SubjectService} from "../../../../service/SubjectService";

@Component({
  selector: 'app-topic',
  templateUrl: 'topic.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Topic {

  @Input() topics: TopicEntity[];
  @Input() subjects: SubjectEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: TopicEntity;
  modeAdd = false;

  headers: any = {
    columns: {
      corpId: {title: 'Statement', hide: true, filter: false},
      id: {title: 'Id', hide: true, filter: false},
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let topic = this.topics.find(x => x.id == data.row.id)
              topic.alias = data.text;
              this.saveTopic(topic, false);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let topic = this.topics.find(x => x.id == data.row.id)
              topic.name = data.text;
              this.saveTopic(topic, false);
            });
          }
        },
      },
      subjectId: {title: 'Subject', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.subjects}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let topic = this.topics.find(x => x.id == data.row.id)
              topic.subjectId = data;
              this.saveTopic(topic, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let topic = this.topics.find(x => x.id == data.row.id)
              topic.showInCampus = data.bool;
              this.saveTopic(topic, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let topic = this.topics.find(x => x.id == data.row.id)
              topic.showInMagnament = data.bool;
              this.saveTopic(topic, false);
            });
          }
        },
      }
    },
    actions: false,
  }

  constructor(private subjectService: SubjectService, private shared: Shared) {
  }

  ngOnInit() {
  }

  nextStep(id: number){
    this.filter.emit(id);
  }

  saveTopic(topic: TopicEntity, bool) {
    this.subjectService.saveTopic(topic).subscribe( t =>{
      if(t!=null) {
        if(bool){this.topics.push(t);}
        this.topics = this.topics.filter(x => {return x});
      }
    });
  }

  async saveNewTopic() {
    this.saveTopic(this.newEntity, true);
    this.modeAdd = false;
  }

  newTopic(){
    this.modeAdd = true;
    this.newEntity = new TopicEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }
}
