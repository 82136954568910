import {Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";

import * as moment from "moment";

import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {CommonQuestionDto} from "../../../dto/CommonQuestionDto";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {FlashcardEntity} from "../../../model/FlashcardEntity";
import {SearchFlashcardDto} from "../../../dto/SearchFlashcardDto";
import {FlashcardService} from "../../../service/FlashcardService";
import {SubjectService} from "../../../service/SubjectService";
import {QuestionMultimediaEntity} from "../../../model/QuestionMultimediaEntity";
import {ImagesService} from "../../../service/ImagesService";
import {reference} from "@popperjs/core";

const swiper = new Swiper('.swiper', {
  modules: [Navigation, Pagination],
});

@Component({
  selector: 'app-flashcards',
  templateUrl: 'flashcards.html',
  styleUrls: ['flashcards.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Flashcards {

  @Output() addFlashcard = new EventEmitter<string>();

  flashcards: FlashcardEntity[];
  current: FlashcardEntity;

  subjects: SubjectEntity[];
  topics: TopicEntity[];

  commonQuestion: CommonQuestionDto;
  answer: string;
  statementFileUrl: string;
  answerFileUrl: string;

  searchSubjects: SubjectEntity[];
  searchTopics: TopicEntity[];

  searchFlashcard: SearchFlashcardDto;

  currentTab = 1;
  bigImage = -1;
  currentSubjectIndex = -1;

  subjectId = null;
  topicId = null;

  showTable = true;
  searchDiv: boolean = false;

  searchD1: boolean = false;
  searchD2: boolean = false;
  searchD3: boolean = false;
  searchD4: boolean = false;
  searchD5: boolean = false;

  headers: any = {
    columns: {
      statement: {title: 'Statement', hide: false, filter: false,
        valuePrepareFunction: (statement: string) => {
          return statement.substring(0,80);
        }
      },
      subject: {
        title: 'Subject', hide: false, filter: false,
        valuePrepareFunction: (subject: SubjectEntity) => {
          if(subject!=null) {
            return subject.name;
          }else{
            return "";
          }
        }
      },
      topic: {
        title: 'Topic', hide: false, filter: false,
        valuePrepareFunction: (topic: TopicEntity) => {
          if(topic!=null) {
            return topic.name;
          }else{
            return "";
          }
        }
      },
      corp: {title: 'Corp', hide: true, filter: false},
      answer: {title: 'Answer', hide: true, filter: false,
        valuePrepareFunction: (answer: string) => {
          return answer.substring(0,80);
        }
      },
      difficulty: {title: 'Difficulty', hide: true, filter: false},
      id: {title: 'ID', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      showInCampus: {title: 'Show in campus', hide: true, filter: false},
      showInMagnament: {title: 'Show in magnament', hide: true, filter: false}
    },
    actions: false,
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  constructor(
    private flashcardService: FlashcardService,
    private subjectService: SubjectService,
    private shared: Shared
  ) {
  }

  ngOnInit() {
    let flashcardReference = this.shared.getLastVariableFromUrl('reference');

    this.subjectService.getSubjects().then(response => {
      response.subscribe(subjects => {
        if (subjects != null) {
          if (flashcardReference != null) {
            this.flashcardService.getFlashcard(flashcardReference).subscribe(flashcard => {
              if (flashcard != null) {
                this.rowSelectedEmitter(flashcard);
              }
            })
          }
          this.searchSubjects = subjects
          this.subjects = subjects
        }
      })
    })
    this.subjectService.getTopics().then(response => {
      response.subscribe(topics => {
        this.topics = topics
        this.searchTopics = topics
      })
    })
    this.flashcardService.getFlashcards().then(response => {
      response.subscribe(flashcards => {
        if (flashcards != null) {
          this.flashcards = flashcards;
        }
      })
    })
  }

  rowSelectedEmitter(row: FlashcardEntity) {
    this.current = row;
    this.searchDiv = false;

    this.answer = row.answer
    this.statementFileUrl = row.statementFileUrl;
    this.answerFileUrl = row.answerFileUrl;

    if (this.current.subject != null) {
      this.currentSubjectIndex = this.searchSubjects.findIndex(x => x.id == this.current.subject.id);
      this.subjectId = this.current.subject.id;
      this.subjectService.getTopicsBySubject(this.subjectId).subscribe(topics => {
        this.topics = topics
      })
    }
    else {
      this.subjectId = null
      this.topicId = null
      this.subjectService.getTopics().then(response => {
        response.subscribe(topics => {
          this.topics = topics
          this.searchTopics = topics
        })
      })
    }
    if(this.current.topic != null){
      this.topicId = this.current.topic.id;
    }
  }

  openSearchFlashCard(){
    this.searchFlashcard = new SearchFlashcardDto();
    this.searchDiv = true;
  }

  newFlashcard() {
    this.current = new FlashcardEntity();
    this.current.corpId = this.shared.CORP_ID;
    this.current.subject = new SubjectEntity();
    this.current.topic = new TopicEntity();
    this.currentSubjectIndex = 0;
  }

  getCorporation(corpId){
    return this.shared.CORPS.find(x=> x.id == corpId);
  }

  changeSubject() {
    this.current.subject = new SubjectEntity();
    this.current.subject.id = this.subjectId;
    this.subjectService.getTopicsBySubject(this.subjectId).subscribe(topics => {
      this.topics = topics
    })
    if(this.current.subject != null) {
      this.currentSubjectIndex = this.searchSubjects.findIndex(x => x.id == this.current.subject.id);
    }
  }

  changeTopic() {
    this.current.topic = new TopicEntity();
    this.current.topic.id = this.topicId;
  }

  async searchFlashcards() {
    this.searchFlashcard.difficulty = [];
    if (this.searchD1) this.searchFlashcard.difficulty.push(1);
    if (this.searchD2) this.searchFlashcard.difficulty.push(2);
    if (this.searchD3) this.searchFlashcard.difficulty.push(3);
    if (this.searchD4) this.searchFlashcard.difficulty.push(4);
    if (this.searchD5) this.searchFlashcard.difficulty.push(5);

    this.searchFlashcard.corps = (await this.shared.getCorpGroups()).split(',').map(Number);

    this.flashcardService.searchFlashcards(this.searchFlashcard).subscribe(flashcards => {
      if (flashcards != null) {
        this.flashcards = flashcards;
      }
    });
  }

  async saveFlashcard() {
    this.showTable = false;
    var obj = this.current

    this.flashcardService.saveFlashcard(obj).subscribe(x=>{
      if(x!=null){
        let i = this.flashcards.findIndex(t=>t.id == x.id);
        if(i!=-1){
          this.flashcards[i] = x;
        }else{
          this.flashcards.push(x);
        }
      }
    });
    let currentF = this.flashcards.findIndex(x => x.id == this.current.id);
    this.flashcards[currentF] = this.current;
    await this.delay(200);
    this.showTable = true;
  }

  uploadMultimedia(evt, type){
    this.flashcardService.uploadFile(evt.target.files[0]).subscribe(x=>{
      if(x!=null){
        if (type == 'statement') {
          this.statementFileUrl = x.reference
          this.current.statementFileUrl = x.reference
          console.log(x)
        }
        else if (type == 'answer') {
          this.answerFileUrl = x.reference
          this.current.answerFileUrl = x.reference
        }
      }
    });
  }

  checkBigImage(questionId) {
    if (this.bigImage != questionId) {
      this.bigImage = questionId
    } else {
      this.bigImage = -1
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

