import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'button-component' ,
  templateUrl: 'buttonComponent.html',
  styleUrls: ['buttonComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements ViewCell, OnInit {
  @Input() value: any;
  @Output() changeButton:  EventEmitter<number> = new EventEmitter();
  @Output() changeContextButton:  EventEmitter<number> = new EventEmitter();
  rowData: any;

  icon: string;
  text: string;
  color: string;

  ngOnInit() {
    this.icon = this.value.icon;
    this.text = this.value.text;
    this.color = this.value.color;
  }

  change(){
    this.changeButton.emit(this.value.cell);
  }

  changeContext(){
    this.changeContextButton.emit(this.value.cell);
  }
}
