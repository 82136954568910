import {SubjectEntity} from "./SubjectEntity";
import {TopicEntity} from "./TopicEntity";

export class ReportsEntity {

  corpId: number;
  id: number;
  searchObject: string;
  name: string;
  exportObject: string;
  emails: string;
  periocity: string;
  firstDate: string;
  lastExecution: string;
  incremental: boolean;
  enabled: boolean;
}

