import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {ContextService} from "../../../service/ContextService";
import {CorpStyleEntity} from "../../../model/CorpStyleEntity";

@Component({
  selector: 'app-corp-style',
  templateUrl: 'corpStyle.html',
  styleUrls: ['corpStyle.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CorpStyle {

  corners = [
    {'name': 'Rounded0', 'id': 'ROUNDED0'},
    {'name': 'Rounded10', 'id': 'ROUNDED10'},
    {'name': 'Rounded100', 'id': 'ROUNDED100'}
  ];

  styles = [
    {'name': 'Flat', 'id': 'FLAT'},
    {'name': 'Relief', 'id': 'RELIEF'}
  ];

  distributions = [
    {'name': 'Not defined', 'id': 'NOT DEFINED'}
  ];

  menus = [
    {'name': 'Side', 'id': 'SIDE'},
    {'name': 'Bottom', 'id': 'BOTTOM'}
  ];

  tagCloud = [
    {'name': 'Cloud', 'id': 'CLOUD'},
    {'name': 'Boxes', 'id': 'BOXES'}
  ];

  subjectResult = [
    {'name': 'Flip', 'id': 'FLIP'},
    {'name': 'Condensed', 'id': 'CONDENSED'}
  ];

  selectImage1 = false;
  selectImage2 = false;
  selectImage3 = false;
  selectImage4 = false;
  selectImage5 = false;
  selectImage6 = false;

  constructor(private contextService: ContextService, private shared: Shared) {
  }

  corpStyle: CorpStyleEntity;

  ngOnInit() {
    this.contextService.getCorpStyle().subscribe(x=>{
      if(x!=null){
        this.corpStyle = x;
      }
    });
  }

  saveCorpStyle(){
    console.log(this.corpStyle);
    this.contextService.saveCorpStyle(this.corpStyle).subscribe(x=>{
      if(x!=null){
        this.shared.goTo(['/','phoenix']);
      }
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
