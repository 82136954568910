import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {PricetableRoutingModule} from "./pricetable-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {CourseService} from "../../../service/CourseService";
import {Pricetable} from "./pricetable";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {ContextService} from "../../../service/ContextService";
import {AngularEditorModule} from "@kolkov/angular-editor";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        PricetableRoutingModule,
        NgxDatatableModule,
        TableModule,
        NgSelectModule,
        DlDateTimePickerModule,
        AngularEditorModule,
    ],
  providers: [CourseService, ContextService],
  exports: [
    Pricetable
  ],
  declarations: [Pricetable]
})
export class PricetableModule {}
