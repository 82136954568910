export class ScholarshipEntity {
  id: number;
  corpId: number;
  title: string;
  description: string;
  baseDocument: string;
  requiredDocument : boolean;
  showInCampus: boolean;
  showInMagnament: boolean;
}
