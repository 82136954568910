import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Module } from './module';
import {ModuleRoutingModule} from "./module-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {AchievementService} from "../../../service/AchievementService";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgCalendarModule} from "ionic2-calendar";
import {NgToggleModule} from "ng-toggle-button";
import {DndModule} from "ngx-drag-drop";
import {ModuleService} from "../../../service/ModuleService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ModuleRoutingModule,
    NgxDatatableModule,
    TableModule,
    DlDateTimePickerModule,
    NgSelectModule,
    NgCalendarModule,
    NgToggleModule,
    DndModule
  ],
  providers: [AchievementService, ModuleService],
  exports: [
    Module
  ],
  declarations: [Module]
})
export class ModuleModule {}
