export class StepContentEntity {
  corpId: number;
  id: number;
  calendarStepId: number;
  subjectId: number;
  topicId: number;
  maxTime: number;
  minTime: number;
  showInMagnament: boolean;
}
