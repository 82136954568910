export class LibraryFolderIntermediateEntity {
  id: number;
  libraryId: number;
  libraryFolderId: number;
  libraryOrder: number;
  enabled: boolean;
  startDate: string;
  endDate: string;
  typeOfRequirement: string;
  requirementReference: string;
  requirementPercentaje: number;
  requirementMoreThan: boolean;
  showRequirement: boolean;
}
