import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {WorkgroupRoutingModule} from "./workgroup-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {CourseService} from "../../../service/CourseService";
import {Workgroup} from "./workgroup";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    WorkgroupRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    DlDateTimePickerModule,
  ],
  providers: [CourseService],
  exports: [
    Workgroup
  ],
  declarations: [Workgroup]
})
export class WorkgroupModule {}
