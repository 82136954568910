export class ForumMessageEntity {
  corpId: number;
  id: number;
  forumId: number;
  forumMessageId: number;
  forumPrincipalId: number;
  title: string;
  message: string;
  audioUrl: string;
  videoUrl: string;
  categoryId: number;
  topicId: number;
  closed: boolean;
  answered: boolean;
  showInCampus: boolean;
  authorId: number;
  authorFullname: string;
  authorImage: string;
  authorType: string;
  created: string;
  updated: string;
  answers: ForumMessageEntity[];
}


