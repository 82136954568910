export class RegisterLinkEntity {
  corpId: number;
  id: number;
  reference: string;
  name: string;
  date: string;
  link: string;
  options: string;
  enabled: boolean;
}
