import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {SubjectService} from "../../../service/SubjectService";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IonAccordionGroup} from "@ionic/angular";
import {VideoFolderEntity} from "../../../model/VideoFolderEntity";
import {VideoEntity} from "../../../model/VideoEntity";
import {VideoService} from "../../../service/VideoService";

import videojs from 'video.js';
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {VideoFoldeCourseEntity} from "../../../model/VideoFolderCourseEntity";
import {VideoFolderIntermediateEntity} from "../../../model/VideoFolderIntermediateEntity";
import {BasicDto} from "../../../dto/BasicDto";
import {VideoFolderSubfolderEntity} from "../../../model/VideoFolderSubfolderEntity";
import {UserService} from "../../../service/UserService";
import {CourseStructureDto} from "../../../dto/CourseStructureDto";
import {CalendarService} from "../../../service/CalendarService";

@Component({
  selector: 'app-video',
  templateUrl: 'video.html',
  styleUrls: ['video.scss']
})
export class Video {

  folders: VideoFolderEntity[];
  foldersToDropdown: BasicDto[];
  currentFolder: VideoFolderEntity;

  videos: VideoEntity[];
  currentVideo: VideoEntity;

  subjects: SubjectEntity[];
  topics: TopicEntity[];
  basicData: BasicDto[];

  showVideoTable = true;
  showFolderTable = true;
  updateFromVimeo = false;
  waitingForUpload = false;

  showFolders = true;
  selectedFile: any;
  selectedFile2: any;
  selectedVideo: any;

  newFolderEntity: VideoFolderEntity;
  modeAddFolder: boolean = false;
  searchAllVideos: boolean = false;
  uploadToVimeo: boolean = false;

  searchAllTitle: string;
  searchAllSubjectId: number;
  searchAllTopicId: number;
  searchAllOfficialNum: number;
  searchAllOfficialYear: number;

  showVideos: boolean = false;
  showExams: boolean = false;
  showDocuments: boolean = false;

  noCourse: boolean = false;
  showCoursesOptions: boolean = false;
  coursesOptions: BasicDto[] = [];
  courseOptionSelected: CourseEntity;

  availableVideos: CourseStructureDto[];
  availableLibraries: CourseStructureDto[];
  availableExams: CourseStructureDto[];
  availableCourseStructure: CourseStructureDto[];

  typeOfRequirements = [
    {'id':'DATE', 'name':'Date'},
    {'id':'VIDEO', 'name':'Video'},
    {'id':'LIBRARY', 'name':'Library'},
    {'id':'EXAM', 'name':'Exam'},
    {'id':'VIDEO_FOLDER', 'name':'Video folder'},
    {'id':'LIBRARY_FOLDER', 'name':'Library folder'}
  ];

  uploadTitle: string;
  uploadWaitingtext: string = "Loading";

  options;
  currentPage=1;
  selectImage: boolean = false;

  headers: any = {
    columns: {
      corpId: {
        title: 'Corp ID',
        hide: true
      },
      title: {
        title: 'Title',
        valuePrepareFunction: (value, row) => {
          return this.checkParent(row);
        }
      },
      subjectId: {
        title: 'Subject',
        valuePrepareFunction: (value) => {
          return this.getSubject(value);
        }
      },
      videoFolderCourses: {
        title: 'Courses',
        valuePrepareFunction: (value) => {
          return this.getCourses(value) ;
        },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            let filtered = this.basicData.filter(x=> x.alias.toUpperCase().includes(search.toUpperCase())).map(c=> c.id);
            if(cell.find(z=>{return filtered.includes(z.courseId);})){
              return true;
            }
          }
          return false;
        }
      },
      videoFolderSubfolder: {
        title: 'Parent folders',
        type: 'html',
        valuePrepareFunction: (value) => {
          return this.getSubfolder(value, true) ;
        },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            let filtered = this.folders.filter(x=> x.description.toUpperCase().includes(search.toUpperCase())).map(c=> c.id);
            if(cell.find(z=>{return filtered.includes(z.videoFolderParentId);})){
              return true;
            }
          }
          return false;
        }
      },
      id: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.filterVideos(data);
            });
          }
        },
      },
    },
    actions: false,
  }


  headersVid: any = {
    columns: {
      corpId: {
        title: 'Corp ID',
        hide: true
      },
      id: {
        title: 'id',
        hide: true
      },
      title: {
        title: 'Title'
      },
      subjectId: {
        title: 'Subject',
        valuePrepareFunction: (value) => {
          return this.getSubject(value);
        }
      },
      topicId: {
        title: 'Topic',
        valuePrepareFunction: (value) => {
          return this.getTopic(value);
        }
      },
    },
    actions: false,
  }

  @ViewChild('accordionGroup', {static: true}) accordionGroup: IonAccordionGroup;

  constructor(private shared: Shared, private videoService: VideoService, private subjectService: SubjectService, private courseService: CourseService, private userService: UserService, private modalService: NgbModal, private calendarService: CalendarService) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    this.videoService.getVideoFolder(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.folders = x.reverse();

        this.userService.getBasicData().subscribe(p => {
          if (p != null) {
            this.basicData = p;
            this.foldersToDropdown = x.map(t=>new BasicDto(t.id,(t.title+' '+this.getCourses(t.videoFolderCourses)+' '+this.getSubfolder(t.videoFolderSubfolder, false)),(t.title+' '+this.getCourses(t.videoFolderCourses)+' '+this.getSubfolder(t.videoFolderSubfolder, false)),null));
            console.log(this.foldersToDropdown);
          }
        });
      }
    });
    (await this.subjectService.getSubjects()).subscribe(x => {
      if (x != null) {
        this.subjects = x;
      }
    });
    (await this.subjectService.getTopics()).subscribe(x => {
      if (x != null) {
        this.topics = x;
      }
    });
  }

  getIndexOf(list,id){
    let output = list.findIndex(x=> x.id == id);
    return output;
  }

  doSearch(){
    let obj = {
      'corpGroup': this.shared.CURRENT_CORP.corpGroup,
      'corpId': this.shared.CORP_ID,
      'title': this.searchAllTitle,
      'subjectId': this.searchAllSubjectId,
      'topicId': this.searchAllTopicId,
      'officialNum': this.searchAllOfficialNum,
      'officialYear': this.searchAllOfficialYear,
    }
    if(this.updateFromVimeo) {
      this.videoService.updateVideoByTitle(this.searchAllTitle).subscribe(async y => {
        if (y != null) {
          this.videoService.searchAllVideos(obj).subscribe(x => {
            if (x != null) {
              this.videos = x;
              const nativeEl = this.accordionGroup;
              nativeEl.value = 'videos';
              this.searchAllVideos = false;
            }
          });
        }
      });
    }else{
      this.videoService.searchAllVideos(obj).subscribe(x => {
        if (x != null) {
          this.videos = x;
          const nativeEl = this.accordionGroup;
          nativeEl.value = 'videos';
          this.searchAllVideos = false;
        }
      });
    }
  }

  selVideo(evt) {
    this.selectedVideo = evt.target.files[0];
  }

  async doUpload(){
    this.videoService.uploadVideoToVimeo(this.selectedVideo, this.uploadTitle).subscribe( async x => {
      if (x != null) {
        this.uploadToVimeo = false;
      }
    });
  }

  checkParent(row: VideoFolderEntity){
    let title = row.title;
    if(row.videoFolderSubfolder != null && row.videoFolderSubfolder.length>0){
      title = "-- "+title;
    }
    return title
  }

  convertTextToNumber(text){
    return Number(text);
  }

  newVideoFolderCourse(){
    let vfce = new VideoFoldeCourseEntity();
    vfce.videoFolderId = this.currentFolder.id;
    vfce.enabled = false;
    vfce.showRequirement = true;
    vfce.requirementMoreThan = true;
    if(this.currentFolder.videoFolderCourses==null){
      this.currentFolder.videoFolderCourses = [];
    }
    this.currentFolder.videoFolderCourses.push(vfce);
  }

  newVideoFolder(){
    this.modeAddFolder = true;
    this.currentFolder = new VideoFolderEntity();
    this.currentFolder.corpId = this.shared.CORP_ID;
    this.currentFolder.showInMagnament = true;
    this.currentFolder.folderParent = false;
    console.log(this.currentFolder);
  }

  newVideoFolderIntermediate(){
    let vfie = new VideoFolderIntermediateEntity();
    vfie.videoId = this.currentVideo.id;
    vfie.enabled = false;
    vfie.showRequirement = true;
    vfie.requirementMoreThan = true;
    if(this.currentVideo.folders==null){
      this.currentVideo.folders = [];
    }
    this.currentVideo.folders.push(vfie);

    this.videoService.getVideoFolder(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.folders = x;
        this.foldersToDropdown = x.map(t=>new BasicDto(t.id,(t.title+' '+this.getCourses(t.videoFolderCourses)+' '+this.getSubfolder(t.videoFolderSubfolder, false)),(t.title+' '+this.getCourses(t.videoFolderCourses)+' '+this.getSubfolder(t.videoFolderSubfolder, false)),null));
      }
    });
  }

  newVideoFolderSubfolder(){
    let vfie = new VideoFolderSubfolderEntity();
    vfie.videoFolderChildId = this.currentFolder.id;
    vfie.corpId = this.shared.CORP_ID;
    vfie.enabled = false;
    if(this.currentFolder.videoFolderSubfolder==null){
      this.currentFolder.videoFolderSubfolder = [];
    }
    this.currentFolder.videoFolderSubfolder.push(vfie);

    this.videoService.getVideoFolder(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.folders = x;
        this.foldersToDropdown = x.map(t=>new BasicDto(t.id,(t.title+' '+this.getCourses(t.videoFolderCourses)+' '+this.getSubfolder(t.videoFolderSubfolder, false)),(t.title+' '+this.getCourses(t.videoFolderCourses)+' '+this.getSubfolder(t.videoFolderSubfolder, false)),null));
      }
    });
  }

  deleteVideoFolder(){
  }

  uploadTranscriptionDocument(){
    this.videoService.uploadTranscriptionDocument(this.selectedFile2).subscribe(x=>{
      if(x!=null){
        this.currentVideo.transcriptionFormatted = x.reference;
      }
    });
  }

  getSubject(id) {
    if (id != null) {
      let sub = this.subjects.find(x => x.id === id);
      if(sub != null) {
        return sub.name;
      }
    }
    return "";
  }

  getTopic(id) {
    if (id != null) {
      let top = this.topics.find(x => x.id === id);
      if(top != null) {
        return top.name;
      }
    }
    return "";
  }

  getCourse(id) {
    if (id != null) {
      let cou = this.basicData.find(x => x.id === id);
      if(cou != null) {
        return cou.name;
      }
    }
    return "";
  }

  getCourseComplete(id) {
    if (id != null) {
      let cou = this.basicData.find(x => x.id === id);
      if(cou != null) {
        return cou;
      }
    }
    return null;
  }

  getCourses(vfce: VideoFoldeCourseEntity[]) {
    let output = "";
    vfce.forEach(x=>{
      var c = this.basicData.find(y1 => y1.id === x.courseId);
      if(c != null) {
        if(c.list != null && x.courseTypeId != null){
          var ct = c.list.find(y2 => y2.id === x.courseTypeId);
          if(ct != null){
            if(ct.list != null && x.modalityId != null){
              var m = ct.list.find(y3 => y3.id === x.modalityId);
              if(m != null){
                if(m.list != null && x.siteId != null){
                  var s = m.list.find(y4 => y4.id === x.siteId);
                  if(s != null){
                    if(s.list != null && x.siteId != null){
                      var t = s.list.find(y5 => y5.id === x.turnId);
                      if(t != null){
                        output = output + "[" + t.alias + "]";
                      }
                    }else{
                      output = output + "[" + s.alias + "]";
                    }
                  }
                }else{
                  output = output + "[" + m.alias + "]";
                }
              }
            }else{
              output = output + "[" + ct.alias + "]";
            }
          }
        }else {
          output = output + "[" + c.alias + "]";
        }
      }
    });
    return output;
  }

  getSubfolder(vfse: VideoFolderSubfolderEntity[], isTable) {
    //let output = "<ion-icon id='hover-trigger' name='information-circle-outline' ><ion-popover trigger='hover-trigger' triggerAction='hover'><ng-template><ion-content class='ion-padding'>";
    let output = "";
    if(vfse != null) {
      vfse.forEach(x => {
        var c = this.folders.find(y => y.id === x.videoFolderParentId);
        if (c != null) {
          if(isTable) {
            output = output + '(' + c.description + this.getCourses(c.videoFolderCourses) + ')<br />';
          }else{
            output = output + '(' + c.description + this.getCourses(c.videoFolderCourses) + ')';
          }
        }
      });
    }
    //return output+"</ion-content></ng-template></ion-popover>";
    return output;
  }

  getFolder(id) {
    if (id != null) {
      return this.folders.find(x => x.id === id);
    }
    return null;
  }

  subjectChanged(evt) {
    this.subjectService.getTopicsBySubject(evt.id).subscribe(x => {
      if (x != null) {
        this.topics = x;
      }
    });
  }

  subjectChangedVid(evt) {
    this.currentVideo.subject = evt;
    this.subjectService.getTopicsBySubject(evt.id).subscribe(x => {
      if (x != null) {
        this.topics = x;
      }
    });
  }

  deleteCourse(vfCourse){
    this.currentFolder.videoFolderCourses = this.currentFolder.videoFolderCourses.filter(x=> x!=vfCourse);
  }

  deleteSubfolder(vfSubfolder){
    this.currentFolder.videoFolderSubfolder = this.currentFolder.videoFolderSubfolder.filter(x=> x!=vfSubfolder);
  }

  deleteIntermediate(vfIntermediate){
    this.currentVideo.folders = this.currentVideo.folders.filter(x=> x!=vfIntermediate);
  }

  topicChangedVid(evt) {
    this.currentVideo.topic = evt;
  }

  rowSelectedEmitter(row: VideoFolderEntity) {
    this.currentFolder = row;

    if(this.currentFolder.videoFolderCourses.length>0) {
      this.getCourseStructure(this.currentFolder.videoFolderCourses[0]);
    }
  }

  async rowSelectedEmitterVid(row: VideoEntity) {
    this.showFolders = false;
    await this.delay(300);
    this.currentVideo = row;
    if(this.currentVideo.folders.length>0) {
      this.getCourseStructure2(this.currentVideo.folders[0].typeOfRequirement);
    }
    await this.delay(100);
    this.showFolders = true;
  }

  async saveFolder() {
    this.showFolderTable = false;
    this.videoService.saveVideoFolder(this.currentFolder).subscribe(x=>{
      if(x!=null){
        if(this.modeAddFolder){
          this.folders.push(x);
          this.folders = this.folders.filter(x => {return x});
          this.modeAddFolder = false;
        }
        this.currentFolder = null;
      }
    });
    this.folders[this.folders.findIndex(x=> x.id == this.currentFolder.id)] = this.currentFolder;
    await this.delay(200);
    this.showFolderTable = true;
  }

  async saveVideo() {
    this.showVideoTable = false;
    console.log(this.currentVideo);
    this.videoService.saveVideo(this.currentVideo).subscribe(x=>{
      if(x!=null){
        this.videos[this.videos.findIndex(x=> x.id == this.currentVideo.id)] = this.currentVideo;
        this.waitForMe()
        this.showVideoTable = true;
        this.currentVideo = null;
      }
    });
  }

  getCourseStructure(data){
    if(data != null && data.type != 'DATE' && data.courseId != null) {
      this.availableCourseStructure = null;
      this.calendarService.getCourseStructure(data.courseId).subscribe(x => {
        if (x != null) {
          this.availableCourseStructure = x;
          this.availableExams = x.filter(t => t.type == 'EXAM');
          this.availableVideos = x.filter(t => t.type == 'VIDEO_FOLDER');
          this.availableLibraries = x.filter(t => t.type == 'LIBRARY_FOLDER');
        }
      });
    }
  }

  getCourseStructure2(type){
    if(this.currentFolder == null || this.currentFolder == undefined){
      this.noCourse = true;
    }else{
      this.noCourse = false;
      if(this.currentFolder.videoFolderCourses.length>1) {
        this.showCoursesOptions = true;
        this.coursesOptions = this.currentFolder.videoFolderCourses.map(t => this.getCourseComplete(t.courseId));
        if(this.courseOptionSelected != null && this.courseOptionSelected != undefined) {
          this.getCourseStructure(this.courseOptionSelected);
        }
      }else{
        this.showCoursesOptions = false;
        this.getCourseStructure(this.currentFolder.videoFolderCourses[0]);
      }
    }
  }

  showData(type, template){
    if(type==null){
      return false;
    }else if(type=='EXAM'){
      this.showExams = true;
    }else if(type == 'VIDEO' || type == 'VIDEO_FOLDER'){
      this.showVideos = true;
    }else if(type == 'LIBRARY' || type == 'LIBRARY_FOLDER'){
      this.showDocuments = true;
    }

    this.modalService.open(template, { centered: true });
  }

  getExam(reference){
    return this.availableExams.find(x=>x.reference === reference);
  }

  getVideo(reference){
    let fullList = [];

    this.availableVideos.forEach(x1=>{
      fullList.push(x1);
      if(x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if(x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return fullList.find(x=> x.reference == reference);
  }

  getDocument(reference){
    let fullList = [];

    this.availableLibraries.forEach(x1=>{
      fullList.push(x1);
      if(x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if(x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return fullList.find(x=> x.reference == reference);
  }


  async waitForMe(){
    await this.delay(200);
  }

  selFile(evt){
    this.selectedFile = evt.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = reader.result.toString().trim();
      this.currentVideo.transcription = text;
    }

    reader.readAsText(this.selectedFile);
  }

  selFile2(evt){
    this.selectedFile2 = evt.target.files[0];
  }

  viewVideo(template) {
    this.options = {
      playbackRates: [0.5, 1, 1.5, 2],
      muted: true,
      controls: true,
      autoplay: true,
      sources: [{
        src: this.currentVideo.fileUrl,
        type: 'application/x-mpegURL'
      }],
    }

    this.modalService.open(template, {centered: true});
  }

  filterVideos(data) {
    this.videoService.getVideos(data).subscribe(x => {
      if (x != null) {
        this.videos = x.reverse();
        const nativeEl = this.accordionGroup;
        nativeEl.value = 'videos';
      }
    });
  }

  getAllVideos(){
    this.videoService.getAllVideos(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.videos = x;
        const nativeEl = this.accordionGroup;
        nativeEl.value = 'videos';
      }
    });
  }

  convertFileToBase64Folder(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentFolder.front = reader.result.toString();
    };
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}


@Component({
  selector: 'app-vjs-player',
  template: `
    <video #target class="video-js" controls muted playsinline preload="none"></video>
  `,
  styleUrls: ['video.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
  };
  player: videojs.Player;

  constructor(private elementRef: ElementRef, private shared: Shared) {
  }

  ngOnInit() {
    this.shared.videoPlayer = null;
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
    });
    this.shared.videoPlayer = this.player;
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
