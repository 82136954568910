import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoursesData } from './coursesData';
import {CoursesDataRoutingModule} from "./coursesData-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {CourseService} from "../../../service/CourseService";
import {Courses} from "./courses/courses";
import {CoursesType} from "./coursesType/coursesType";
import {Modality} from "./modality/modality";
import {Site} from "./site/site";
import {Turn} from "./turn/turn";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {NgToggleModule} from "ng-toggle-button";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    CoursesDataRoutingModule,
    NgxDatatableModule,
    TableModule,
    DlDateTimePickerModule,
    NgSelectModule,
    AngularEditorModule,
    NgToggleModule,
  ],
  providers: [CourseService],
  exports: [
    CoursesData
  ],
  declarations: [CoursesData, Courses, CoursesType, Modality, Site, Turn]
})
export class CoursesDataModule {}
