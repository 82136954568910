import {ExamSettingEntity} from "./ExamSettingEntity";

export class ExamSettingCourseEntity {
  corpId: number;
  id: string;
  examSettingId: number;
  name: string;
  courseId: number;
  courseTypeId: number;
  modalityId: number;
  siteId: number;
  turnId: number;
  examOrder: number;
  startDate: string;
  endDate: string;
  typeOfRequirement: string;
  requirementReference: string;
  requirementPercentaje: number;
  requirementMoreThan: boolean;
  showRequirement: boolean;
  enabled: boolean;
}
