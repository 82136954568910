export class WorkgroupEntity {
  corpId: number;
  id: number;
  alias: string;
  name: string;
  courseId: number;
  startDate: string;
  endDate: string;
  showInCampus: boolean;
  showInMagnament: boolean;
}
