import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {CourseService} from "../../../service/CourseService";
import {Shared} from "../../shared/shared";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {TutorEntity} from "../../../model/TutorEntity";
import {HospitalEntity} from "../../../model/HospitalEntity";
import {SpecialityEntity} from "../../../model/SpecialityEntity";
import {PlaceService} from "../../../service/PlaceService";

@Component({
  selector: 'app-hospitals',
  templateUrl: 'hospitals.html',
  styleUrls: ['hospitals.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Hospitals {

  hospitals: HospitalEntity[];

  showTable = true;
  modeAdd = false;

  newHospitalEntity: HospitalEntity;

  headers: any = {
    columns: {
      corpId: {title: 'corpId', hide: true, filter: false},
      id: {title: 'id', hide: true, filter: false},
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.alias = data.text;
              this.saveHospital(hospital);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.name = data.text;
              this.saveHospital(hospital);
            });
          }
        },
      },
      address: {title: 'Address', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.address = data.text;
              this.saveHospital(hospital);
            });
          }
        },
      },
      province: {title: 'Province', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.province = data.text;
              this.saveHospital(hospital);
            });
          }
        },
      },
      town: {title: 'Town', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.town = data.text;
              this.saveHospital(hospital);
            });
          }
        },
      },
      cp: {title: 'CP', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.cp = data.text;
              this.saveHospital(hospital);
            });
          }
        },
      },
      country: {title: 'Country', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.country = data.text;
              this.saveHospital(hospital);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.showInCampus = data.bool;
              this.saveHospital(hospital);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let hospital = this.hospitals.find(x => x.id == data.row.id)
              hospital.showInMagnament = data.bool;
              this.saveHospital(hospital);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private placeService: PlaceService, private shared: Shared) {
  }

  ngOnInit() {
    this.placeService.getHospitals(this.shared.CORP_ID).subscribe( x=> {
      if(x!=null){
        this.hospitals = x;
      }
    });
  }

  async saveHospital(hospital: HospitalEntity) {
    this.placeService.saveHospital(hospital).subscribe( t =>{
        if(t!=null) {
          let index = this.hospitals.findIndex(x=> x.id == t.id);
          if(index != -1) {
            this.hospitals[index] = t;
          }else{
            this.hospitals.push(t);
          }
          this.hospitals = this.hospitals.filter(x => {return x});
        }
    });
  }

  async saveNewHospital() {
    this.saveHospital(this.newHospitalEntity);
    this.modeAdd = false;
  }

  newHospital(){
    this.modeAdd = true;
    this.newHospitalEntity = new HospitalEntity();
    this.newHospitalEntity.corpId = this.shared.CORP_ID;
    this.newHospitalEntity.showInMagnament = true;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
