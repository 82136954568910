import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {StudentEntity} from "../model/StudentEntity";
import {BasicDto} from "../dto/BasicDto";
import {CoursePriceDto} from "../dto/CoursePriceDto";
import {catchError} from "rxjs/operators";
import {Auth} from "../model/Auth";

@Injectable()
export class LoginService extends ParentService{

  public login(username: string, password: string) {
    const loginData = { username, password };

    return this.http.post<Auth>(this.shared.MAGNAMENT_URL+'/login', loginData, this.shared.headers()).pipe(catchError(this.shared.handleErrorLogin));
  }

}
