import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {TopicEntity} from "../../../../model/TopicEntity";
import {SubjectEntity} from "../../../../model/SubjectEntity";
import {SubjectService} from "../../../../service/SubjectService";
import {BankItemEntity} from "../../../../model/BankItemEntity";
import {BankPackageEntity} from "../../../../model/BankPackageEntity";
import {BankService} from "../../../../service/BankService";

@Component({
  selector: 'app-bank-item',
  templateUrl: 'bankItem.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class BankItem {

  @Input() bankItems: BankItemEntity[];
  @Input() bankPackages: BankPackageEntity[];
  @Input() selectedBankPackageId: number;

  @Output() filter = new EventEmitter<number>();

  newEntity: BankItemEntity;
  modeAdd = false;
  currencies = [
    {id: 'EUR', name: 'EUR'},
    {id: 'USD', name: 'USD'},
    {id: 'MXN', name: 'MXN'},
    {id: 'ARS', name: 'ARS'},
    {id: 'CRC', name: 'CRC'},
  ];
  types = [
    {id: 'PAYPAL', name: 'Paypal'},
    {id: 'REDSYS', name: 'Redsys'},
    {id: 'STRIPE', name: 'Stripe'},
    {id: 'BANKTRANSFER', name: 'Bank transfer'},
    {id: 'BIZUM', name: 'Bizum'},
  ];

  headers: any = {
    columns: {
      corpId: {title: 'Statement', hide: true, filter: false},
      id: {title: 'Id', hide: true, filter: false},
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.name = data.text;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      bankPackageId: {title: 'Bank Package', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.bankPackages}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.bankPackageId = data;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      type: {title: 'Type', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.types}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.type = data.text;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      primaryToken: {title: 'Primary Token', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.primaryToken = data.text;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      secondaryToken: {title: 'Secondary Token', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.secondaryToken = data.text;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      tertiaryToken: {title: 'Tertiary Token', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.tertiaryToken = data.text;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      enabled: {title: 'Enabled', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.enabled = data.bool;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      currency: {title: 'Currency', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.currencies}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.currency = data.id;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let bankItem = this.bankItems.find(x => x.id == data.row.id)
              bankItem.showInMagnament = data.bool;
              this.saveBankItem(bankItem, false);
            });
          }
        },
      }
    },
    actions: false,
  }

  constructor(private bankService: BankService, private shared: Shared) {
  }

  ngOnInit() {
  }

  nextStep(id: number){
    this.filter.emit(id);
  }

  saveBankItem(bankItem: BankItemEntity, bool) {
    this.bankService.saveItem(bankItem).subscribe( t =>{
      if(t!=null) {
        if(bool){this.bankItems.push(t);}
        this.bankItems = this.bankItems.filter(x => {return x});
      }
    });
  }

  async saveNewBankItem() {

    this.saveBankItem(this.newEntity, true);
    this.modeAdd = false;
  }

  newBankItem(){
    const selectedBankPackage = this.bankPackages.find(bankPackage => bankPackage.id === this.selectedBankPackageId);
    if (selectedBankPackage) {
      this.modeAdd = true;
      this.newEntity = new BankItemEntity();
      this.newEntity.corpId = this.shared.CORP_ID;
      this.newEntity.currency = selectedBankPackage.currency;
      this.newEntity.bankPackageId = this.selectedBankPackageId
      this.newEntity.enabled = false;
      this.newEntity.showInMagnament = true;
    }
  }
}
