import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";
import {LibraryEntity} from "./LibraryEntity";
import {VideoFoldeCourseEntity} from "./VideoFolderCourseEntity";
import {LibraryFoldeCourseEntity} from "./LibraryFolderCourseEntity";
import {VideoFolderSubfolderEntity} from "./VideoFolderSubfolderEntity";
import {LibraryFolderSubfolderEntity} from "./LibraryFolderSubfolderEntity";

export class LibraryFolderEntity {
  corpId: number;
  id: number;
  title: string;
  description: string;
  front: string;
  frontUrl: string;
  subjectId: number;
  topicId: number;
  folderParentId: number;
  folderParent: boolean;
  showInCampus: boolean;
  showInMagnament: boolean;
  list: LibraryEntity[];
  libraryFolderCourses: LibraryFoldeCourseEntity[];
  libraryFolderSubfolder: LibraryFolderSubfolderEntity[];
}
