import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {CalendarService} from "../../../../service/CalendarService";
import {PackageEntity} from "../../../../model/CalendarPackage";
import {SubjectEntity} from "../../../../model/SubjectEntity";
import {TopicEntity} from "../../../../model/TopicEntity";
import {StepEntity} from "../../../../model/StepEntity";
import {PackageItemEntity} from "../../../../model/CalendarPackageItem";
import {TypeOfEventEntity} from "../../../../model/TypeOfEventEntity";
import {StepContentEntity} from "../../../../model/StepContentEntity";
import {VideoService} from "../../../../service/VideoService";
import {VideoFolderEntity} from "../../../../model/VideoFolderEntity";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VideoEntity} from "../../../../model/VideoEntity";
import {LibraryFolderEntity} from "../../../../model/LibraryFolderEntity";
import {LibraryService} from "../../../../service/LibraryService";
import {ExamSettingEntity} from "../../../../model/ExamSettingEntity";
import {ExamService} from "../../../../service/ExamService";
import * as moment from "moment";
import {AlertController} from "@ionic/angular";

@Component({
  selector: 'app-packages',
  templateUrl: 'package.html',
  styleUrls: ['package.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Package {

  @Input() packages: PackageEntity[];

  @Input() subjects: SubjectEntity[];
  @Input() topics: TopicEntity[];
  @Input() steps: StepEntity[];
  @Input() stepContents: StepContentEntity[];
  @Input() typeOfEvents: TypeOfEventEntity[];

  @Input() stepId: number;

  availableVideos: VideoFolderEntity[];
  availableLibraries: LibraryFolderEntity[];
  availableExams: ExamSettingEntity[];

  packageItems: PackageItemEntity[];
  currentItem: PackageItemEntity;

  showVideos: boolean = false;
  showExams: boolean = false;
  showDocuments: boolean = false;

  showStartDate: boolean = false;
  showEndDate: boolean = false;

  startDateCurrentSelected: string = "";
  endDateCurrentSelected: string = "";

  selection: number;

  constructor(private calendarService: CalendarService,private videoService: VideoService,private libraryService: LibraryService,  public alertController: AlertController, private examService: ExamService, private modalService: NgbModal, private shared: Shared) {
  }

  ngOnInit() {
    let currentStep = this.steps.find(t=>t.id == this.stepId);
    let obj = {
     'courseId': currentStep.courseId,
     'courseTypeId': currentStep.courseTypeId,
     'modalityId': currentStep.modalityId,
     'siteId': currentStep.siteId,
     'turnId': currentStep.turnId
    }
    this.videoService.getVideoFolders(obj).subscribe(x=>{
      if(x!=null){
        this.availableVideos = x;
      }
    });

    this.libraryService.getLibraryFolders(obj).subscribe(x=>{
      if(x!=null){
        this.availableLibraries = x;
      }
    });

    this.examService.getExamsSettings(obj).subscribe(x=>{
      if(x!=null){
        this.availableExams = x;
      }
    });
  }

  getSubject( pack: any){
    return this.subjects.find(x=>x.id === pack.subjectId);
  }

  getTopic( pack: any){
    return this.topics.find(x=>x.id === pack.topicId);
  }

  getExam( pack: any){
    return this.availableExams.find(x=>x.reference === pack.auxReference);
  }

  getVideo( pack: any){
    let fullList = [];
    this.availableVideos.forEach(x=>{
      x.list.forEach(t=>{
        fullList.push(t);
      });
    });
    return fullList.find(x=> x.reference == pack.auxReference);
  }

  getDocument( pack: any){
    let fullList = [];
    this.availableLibraries.forEach(x=>{
      x.list.forEach(t=>{
        fullList.push(t);
      });
    });
    return fullList.find(x=> x.reference == pack.auxReference);
  }

  getType( pack: any){
    return this.typeOfEvents.find(x=>x.id === pack.typeOfEventId);
  }

  setTimeSubject(item){
    let pack = this.packages.find(x=> x.id == item.packageId);
    let sce = this.stepContents.find(t=> t.subjectId == item.subjectId && t.calendarStepId == pack.stepId);
    if(sce != null) {
      item.maxTime = sce.maxTime;
      item.minTime = sce.minTime;
    }
  }

  setTimeTopic(item){
    let pack = this.packages.find(x=> x.id == item.packageId);
    let sce = this.stepContents.find(t=> t.topicId == item.topicId && t.calendarStepId == pack.stepId)
    if(sce != null) {
      item.maxTime = sce.maxTime;
      item.minTime = sce.minTime;
    }
  }

  checkType(typeId, isSubject){
    if(typeId==null){
      return false;
    }
    let type = this.typeOfEvents.find(t=> t.id == typeId);
    if(isSubject){
      if(['EX','CL','LC','EC','TU'].includes(type.alias)){
        return true;
      }else{
        return false;
      }
    }else{
      if(['ST','RV','DO'].includes(type.alias)){
        return true;
      }else{
        return false;
      }
    }
  }

  showData(item: PackageItemEntity, template){
    if(item.typeOfEventId==null){
      return false;
    }
    this.currentItem = item;
    this.showVideos = this.checkData(item,2);
    this.showExams = this.checkData(item,1);
    this.showDocuments = this.checkData(item,3);

    this.modalService.open(template, { centered: true });
  }

  showDate(item: PackageItemEntity, template){
    if(item.typeOfEventId==null){
      return false;
    }
    this.currentItem = item;
    this.modalService.open(template, { centered: true });
  }

  checkData(item: PackageItemEntity, myType){
    let type = this.typeOfEvents.find(t=> t.id == item.typeOfEventId);
    if(['EX','EC'].includes(type.alias) && myType == 1){
      return true;
    }else if(['CL','LC','TU','LT','VI'].includes(type.alias) && myType == 2){
      return true;
    }else if(['ST','RV','DO'].includes(type.alias) && myType == 3){
      return true;
    }
    return false;
  }

  newPackageItem(packageId){
    let aux = this.packages.find(t=>t.id == packageId);
    let newEntity = new PackageItemEntity();
    newEntity.showInMagnament = true;
    newEntity.corpId = this.shared.CORP_ID;
    newEntity.packageId = packageId;
    newEntity.order = (aux.items.length + 1);
    newEntity.modeEdit = true;

    //aux.items = aux.items.filter(h=>h.modeEdit != true);

    aux.items.push(newEntity);
  }

  newPackage(){
    let newEntity = new PackageEntity();
    newEntity.showInMagnament = true;
    newEntity.corpId = this.shared.CORP_ID;
    newEntity.orderNum = (this.packages.length + 1);
    newEntity.modeEdit = true;
    newEntity.stepId = this.stepId;

    this.packages.push(newEntity);
  }

  savePackageItem(item: PackageItemEntity){
    item.startDate = this.startDateCurrentSelected;
    item.endDate = this.endDateCurrentSelected;
    this.calendarService.savePackageItem(item).subscribe(x=>{
      if(x!=null){
        item.modeEdit = false;
        this.recalculatePackageTimes(item.packageId);
        this.packages.forEach(h=>{
          h.items.find(y=>y.id == -1).id = x.id;
        })
      }
    });
    return -1;
  }

  changeModeItem(myItem){
    this.packages.forEach(x=>{
      x.items.forEach(y=>{
        y.modeEdit = false;
      })
    })

    if(myItem.startDate!=null) {
      this.startDateCurrentSelected = myItem.startDate;
    }else{
      this.startDateCurrentSelected = null;
    }
    if(myItem.startDate!=null) {
      this.endDateCurrentSelected = myItem.endDate;
    }else{
      this.endDateCurrentSelected = null;
    }
    return true;
  }

  deleteItem(myItem){
    this.calendarService.deletePackageItem(myItem.id).subscribe();
    this.packages.forEach(x=>{
      x.items = x.items.filter(y=> y.id != myItem.id)
    })
  }

  async presentAlert(myItem) {
    console.log(myItem);
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: "Delete package item",
      subHeader: '',
      message: "Are you sure delete this package item?",
      buttons: [{
        text: "Delete",
        handler: () => {
          this.deleteItem(myItem);
        }
      }, {
        text: "Cancel"
      }]
    });

    await alert.present();

    const {role} = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  savePackage(item: PackageEntity){
    this.calendarService.savePackage(item).subscribe(x=>{
      if(x!=null){
        item.modeEdit = false;
        this.calendarService.getPackages(this.stepId).subscribe(t=>{
          if(t!=null) {
            this.packages = t;
          }
        });
      }
    });
  }

  saveStartDate(event) {
    return moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDate(event) {
    return moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  recalculatePackageTimes(packId){
    let currentPackage = this.packages.find(p=>p.id == packId);
    let packagesItemFiltered = currentPackage.items.filter(t=>t.packageId = packId);
    currentPackage.maxTime = 0;
    currentPackage.minTime = 0;
    packagesItemFiltered.map(p=>{currentPackage.maxTime = Number(currentPackage.maxTime) + Number(p.maxTime)});
    packagesItemFiltered.map(p=>{currentPackage.minTime = Number(currentPackage.minTime) + Number(p.minTime)});
    currentPackage.maxTime = Number(currentPackage.maxTime);
    currentPackage.minTime = Number(currentPackage.minTime);
    this.calendarService.savePackage(currentPackage).subscribe()
  }

  reorderPackages(ev, isPackage) {
    if(isPackage && ev.srcElement.id =="package") {
      const itemMove = this.packages.splice(ev.detail.from, 1)[0];
      this.packages.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();

      this.packages = this.packages.map((element, index) => {
        element.orderNum = index + 1;
        return element;
      })

      this.calendarService.savePackages(this.packages).subscribe();
    }
  }

  reorderItems(ev, items, isPackage) {
    if(!isPackage && ev.srcElement.id == "item") {
      const itemMove = items.splice(ev.detail.from, 1)[0];
      items.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();

      items = items.map((element, index) => {
        element.order = index + 1;
        return element;
      })

      this.calendarService.savePackageItems(items).subscribe(t => {
        if (t != null) {
          this.packages = this.packages.filter(f => f != undefined);
        }
      });
    }
  }

}
