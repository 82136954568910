
export class EmailDto {
  corpId: number;
  studentId: number[];
  studentEmail: string[];
  from: string;
  subject: string;
  message: string;
  attch: any[];

}
