import { Component } from '@angular/core';
import {Shared} from "./shared/shared";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private shared: Shared) {}
}
