import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Achievements } from './achievements';
import {AchievementsRoutingModule} from "./achievements-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {AchievementService} from "../../../service/AchievementService";


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    AchievementsRoutingModule,
    NgxDatatableModule,
    TableModule
  ],
  providers: [AchievementService],
  exports: [
    Achievements
  ],
  declarations: [Achievements]
})
export class AchievementsModule {}
