import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {CourseService} from "../../../../service/CourseService";
import {CourseEntity} from "../../../../model/CourseEntity";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {CalendarComponent} from "../../generic/table/calendarComponent/calendarComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {CourseTypeEntity} from "../../../../model/CourseTypeEntity";
import * as moment from "moment";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {CourseFrontPageEntity} from "../../../../model/CourseFrontPageEntity";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {BasicDto} from "../../../../dto/BasicDto";
import {ContextService} from "../../../../service/ContextService";
import {Angular5Csv} from "angular5-csv/dist/Angular5-csv";
import {CourseFriendCorpEntity} from "../../../../model/CourseFriendCorpEntity";
import {CommonCourseDto} from "../../../../dto/CommonCourseDto";
import {DropdownMComponent} from "../../generic/table/dropdownMComponent/dropdownMComponent";

@Component({
  selector: 'app-courses',
  templateUrl: 'courses.html',
  styleUrls: ['courses.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Courses {

  @Input() courses: CourseEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: CourseEntity;
  modeAdd = false;

  roles: BasicDto[];
  forums: BasicDto[];
  categories: BasicDto[];
  questionGroups: BasicDto[];
  flashcardGroups: BasicDto[];

  showStartDate = false;
  showEndDate = false;
  showOpenSignupDate = false;
  showCloseSignupDate = false;

  courseStatisticsLoading = false;

  currentCourse: CourseEntity;
  currentCourseFrontPage: CourseFrontPageEntity;

  selectedFriendCorps: number[] = null;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };


  headers: any = {
    columns: {
      alias: {
        title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.alias = data.text;
              this.saveCourse(course, false);
            });
          }
        },
      },
      name: {
        title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.name = data.text;
              this.saveCourse(course, false);
            });
          }
        },
      },
      startDate: {
        title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.startDate = data.date;
              this.saveCourse(course, false);
            });
          }
        },
      },
      endDate: {
        title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.endDate = data.date;
              this.saveCourse(course, false);
            });
          }
        },
      },
      openSignupDate: {
        title: 'Open signup date', hide: true, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.openSignupDate = data.date;
              this.saveCourse(course, false);
            });
          }
        },
      },
      closeSignupDate: {
        title: 'Close signup date', hide: true, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.closeSignupDate = data.date;
              this.saveCourse(course, false);
            });
          }
        },
      },
      defaultRole: {
        title: 'Default role', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, options: this.roles}
        },
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              console.log("Holaaaaaaaaaaaaaaaaa");
              console.log(data);
              console.log(this.courses);
              let course = this.courses.find(x => x.id == data.row.id)
              console.log(course);
              course.defaultRole = data.id;
              this.saveCourse(course, false);
            });
          }
        },
      },
      categoryId: {
        title: 'Category', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, options: this.categories}
        },
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.categoryId = data.id;
              this.saveCourse(course, false);
            });
          }
        },
      },
      forumId: {
        title: 'Forum', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, options: this.forums}
        },
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.forumId = data.id;
              this.saveCourse(course, false);
            });
          }
        },
      },
      contentNotification: {
        title: 'Content notif.', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.contentNotification = data.bool;
              this.saveCourse(course, false);
            });
          }
        },
      },
      showInRegister: {
        title: 'Show in Register', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.showInRegister = data.bool;
              this.saveCourse(course, false);
            });
          }
        },
      },
      registerOrder: {
        title: 'Reg. Order', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.registerOrder = data.text;
              this.saveCourse(course, false);
            });
          }
        },
      },
      tagCloudQuestionGroups: {
        title: 'Tag Cloud', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {
          let value = [];
          if(cell != null) {
            console.log(cell.split(','));
            value = cell.split(',');
          }
          return {cell: value, options: this.questionGroups}
        },
        renderComponent: DropdownMComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.tagCloudQuestionGroups = data.id.join(',');
              this.saveCourse(course, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.showInCampus = data.bool;
              this.saveCourse(course, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let course = this.courses.find(x => x.id == data.row.id)
              course.showInMagnament = data.bool;
              this.saveCourse(course, false);
            });
          }
        },
      },
      id: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
      corpId: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'brush-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.customizeCourse(data);
            });
          }
        },
      },
      row: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'code-download-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.courseStatistics(data);
            });
          }
        },
      },
      row2: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'copy-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.cloneCourse(data);
            });
          }
        },
      },
      row3: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'settings-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.getCourseFriendCorp(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private courseService: CourseService, private contextService: ContextService, public shared: Shared) {
  }

  ngOnInit() {
    this.courseService.getCommonCourses().subscribe((t: CommonCourseDto) => {
      if (t != null) {
        this.roles = t.roles;
        this.categories = t.categories;
        this.forums = t.forums;
        this.questionGroups = t.questionGroups;
        this.flashcardGroups = t.flashcardGroups;
      }
    });
  }

  customizeCourse(id: number) {
    this.currentCourse = this.courses.find(x => x.id == id);
    this.currentCourseFrontPage = new CourseFrontPageEntity();
    this.currentCourseFrontPage.corpId = this.shared.CORP_ID;
    this.currentCourseFrontPage.courseId = id;
    this.currentCourseFrontPage.html = "";
    this.currentCourseFrontPage.backgroundImage = "";
    this.currentCourseFrontPage.enabled = true;
    this.courseService.getCourseFrontPageByCourse(this.currentCourse.id).subscribe(t => {
      if (t != null) {
        this.currentCourseFrontPage = t;
      }
    });
  }

  cloneCourse(id) {
    let bool = confirm("¿Estás seguro que deseas duplicar el curso?");

    if(bool) {
      this.courseService.cloneCourse(id).subscribe((w: CourseEntity[]) => {
        if (w != null) {
          this.courses = w;
        }
      });
    }
  }

  getCourseFriendCorp(courseId) {
    this.currentCourse = this.courses.find(p=> p.id == courseId);
    this.courseService.getCourseFriendCorps(this.currentCourse.id).subscribe(x => {
      if (x != null) {
        this.selectedFriendCorps = x.map(z=> {return z.friendCorpId});
      }
    });
  }

  saveCourseFriendCorp() {
    this.courseService.saveCourseFriendCorps(this.currentCourse.id, this.selectedFriendCorps).subscribe(x => {
      if (x != null) {
        this.selectedFriendCorps = null;
        this.currentCourse = null;
      }
    });
  }

  courseStatistics(id: number) {
    this.courseStatisticsLoading = true;
    this.courseService.getCourseStatistics(id).subscribe(w => {
      let courseStatisticsHeader: string[] = ['Fullname', 'Email'];
      let courseStatisticsHeaderReferences: string[] = []

      let lastColumn = null;
      w.forEach(x => {
        if (x.type.includes('_TITLE')) {
          if (lastColumn != null && lastColumn != x.type) {
            lastColumn = x.type;
            courseStatisticsHeader.push("MEDIA_COLUMN");
            courseStatisticsHeaderReferences.push("MEDIA_COLUMN");
          } else if (lastColumn == null) {
            lastColumn = x.type;
          }
          courseStatisticsHeader.push(x.title);
          courseStatisticsHeaderReferences.push(x.reference);
          if (x.type.includes('EXAM_TITLE')) {
            courseStatisticsHeader.push(x.title + "% result");
            courseStatisticsHeaderReferences.push(x.reference);
          }
        }
      });

      let allCourseStatistics: string[][] = [];
      let courseStatistics: string[];
      let currentEmail = "";
      let lastIndex = 0;
      let flag2 = true;
      w.forEach((x, index) => {
        if (!x.type.includes('_TITLE')) {
          if (x.email != currentEmail) {
            if (courseStatistics != null) {
              allCourseStatistics.push(courseStatistics);
            }
            courseStatistics = [];
            courseStatistics.push(x.fullname);
            courseStatistics.push(x.email);
            currentEmail = x.email;
            lastIndex = 0;
            flag2 = true;
          }

          let firstExam = false;
          let flag = true;
          courseStatisticsHeaderReferences.forEach((p, index2) => {
            if (flag) {
              if (p === x.reference) {
                if (x.type.includes('EXAM') && !firstExam) {
                  courseStatistics.push(Math.round(x.netResult * 100) / 100 + "");
                  flag2 = false;
                  firstExam = true;
                } else {
                  courseStatistics.push(Math.round(x.percentajeResult * 100) / 100 + "");
                  lastIndex = index2;
                  firstExam = false;
                  if (w[index + 1] != null && w[index + 1].email == currentEmail) {
                    flag = false;
                  }
                }
              } else {
                if (flag2) {
                  courseStatistics.push(0 + "");
                  flag2 = false;
                }
                if (index2 > lastIndex) {
                  courseStatistics.push(0 + "");
                  lastIndex = index2;
                }
              }
            }
          });
        }
      });
      allCourseStatistics.push(courseStatistics);

      let filename = this.courses.filter(p => p.id == id)[0].name;
      this.downloadCsv(filename, allCourseStatistics, courseStatisticsHeader);
      this.courseStatisticsLoading = false;
    });
  }

  checkNameAndReference(w,) {

  }

  saveCourseFrontPage() {
    this.courseService.saveCourseFrontPage(this.currentCourseFrontPage).subscribe(t => {
      if (t != null) {
        this.currentCourseFrontPage = t;
      }
    });
    this.currentCourse = null;
  }

  nextStep(id: number) {
    this.filter.emit(id);
  }

  saveCourse(course: CourseEntity, bool) {
    this.courseService.saveCourse(course).subscribe(t => {
      if (t != null) {
        if (bool) {
          this.courses.push(t);
        }
        this.courses = this.courses.filter(x => {
          return x
        });
      }
    });
  }

  async saveNewCourse() {
    this.saveCourse(this.newEntity, true);
    this.modeAdd = false;
  }

  newCourse() {
    this.modeAdd = true;
    this.newEntity = new CourseEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

  changeStartDate(event) {
    this.newEntity.startDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event) {
    this.newEntity.endDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeOpenSingupDate(event) {
    this.newEntity.openSignupDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeCloseSingupDate(event) {
    this.newEntity.closeSignupDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  public downloadCsv(filename, courseStatistics, courseStatisticsHeaders) {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      headers: courseStatisticsHeaders
    };
    new Angular5Csv(courseStatistics, filename, options);
  }


  convertFileToBase64DocumentB(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentCourseFrontPage.backgroundImage = reader.result.toString();
    };
  }

  convertFileToBase64DocumentS(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentCourseFrontPage.subscriptionImage = reader.result.toString();
    };
  }

  splitByComma(text){
    if(text!=null){
      return text.split(',');
    }
    return null;
  }

  convertTextToNumber(text){
    return Number(text);
  }
}
