import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CourseEntity} from "../model/CourseEntity";
import {CourseTypeEntity} from "../model/CourseTypeEntity";
import {ModalityEntity} from "../model/ModalityEntity";
import {TurnEntity} from "../model/TurnEntity";
import {SiteEntity} from "../model/SiteEntity";
import {WorkgroupEntity} from "../model/WorkgroupEntity";
import {TutorEntity} from "../model/TutorEntity";
import {HospitalEntity} from "../model/HospitalEntity";
import {SpecialityEntity} from "../model/SpecialityEntity";
import {CourseFrontPageEntity} from "../model/CourseFrontPageEntity";
import {CourseStatisticsDto} from "../dto/CourseStatisticsDto";
import {CourseFriendCorpEntity} from "../model/CourseFriendCorpEntity";
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class CourseService extends ParentService{

  public getCourses(corpId) {
    return this.http.get<CourseEntity[]>(this.shared.MAGNAMENT_URL+'/getCourses/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSharedCourses(corpId) {
    return this.http.get<CourseEntity[]>(this.shared.MAGNAMENT_URL+'/getSharedCourses/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveCourse(course) {
    return this.http.post<CourseEntity>(this.shared.MAGNAMENT_URL+'/saveCourse', course, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCoursesType(corpId) {
    return this.http.get<CourseTypeEntity[]>(this.shared.MAGNAMENT_URL+'/getCoursesType/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSharedCoursesType(coursesId) {
    return this.http.post<CourseTypeEntity[]>(this.shared.MAGNAMENT_URL+'/getSharedCoursesType',coursesId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCoursesTypeByCourse(courseId) {
    return this.http.get<CourseTypeEntity[]>(this.shared.MAGNAMENT_URL+'/getCoursesTypeByCourse/'+courseId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveCourseType(courseType) {
    return this.http.post<CourseTypeEntity>(this.shared.MAGNAMENT_URL+'/saveCourseType', courseType, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }




  public getModalities(corpId) {
    return this.http.get<ModalityEntity[]>(this.shared.MAGNAMENT_URL+'/getModalities/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSharedModalities(courseTypeIds) {
    return this.http.post<ModalityEntity[]>(this.shared.MAGNAMENT_URL+'/getSharedModalities', courseTypeIds, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getModalitiesByCourseType(courseTypeId) {
    return this.http.get<ModalityEntity[]>(this.shared.MAGNAMENT_URL+'/getModalitiesByCourseType/'+courseTypeId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveModality(modality) {
    return this.http.post<ModalityEntity>(this.shared.MAGNAMENT_URL+'/saveModality', modality, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }




  public getSites(corpId) {
    return this.http.get<SiteEntity[]>(this.shared.MAGNAMENT_URL+'/getSites/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSharedSites(modalitiesId) {
    return this.http.post<SiteEntity[]>(this.shared.MAGNAMENT_URL+'/getSharedSites', modalitiesId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSitesByModality(modalityId) {
    return this.http.get<SiteEntity[]>(this.shared.MAGNAMENT_URL+'/getSitesByModality/'+modalityId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveSite(site) {
    return this.http.post<SiteEntity>(this.shared.MAGNAMENT_URL+'/saveSite', site, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }




  public getTurns(corpId) {
    return this.http.get<TurnEntity[]>(this.shared.MAGNAMENT_URL+'/getTurns/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getSharedTurns(sitesId) {
    return this.http.post<TurnEntity[]>(this.shared.MAGNAMENT_URL+'/getSharedTurns', sitesId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getTurnsBySite(siteId) {
    return this.http.get<TurnEntity[]>(this.shared.MAGNAMENT_URL+'/getTurnsBySite/'+siteId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveTurn(turn) {
    return this.http.post<TurnEntity>(this.shared.MAGNAMENT_URL+'/saveTurn', turn, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }



  public getWorkgroups(corpId) {
    return this.http.get<WorkgroupEntity[]>(this.shared.MAGNAMENT_URL+'/getWorkgroups/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveWorkgroup(workgroup) {
    return this.http.post<WorkgroupEntity>(this.shared.MAGNAMENT_URL+'/saveWorkgroup', workgroup, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }



  public getTutors(corpId) {
    return this.http.get<TutorEntity[]>(this.shared.MAGNAMENT_URL+'/getTutors/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveTutor(tutor) {
    return this.http.post<TutorEntity>(this.shared.MAGNAMENT_URL+'/saveTutor', tutor, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }



  public getHospitals(corpId) {
    return this.http.get<HospitalEntity[]>(this.shared.MAGNAMENT_URL+'/getHospitals/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }


  public getSpecialities(corpId) {
    return this.http.get<SpecialityEntity[]>(this.shared.MAGNAMENT_URL+'/getSpecialities/'+corpId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCourseFriendCorps(courseId) {
    return this.http.get<CourseFriendCorpEntity[]>(this.shared.MAGNAMENT_URL+'/getCourseFriendCorps/'+courseId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveCourseFriendCorps(courseId, corps) {
    return this.http.get<CourseFriendCorpEntity[]>(this.shared.MAGNAMENT_URL+'/saveCourseFriendCorps/'+courseId+'/'+corps, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }



  public getCourseFrontPageByCourse(courseId) {
    return this.http.get<CourseFrontPageEntity>(this.shared.MAGNAMENT_URL+'/getCourseFrontPageByCourse/'+courseId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCourseFrontPageByCourseType(courseTypeId) {
    return this.http.get<CourseFrontPageEntity>(this.shared.MAGNAMENT_URL+'/getCourseFrontPageByCourseType/'+courseTypeId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCourseFrontPageByModality(modalityId) {
    return this.http.get<CourseFrontPageEntity>(this.shared.MAGNAMENT_URL+'/getCourseFrontPageByModality/'+modalityId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCourseFrontPageBySite(siteId) {
    return this.http.get<CourseFrontPageEntity>(this.shared.MAGNAMENT_URL+'/getCourseFrontPageBySite/'+siteId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCourseFrontPageByTurn(turnId) {
    return this.http.get<CourseFrontPageEntity>(this.shared.MAGNAMENT_URL+'/getCourseFrontPageByTurn/'+turnId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveCourseFrontPage(courseFrontPage) {
    return this.http.post<CourseFrontPageEntity>(this.shared.MAGNAMENT_URL+'/saveCourseFrontPage', courseFrontPage, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }



  public getCourseStatistics(courseId) {
    return this.http.get<CourseStatisticsDto[]>(this.shared.MAGNAMENT_URL+'/getCourseStatistics/'+courseId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public cloneCourse(courseId) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/cloneCourse/'+courseId+'/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
  public cloneCourseType(courseTypeId) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/cloneCourseType/'+courseTypeId+'/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
  public cloneModality(modalityId) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/cloneModality/'+modalityId+'/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
  public cloneSite(siteId) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/cloneSite/'+siteId+'/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
  public cloneTurn(turnId) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/cloneTurn/'+turnId+'/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }



  public getCommonCourses() {
    return this.http.get(this.shared.MAGNAMENT_URL+'/commonCourses/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
}
