import {Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";

import * as moment from "moment";

import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {QuestionEntity} from "../../../model/QuestionEntity";
import {QuestionService} from "../../../service/QuestionService";
import {CommonQuestionDto} from "../../../dto/CommonQuestionDto";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AnswerEntity} from "../../../model/AnswerEntity";
import {QuestionMultimediaEntity} from "../../../model/QuestionMultimediaEntity";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {QuestionTagEntity} from "../../../model/QuestionTagEntity";
import {OriginEntity} from "../../../model/OriginEntity";
import {QuestionAdminDto} from "../../../dto/QuestionAdminDto";
import {BasicDto} from "../../../dto/BasicDto";
import {SearchQuestionDto} from "../../../dto/SearchQuestionDto";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {QuestionGroupEntity} from "../../../model/QuestionGroupEntity";

const swiper = new Swiper('.swiper', {
  modules: [Navigation, Pagination],
});

@Component({
  selector: 'app-questions',
  templateUrl: 'questions.html',
  styleUrls: ['questions.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Questions {

  @Output() addQuestion = new EventEmitter<string>();

  questionsData: QuestionAdminDto[];
  questions: QuestionEntity[];
  current: QuestionEntity;

  commonQuestion: CommonQuestionDto;
  answers: AnswerEntity[];
  multimedia: QuestionMultimediaEntity[];


  searchOrigins: BasicDto[];
  searchTags: BasicDto[];
  searchGroups: BasicDto[];
  searchSubjects: BasicDto[];
  searchTopics: BasicDto[];

  origins: OriginEntity[];
  questionTags: QuestionTagEntity[];
  questionGroups: QuestionGroupEntity[];

  searchQuestion: SearchQuestionDto;

  currentTab = 1;
  bigImage = -1;
  currentSubjectIndex = -1;

  subjectId = null;
  topicId = null;

  showTable = true;
  searchDiv: boolean = false;

  searchD1: boolean = false;
  searchD2: boolean = false;
  searchD3: boolean = false;
  searchD4: boolean = false;
  searchD5: boolean = false;

  isAddModeToExam: boolean = false;

  showQuestionStartDate = false;
  showQuestionEndDate = false;

  newMultimediaTitle: string = "";
  newMultimediafileUrl: string = "";
  newMultimediaPosition: string = "STATEMENT";
  newMultimediaShowInCampus: boolean = false;

  headers: any = {
    columns: {
      statement: {title: 'Statement', hide: false, filter: false,
        valuePrepareFunction: (statement: string) => {
          return statement.substring(0,80);
        }
      },
      comment: {title: 'Comment', hide: false, filter: false,
        valuePrepareFunction: (comment: string) => {
          return comment.substring(0,80);
        }
      },
      subject: {
        title: 'Subject', hide: false, filter: false,
        valuePrepareFunction: (subject: SubjectEntity) => {
          if(subject!=null) {
            return subject.name;
          }else{
            return "";
          }
        }
      },
      topic: {
        title: 'Topic', hide: false, filter: false,
        valuePrepareFunction: (topic: TopicEntity) => {
          if(topic!=null) {
            return topic.name;
          }else{
            return "";
          }
        }
      },

      corpId: {title: '', hide: this.isAddModeToExam, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: row.id, icon: 'add-outline', text: 'Add', color: '#1c8aa5'}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.addQuestionToExam(data);
            });
          }
        },
      },
      correctAnswer: {title: 'Correct answer', hide: true, filter: false},
      difficulty: {title: 'Difficulty', hide: true, filter: false},
      endDate: {title: 'End date', hide: true, filter: false},
      id: {title: 'ID', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      showInCampus: {title: 'Show in campus', hide: true, filter: false},
      showInMagnament: {title: 'Show in magnament', hide: true, filter: false},
      startDate: {title: 'Start date', hide: true, filter: false},
      successValue: {title: 'Success value', hide: true, filter: false},
      wrongValue: {title: 'Wrong value', hide: true, filter: false}
    },
    actions: false,
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  constructor(private questionService: QuestionService, private shared: Shared) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    let questionReference = this.shared.getLastVariableFromUrl('reference');

    this.isAddModeToExam = !this.shared.checkUrl('exams');
    this.headers.columns.corpId.hide = this.isAddModeToExam;

    (await this.questionService.getQuestions()).subscribe(async x => {
      (await this.questionService.getCommonQuestion()).subscribe(t => {
        if (t != null) {
          this.commonQuestion = t;

          if (questionReference != null) {
            this.questionService.getQuestion(questionReference).subscribe(x => {
              if (x != null) {
                this.rowSelectedEmitter(x);
              }
            });
          }

          this.searchSubjects = this.commonQuestion.subject;
          this.searchTopics = [];
          this.commonQuestion.subject.map(r => {
            return r.list.map(w => {
              w.name = w.name + ' (' + r.alias + ')';
              this.searchTopics.push(w);
            })
          })
          this.searchTags = this.commonQuestion.questionTag;
          this.searchOrigins = this.commonQuestion.origin;
          this.searchGroups = this.commonQuestion.questionGroup;
        }
        if (x != null) {
          console.log(x);
          this.questions = x;
        }
      })
    });
  }

  rowSelectedEmitter(row: QuestionEntity) {
    this.current = row;
    this.searchDiv = false;

    this.questionService.getAnswerQuestion(this.current.reference).subscribe(p => {
      if (p != null) {
        this.answers = p;
      }
    });
    this.questionService.getMultimediaQuestion(this.current.reference).subscribe(p => {
      if (p != null) {
        this.multimedia = p;
      }
    });
    this.questionService.getOriginTagQuestion(this.current.reference).subscribe(p => {
      if (p != null) {
        this.questionTags = p.questionTag;
        this.origins = p.origin;
        this.questionGroups = p.questionGroup;
      }
    });
    this.subjectId = null;
    this.topicId = null;
    if(this.current.subject != null) {
      this.currentSubjectIndex = this.commonQuestion.subject.findIndex(x => x.id == this.current.subject.id);
      this.subjectId = this.current.subject.id;
    }
    if(this.current.topic != null){
      this.topicId = this.current.topic.id;
    }
  }


  openSearchQuestion(){
    this.searchQuestion = new SearchQuestionDto();
    this.searchDiv = true;
  }

  newQuestion() {
    this.current = new QuestionEntity();
    this.current.corpId = this.shared.CORP_ID;
    this.current.subject = new SubjectEntity();
    this.current.subject.id = -1;
    this.current.topic = new TopicEntity();
    this.current.topic.id = -1;
    this.currentSubjectIndex = 0;
    this.answers = [];
    this.multimedia = [];
    this.questionTags = [];
    this.questionGroups = [];
    this.origins = [];
    this.current.comment = '&nbsp;';
  }

  getCorporation(corpId){
    return this.shared.CORPS.find(x=> x.id == corpId);
  }

  addQuestionToExam(data){
    this.addQuestion.emit(data);
  }

  newAnswer(){
    let newNumQuestion = 1;
    if(this.answers.length>0){
      newNumQuestion = this.answers[this.answers.length-1].numAnswer+1;
    }
    this.answers.push(new AnswerEntity(this.shared.CORP_ID,newNumQuestion));
  }

  deleteAnswer(answer){
    this.answers = this.answers.filter(x=>{if(x!=answer){return x}});
  }

  changeSubject() {
    this.current.subject = new SubjectEntity();
    this.current.subject.id = this.subjectId;
    if(this.current.subject != null) {
      this.currentSubjectIndex = this.commonQuestion.subject.findIndex(x => x.id == this.current.subject.id);
    }
  }

  changeTopic() {
    this.current.topic = new TopicEntity();
    this.current.topic.id = this.topicId;
  }

  saveMultimedia(mul: QuestionMultimediaEntity) {
    this.questionService.saveMultimedia(mul).subscribe();
  }

  saveNewMultimedia() {
    let mul: QuestionMultimediaEntity = new QuestionMultimediaEntity();
    mul.title = this.newMultimediaTitle;
    mul.showInCampus = this.newMultimediaShowInCampus;
    mul.fileUrl = this.newMultimediafileUrl;

    mul.corpId = this.current.corpId;
    mul.questionId = this.current.id;
    mul.showInMagnament = true;

    mul.type = 'PHOTO';
    mul.position = this.newMultimediaPosition;

    this.questionService.saveMultimedia(mul).subscribe(x => {
      this.questionService.getMultimediaQuestion(this.current.reference).subscribe(t => {
        if (t != null) {
          this.multimedia = t;
        }
      });
    });

    this.newMultimediaTitle = "";
    this.newMultimediaShowInCampus = false;
    this.newMultimediafileUrl = "";
  }

  uploadMultimedia(evt){
    this.questionService.uploadMultimedia(evt.target.files[0]).subscribe(x=>{
      if(x!=null){
        this.newMultimediafileUrl = x.reference;
      }
    });
  }

  checkCorrect(numCorrect) {
    if (numCorrect == this.current.correctAnswer) {
      this.current.correctAnswer = 0;
    } else {
      this.current.correctAnswer = numCorrect;
    }
  }

  checkTag(tagId) {
    let index = -1;
    if (this.questionTags != null && this.questionTags.length > 0) {
      index = this.questionTags.findIndex(x => x.id == tagId);
    } else {
      this.questionTags = [];
    }

    if (index == -1) {
      this.questionTags.push(new QuestionTagEntity(tagId));
    } else {
      this.questionTags = this.questionTags.filter(x => x.id != tagId);
    }
  }

  checkGroup(tagId) {
    let index = -1;
    if (this.questionGroups != null && this.questionGroups.length > 0) {
      index = this.questionGroups.findIndex(x => x.id == tagId);
    } else {
      this.questionGroups = [];
    }

    if (index == -1) {
      this.questionGroups.push(new QuestionTagEntity(tagId));
    } else {
      this.questionGroups = this.questionGroups.filter(x => x.id != tagId);
    }
  }

  async searchQuestions(){
    this.searchQuestion.difficulty = [];
    if(this.searchD1)this.searchQuestion.difficulty.push(1);
    if(this.searchD2)this.searchQuestion.difficulty.push(2);
    if(this.searchD3)this.searchQuestion.difficulty.push(3);
    if(this.searchD4)this.searchQuestion.difficulty.push(4);
    if(this.searchD5)this.searchQuestion.difficulty.push(5);

    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAA");
    const groups = await this.shared.getCorpGroups();
    console.log(groups);
    this.searchQuestion.corps = groups.split(',').map(Number);

    this.questionService.searchQuestions(this.searchQuestion).subscribe(x => {
      if(x != null) {
        this.questions = x;
      }
    });
  }

  isTagChecked(tagId) {
    if (this.questionTags != null && this.questionTags.length > 0) {
      let index = this.questionTags.findIndex(x => x.id == tagId);
      if (index != -1) {
        return true;
      }
    }
    return false;
  }

  isGroupChecked(tagId) {
    if (this.questionGroups != null && this.questionGroups.length > 0) {
      let index = this.questionGroups.findIndex(x => x.id == tagId);
      if (index != -1) {
        return true;
      }
    }
    return false;
  }

  checkOrigin(originId) {
    let index = -1;
    if (this.origins != null && this.origins.length > 0) {
      index = this.origins.findIndex(x => x.id == originId);
    } else {
      this.origins = [];
    }

    if (index == -1) {
      this.origins.push(new OriginEntity(originId));
    } else {
      this.origins = this.origins.filter(x => x.id != originId);
    }
  }

  isOriginChecked(originId) {
    if (this.origins != null && this.origins.length > 0) {
      let index = this.origins.findIndex(x => x.id == originId);
      if (index != -1) {
        return true;
      }
    }
    return false;
  }

  async saveQuestion() {
    this.showTable = false;
    var obj = {
      question: this.current,
      answers: this.answers,
      questionTags: this.questionTags,
      questionGroups: this.questionGroups,
      origins: this.origins
    }

    this.questionService.saveQuestion(obj).subscribe(x=>{
      if(x!=null){
        let i = this.questions.findIndex(t=>t.id == x.id);
        if(i!=-1){
          this.questions[i] = x;
        }else{
          this.questions.push(x);
        }
      }
    });
    let currentQ = this.questions.findIndex(x => x.id == this.current.id);
    this.questions[currentQ] = this.current;
    await this.delay(200);
    this.showTable = true;
  }

  checkBigImage(questionId) {
    if (this.bigImage != questionId) {
      this.bigImage = questionId
    } else {
      this.bigImage = -1
    }
  }

  saveStartDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.current.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.current.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

