import {Component, ViewChild} from '@angular/core';
import {Shared} from "../../shared/shared";
import {LibraryEntity} from "../../../model/LibraryEntity";
import {LibraryService} from "../../../service/LibraryService";
import {LibraryFolderEntity} from "../../../model/LibraryFolderEntity";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {SubjectService} from "../../../service/SubjectService";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IonAccordionGroup} from "@ionic/angular";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {LibraryFoldeCourseEntity} from "../../../model/LibraryFolderCourseEntity";
import {BasicDto} from "../../../dto/BasicDto";
import {LibraryFolderIntermediateEntity} from "../../../model/LibraryFolderIntermediateEntity";
import {UserService} from "../../../service/UserService";
import {CourseStructureDto} from "../../../dto/CourseStructureDto";
import {CalendarService} from "../../../service/CalendarService";
import {LibraryFolderSubfolderEntity} from "../../../model/LibraryFolderSubfolderEntity";


@Component({
  selector: 'app-library',
  templateUrl: 'library.html',
  styleUrls: ['library.scss']
})
export class Library {

  folders: LibraryFolderEntity[];
  foldersToDropdown: BasicDto[];
  currentFolder: LibraryFolderEntity;

  documents: LibraryEntity[];
  currentDocument: LibraryEntity;

  showFolders = true;

  subjects: SubjectEntity[];
  topics: TopicEntity[];
  basicData: BasicDto[];

  subFolder: boolean = false;

  document: String;
  currentPage:number = 1;
  selectedFile: any;
  selectedFile2: any;
  selectedFile3: any;

  newFolderEntity: LibraryFolderEntity;
  newLibraryEntity: LibraryEntity;
  modeAddFolder: boolean = false;
  modeAdd: boolean = false;

  selectImageF:boolean = false;
  selectImageD:boolean = false;


  showVideos: boolean = false;
  showExams: boolean = false;
  showDocuments: boolean = false;

  noCourse: boolean = false;
  showCoursesOptions: boolean = false;
  coursesOptions: BasicDto[] = [];
  courseOptionSelected: CourseEntity;

  availableVideos: CourseStructureDto[];
  availableLibraries: CourseStructureDto[];
  availableExams: CourseStructureDto[];
  availableCourseStructure: CourseStructureDto[] = [];

  typeOfRequirements = [
    {'id':'DATE', 'name':'Date'},
    {'id':'VIDEO', 'name':'Video'},
    {'id':'LIBRARY', 'name':'Library'},
    {'id':'EXAM', 'name':'Exam'},
    {'id':'VIDEO_FOLDER', 'name':'Video folder'},
    {'id':'LIBRARY_FOLDER', 'name':'Library folder'}
  ];


  headers: any = {
    columns : {
      corpId: {
        title: 'Corp ID',
        hide: true
      },
      title: {
        title: 'Title',
        valuePrepareFunction: (value, row) => {
          return this.checkParent(row);
        }
      },
      description: {
        title: 'Description'
      },
      subjectId: {
        title: 'Subject',
        valuePrepareFunction: (value) => {
          return this.getSubject(value);
        }
      },
      libraryFolderCourses: {
        title: 'Courses',
        valuePrepareFunction: (value) => {
          return this.getCourses(value) ;
        },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            let filtered = this.basicData.filter(x=> x.alias.toUpperCase().includes(search.toUpperCase())).map(c=> c.id);
            if(cell.find(z=>{return filtered.includes(z.courseId);})){
              return true;
            }
          }
          return false;
        }
      },
      libraryFolderSubfolder: {
        title: 'Parent folders',
        type: 'html',
        valuePrepareFunction: (value) => {
          return this.getSubfolder(value, true) ;
        },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            let filtered = this.folders.filter(x=> x.description.toUpperCase().includes(search.toUpperCase())).map(c=> c.id);
            if(cell.find(z=>{return filtered.includes(z.libraryFolderParentId);})){
              return true;
            }
          }
          return false;
        }
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.filterDocuments(data);
            });
          }
        },
      },
    },
    actions: false,
  }


  headersDoc: any = {
    columns : {
      corpId: {
        title: 'Corp ID',
        hide: true
      },
      id: {
        title: 'id',
        hide: true
      },
      title: {
        title: 'Title'
      },
      description: {
        title: 'Description'
      },
      subjectId: {
        title: 'Subject',
        valuePrepareFunction: (value) => {
          return this.getSubject(value);
        }
      },
      topicId: {
        title: 'Topic',
        valuePrepareFunction: (value) => {
          return this.getTopic(value);
        }
      },
    },
    actions: false,
  }

  @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;

  constructor(private shared: Shared, private libraryService: LibraryService, private userService: UserService, private courseService: CourseService, private subjectService: SubjectService, private modalService: NgbModal, private calendarService: CalendarService) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    this.libraryService.getLibraryFolder(this.shared.CORP_ID).subscribe(x=>{
      if(x != null) {
        this.folders = x.reverse();
        this.foldersToDropdown = x.map(x=>new BasicDto(x.id,x.title,x.title+this.getCourses(x.libraryFolderCourses),null));
      }
    });
    (await this.subjectService.getSubjects()).subscribe(x=>{
      if(x != null) {
        this.subjects = x;
      }
    });
    (await this.subjectService.getTopics()).subscribe(x=>{
      if(x != null) {
        this.topics = x;
      }
    });
    this.userService.getBasicData().subscribe(x => {
      if (x != null) {
        this.basicData = x;
      }
    });
  }

  newLibraryFolderCourse(){
    let vfce = new LibraryFoldeCourseEntity();
    vfce.libraryFolderId = this.currentFolder.id;
    vfce.showRequirement = true;
    vfce.requirementMoreThan = true;
    if(this.currentFolder.libraryFolderCourses==null){
      this.currentFolder.libraryFolderCourses = [];
    }
    this.currentFolder.libraryFolderCourses.push(vfce);
  }

  newLibraryFolderIntermediate(){
    let lfie = new LibraryFolderIntermediateEntity();
    lfie.libraryId = this.currentDocument.id;
    lfie.enabled = false;
    lfie.showRequirement = true;
    lfie.requirementMoreThan = true;
    if(this.currentDocument.folders==null){
      this.currentDocument.folders = [];
    }
    this.currentDocument.folders.push(lfie);
  }

  newLibraryFolderSubfolder(){
    let lfie = new LibraryFolderSubfolderEntity();
    lfie.libraryFolderChildId = this.currentFolder.id;
    lfie.corpId = this.shared.CORP_ID;
    lfie.enabled = false;
    if(this.currentFolder.libraryFolderSubfolder==null){
      this.currentFolder.libraryFolderSubfolder = [];
    }
    this.currentFolder.libraryFolderSubfolder.push(lfie);

    this.libraryService.getLibraryFolder(this.shared.CORP_ID).subscribe(x => {
      if (x != null) {
        this.folders = x;
        this.foldersToDropdown = x.map(t=>new BasicDto(t.id,(t.title+' '+this.getCourses(t.libraryFolderCourses)+' '+this.getSubfolder(t.libraryFolderSubfolder, false)),(t.title+' '+this.getCourses(t.libraryFolderCourses)+' '+this.getSubfolder(t.libraryFolderSubfolder, false)),null));
      }
    });
  }

  newLibraryFolder(){
    this.modeAddFolder = true;
    this.currentFolder = new LibraryFolderEntity();
    this.currentFolder.corpId = this.shared.CORP_ID;
    this.currentFolder.showInMagnament = true;
  }

  newLibrary(){
    this.modeAdd = true;
    this.currentDocument = new LibraryEntity();
    this.currentDocument.corpId = this.shared.CORP_ID;
    this.currentDocument.showInMagnament = true;
  }

  convertTextToNumber(text){
    return Number(text);
  }

  getSubject(id){
    if(id!=null && this.subjects != null && this.subjects.length>0) {
      let subject = this.subjects.find(x => x.id === id);
      if(subject != null) {
        return subject.name;
      }
    }
    return "";
  }

  getTopic(id){
    if(id!=null && this.topics != null && this.topics.length>0) {
      let topic = this.topics.find(x => x.id === id);
      if(topic != null) {
        return topic.name;
      }
    }
    return "";
  }

  getCourse(id){
    if(id!=null && this.basicData != null && this.basicData.length>0) {
      let data = this.basicData.find(x => x.id === id);
      if(data != null) {
        return data.name;
      }
    }
    return "";
  }

  checkSubFolder(){
    if(!this.subFolder){
      this.currentFolder.folderParentId = null;
    }
  }

  checkParent(row: LibraryFolderEntity){
    let title = row.title;
    if(row.libraryFolderSubfolder != null && row.libraryFolderSubfolder.length>0){
      title = "-- "+title;
    }
    return title
  }

  getCourses(lfce: LibraryFoldeCourseEntity[]) {
    let output = "";
    lfce.forEach(x=>{
      var c = this.basicData.find(y1 => y1.id === x.courseId);
      if(c != null) {
        if(c.list != null && x.courseTypeId != null){
          var ct = c.list.find(y2 => y2.id === x.courseTypeId);
          if(ct != null){
            if(ct.list != null && x.modalityId != null){
              var m = ct.list.find(y3 => y3.id === x.modalityId);
              if(m != null){
                if(m.list != null && x.siteId != null){
                  var s = m.list.find(y4 => y4.id === x.siteId);
                  if(s != null){
                    if(s.list != null && x.siteId != null){
                      var t = s.list.find(y5 => y5.id === x.turnId);
                      if(t != null){
                        output = output + "[" + t.alias + "]";
                      }
                    }else{
                      output = output + "[" + s.alias + "]";
                    }
                  }
                }else{
                  output = output + "[" + m.alias + "]";
                }
              }
            }else{
              output = output + "[" + ct.alias + "]";
            }
          }
        }else {
          output = output + "[" + c.alias + "]";
        }
      }
    });
    return output;
  }

  getCourseComplete(id) {
    if (id != null) {
      let cou = this.basicData.find(x => x.id === id);
      if(cou != null) {
        return cou;
      }
    }
    return null;
  }

  deleteCourse(lfCourse){
    this.currentFolder.libraryFolderCourses = this.currentFolder.libraryFolderCourses.filter(x=> x!=lfCourse);
  }

  deleteIntermediate(lfIntermediate){
    this.currentDocument.folders = this.currentDocument.folders.filter(x=> x!=lfIntermediate);
  }

  deleteSubfolder(lfSubfolder){
    this.currentFolder.libraryFolderSubfolder = this.currentFolder.libraryFolderSubfolder.filter(x=> x!=lfSubfolder);
  }

  subjectChanged(evt){
    this.subjectService.getTopicsBySubject(evt.id).subscribe(x=>{
      if(x != null) {
        this.topics = x;
      }
    });
  }

  subjectChangedDoc(evt){
    this.currentDocument.subject = evt;
    if(this.currentDocument.title == null || this.currentDocument.title.length == 0){
      this.currentDocument.title = this.currentDocument.subject.name;
    }
    this.subjectService.getTopicsBySubject(evt.id).subscribe(x=>{
      if(x != null) {
        this.topics = x;
      }
    });
  }

  getSubfolder(lfse: LibraryFolderSubfolderEntity[], isTable) {
    //let output = "<ion-icon id='hover-trigger' name='information-circle-outline' ><ion-popover trigger='hover-trigger' triggerAction='hover'><ng-template><ion-content class='ion-padding'>";
    let output = "";
    if(lfse != null) {
      lfse.forEach(x => {
        var c = this.folders.find(y => y.id === x.libraryFolderParentId);
        if (c != null) {
          if(isTable) {
            output = output + '(' + c.description + this.getCourses(c.libraryFolderCourses) + ')<br />';
          }else{
            output = output + '(' + c.description + this.getCourses(c.libraryFolderCourses) + ')';
          }
        }
      });
    }
    //return output+"</ion-content></ng-template></ion-popover>";
    return output;
  }

  topicChangedDoc(evt){
    this.currentDocument.topic = evt;
  }

  getIndexOf(list,id){
    let output = list.findIndex(x=> x.id == id);
    return output;
  }

  rowSelectedEmitter(row: LibraryFolderEntity){
    this.currentFolder = row;

    if(this.currentFolder.libraryFolderCourses.length>0) {
      this.getCourseStructure(this.currentFolder.libraryFolderCourses[0]);
    }

    if(this.currentFolder.folderParentId != null){
      this.subFolder = true;
    }else{
      this.subFolder = false;
    }
  }

  async rowSelectedEmitterDoc(row: LibraryEntity){
    this.showFolders = false;
    await this.delay(300);
    this.currentDocument = row;

    if(this.currentDocument.folders.length>0) {
      this.getCourseStructure2(this.currentDocument.folders[0].typeOfRequirement);
    }

    this.showFolders = true;
    this.modeAdd = false;
  }

  async saveFolder(){
    this.libraryService.saveLibraryFolder(this.currentFolder).subscribe(x=>{
      if(x!=null){
        if(this.modeAddFolder){
          this.folders.push(x);
          this.folders = this.folders.filter(x => {return x});
          this.modeAddFolder = false;
        }
        this.currentFolder = null;
      }
    });
    this.folders[this.folders.findIndex(x=> x.id == this.currentFolder.id)] = this.currentFolder;
    await this.delay(200);
  }

  async saveDocument(){
    this.libraryService.saveLibrary(this.currentDocument).subscribe(x=>{
      if(x!=null){
        if(this.modeAdd){
          this.documents.push(x);
          this.documents = this.documents.filter(x => {return x});
          this.modeAdd = false;
        }
        this.currentDocument = null;
      }
    });
    this.documents[this.documents.findIndex(x=> x.id == this.currentDocument.id)] = this.currentDocument;
    await this.delay(200);
  }

  viewDocument(template){
    this.libraryService.getLibrary(this.currentDocument.reference).subscribe(x=>{
      if(x!=null) {
        this.currentDocument = x;
        this.document = this.currentDocument.fileUrl.substring(0,10)+this.currentDocument.fileUrl.substring(20,(this.currentDocument.fileUrl.length)-20)+this.currentDocument.fileUrl.substring((this.currentDocument.fileUrl.length-10),this.currentDocument.fileUrl.length);
        this.modalService.open(template, { centered: true });
      }
    });
  }

  getCourseStructure(data){
    if(data.type != 'DATE' && data.courseId != null) {
      this.availableCourseStructure = null;
      this.calendarService.getCourseStructure(data.courseId).subscribe(x => {
        if (x != null) {
          this.availableCourseStructure = x;
          this.availableExams = x.filter(t => t.type == 'EXAM');
          this.availableVideos = x.filter(t => t.type == 'VIDEO_FOLDER');
          this.availableLibraries = x.filter(t => t.type == 'LIBRARY_FOLDER');
        }
      });
    }
  }

  getCourseStructure2(type){
    if(this.currentFolder == null || this.currentFolder == undefined){
      this.noCourse = true;
    }else{
      this.noCourse = false;
      if(this.currentFolder.libraryFolderCourses.length>1) {
        this.showCoursesOptions = true;
        this.coursesOptions = this.currentFolder.libraryFolderCourses.map(t => this.getCourseComplete(t.courseId));
        if(this.courseOptionSelected != null && this.courseOptionSelected != undefined) {
          this.getCourseStructure(this.courseOptionSelected);
        }
      }else if(this.currentFolder.libraryFolderCourses.length==0){
        this.showCoursesOptions = false;
        if(this.currentFolder.libraryFolderSubfolder[0].libraryFolderParentId != null){
          let course = this.folders.find(t=> t.id == this.currentFolder.libraryFolderSubfolder[0].libraryFolderParentId)
          if(course != null) {
            this.getCourseStructure(course);
          }
        }
      }else{
        this.showCoursesOptions = false;
        this.getCourseStructure(this.currentFolder.libraryFolderCourses[0]);
      }
    }
  }

  showData(type, template){
    if(type==null){
      return false;
    }else if(type=='EXAM'){
      this.showExams = true;
    }else if(type == 'VIDEO' || type == 'VIDEO_FOLDER'){
      this.showVideos = true;
    }else if(type == 'LIBRARY' || type == 'LIBRARY_FOLDER'){
      this.showDocuments = true;
    }

    this.modalService.open(template, { centered: true });
  }

  getExam(reference){
    return this.availableExams.find(x=>x.reference === reference);
  }

  getVideo(reference){
    let fullList = [];

    this.availableVideos.forEach(x1=>{
      fullList.push(x1);
      if(x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if(x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return fullList.find(x=> x.reference == reference);
  }

  getDocument(reference){
    let fullList = [];

    this.availableLibraries.forEach(x1=>{
      fullList.push(x1);
      if(x1.list != null) {
        x1.list.forEach(x2 => {
          fullList.push(x2);
          if(x2.list != null) {
            x2.list.forEach(x3 => {
              fullList.push(x3);
              if (x3.list != null) {
                x3.list.forEach(x4 => {
                  fullList.push(x4);
                  if (x4.list != null) {
                    x4.list.forEach(x5 => {
                      fullList.push(x5);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return fullList.find(x=> x.reference == reference);
  }

  selFile(evt){
    this.selectedFile = evt.target.files[0];
  }

  selFile2(evt){
    this.selectedFile2 = evt.target.files[0];
  }

  selFile3(evt){
    this.selectedFile3 = evt.target.files[0];
  }


  uploadDocument(){
    this.libraryService.uploadDocument(this.selectedFile).subscribe(x=>{
      if(x!=null){
        this.currentDocument.fileUrl = x.reference;
      }
    });
  }

  uploadLowDocument(){
    this.libraryService.uploadDocument(this.selectedFile2).subscribe(x=>{
      if(x!=null){
        this.currentDocument.lowFileUrl = x.reference;
      }
    });
  }

  uploadUpdates(){
    this.libraryService.uploadDocument(this.selectedFile3).subscribe(x=>{
      if(x!=null){
        this.currentDocument.updatesUrl = x.reference;
      }
    });
  }

  convertFileToBase64Folder(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentFolder.front = reader.result.toString();
    };
  }

  convertFileToBase64Document(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentDocument.front = reader.result.toString();
    };
  }

  filterDocuments(data){
    this.libraryService.getDocuments(data).subscribe(x=>{
      if(x != null) {
        this.documents = x.reverse();
        const nativeEl = this.accordionGroup;
        nativeEl.value = 'documents';
      }
    });

    this.libraryService.getLibraryFolder(this.shared.CORP_ID).subscribe(x=>{
      if(x != null) {
        this.folders = x;
        this.foldersToDropdown = x.map(x=>new BasicDto(x.id,x.title,x.title+this.getCourses(x.libraryFolderCourses),null));
      }
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
