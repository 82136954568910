import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {PricetableEntity} from "../model/PricetableEntity";
import {ChatRoomEntity} from "../model/ChatRoomEntity";
import {ChatMessageEntity} from "../model/ChatMessageEntity";
import {MultisubscriptionEntity} from "../model/MultisubscriptionEntity";
import {BasicDto} from "../dto/BasicDto";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {ImageEntity} from "../model/ImageEntity";
import {SurveyEntity} from "../model/SurveyEntity";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {SurveyItemEntity} from "../model/SurveyItemEntity";
import {VideoPlaylistVideoEntity} from "../model/VideoPlaylistVideoEntity";

@Injectable()
export class SurveyService extends ParentService{

  public saveSurvey(surveyEntity){
    return this.http.post<SurveyEntity>(this.shared.MAGNAMENT_URL+'/saveSurvey', surveyEntity, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getSurveys(){
    return this.http.get<SurveyEntity[]>(this.shared.MAGNAMENT_URL+'/getSurveys/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public saveSurveyItem(surveyEntity){
    return this.http.post<SurveyItemEntity>(this.shared.MAGNAMENT_URL+'/saveSurveyItem', surveyEntity, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getSurveyItems(surveyId){
    return this.http.get<SurveyItemEntity[]>(this.shared.MAGNAMENT_URL+'/getSurveyItems/'+surveyId, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public saveSurveyItems(surveyItems){
    return this.http.post<SurveyItemEntity>(this.shared.MAGNAMENT_URL+'/saveSurveyItems',surveyItems, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public deleteSurveyItem(id){
    return this.http.get<any>(this.shared.MAGNAMENT_URL+'/deleteSurveyItem/'+id, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

}
