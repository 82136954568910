import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {EmailTemplateService} from "../../../service/EmailTempalteService";
import {EmailTemplateEntity} from "../../../model/EmailTemplateEntity";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'app-email-template',
  templateUrl: 'emailTemplate.html',
  styleUrls: ['emailTemplate.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailTemplate {

  emailTemplates: EmailTemplateEntity[];
  currentEmailTemplate: EmailTemplateEntity;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  openEmojiSubject: boolean = false;
  openEmojiMessage: boolean = false;
  caretPos: number = 0;

  types = [
    {'id':'SIMPLE', 'name':'Simple'},
    {'id':'PASSWORD_RECOVERY', 'name':'Password recovery'},
    {'id':'REGISTER', 'name':'Register'},
    {'id':'NOTES', 'name':'Notes'},
    {'id':'NOTIFICATIONS', 'name':'Notifications'},
    {'id':'STUDENT_MAIL', 'name':'Student mail'},
    {'id':'REGISTER_WITH_BT', 'name':'Register with bank transfer'},
    {'id':'WELCOME_STUDENT', 'name':'Welcome student'}
  ];

  constructor(private emailTemplateService: EmailTemplateService, private shared: Shared) {
  }

  ngOnInit() {
    this.emailTemplateService.getEmailTemplates().subscribe(x=>{
      if(x!=null){
        this.emailTemplates = x;
      }
    });
  }

  saveEmailTemplate(){
    this.emailTemplateService.saveEmailTemplate(this.currentEmailTemplate).subscribe(x=>{
      if(x!=null){
        let index = -1;
        index = this.emailTemplates.findIndex(t=> t.id == x.id);
        if(index == -1){
          this.emailTemplates.push(x);
        }else{
          this.emailTemplates[index] = x;
        }
        this.currentEmailTemplate = null;
      }
    });
  }

  addEmojiSubject(evt){
    this.currentEmailTemplate.subject = [
      this.currentEmailTemplate.subject.slice(0, this.caretPos),
      evt.emoji.native,
      this.currentEmailTemplate.subject.slice(this.caretPos)].join('');
  }

  addEmojiMessage(evt){
    this.currentEmailTemplate.template = [
      this.currentEmailTemplate.template.slice(0, this.caretPos),
      evt.emoji.native,
      this.currentEmailTemplate.template.slice(this.caretPos)].join('');
  }

  getCaretPos(evt) {
    if (evt.srcElement.selectionStart || evt.srcElement.selectionStart == '0') {
      this.caretPos = evt.srcElement.selectionStart;
    }
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
