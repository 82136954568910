import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Pricetable} from "./pricetable";


const routes: Routes = [
  {
    path: 'pricetable',
    component: Pricetable,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricetableRoutingModule {}
