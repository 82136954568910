import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {CourseTypeEntity} from "../../../model/CourseTypeEntity";
import {ModalityEntity} from "../../../model/ModalityEntity";
import {SiteEntity} from "../../../model/SiteEntity";
import {TurnEntity} from "../../../model/TurnEntity";
import {PricetableEntity} from "../../../model/PricetableEntity";
import {ContextService} from "../../../service/ContextService";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {BooleanComponent} from "../generic/table/booleanComponent/booleanComponent";
import {DropdownMComponent} from "../generic/table/dropdownMComponent/dropdownMComponent";
import {BankService} from "../../../service/BankService";
import {BankPackageEntity} from "../../../model/BankPackageEntity";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ReportService} from 'src/app/service/ReportService';
import {ReportsEntity} from "../../../model/ReportsEntity";
import {CalendarComponent} from "../generic/table/calendarComponent/calendarComponent";
import * as moment from "moment/moment";
import {DownloadStudentSettingDto} from "../../../dto/DownloadStudentSettingDto";
import {SearchStudentDto} from "../../../dto/SearchStudentDto";
import {SearchSubcriptionDto} from "../../../dto/SearchSubcriptionDto";
import {SubscriptionPaymentsEntity} from "../../../model/SubscriptionPaymentsEntity";
import {SearchPaymentsDto} from "../../../dto/SearchPaymentsDto";
import {SearchUserObject} from "../../../dto/SearchUserObject";
import {BasicDto} from "../../../dto/BasicDto";
import {CommonUserDto} from "../../../dto/CommonUserDto";
import {UserService} from "../../../service/UserService";
import {CouponEntity} from "../../../model/CouponEntity";

@Component({
  selector: 'app-report',
  templateUrl: 'report.html',
  styleUrls: ['report.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Report {

  reports: ReportsEntity[];

  newEntity: ReportsEntity;
  current: ReportsEntity;
  modeAdd = false;

  commonUserData: CommonUserDto;
  basicData: BasicDto[];
  coupons: CouponEntity[];

  typesOfPeriocity = [
    {'id':'OneHour', 'name': '1 Hour'},
    {'id':'ThreeHour', 'name': '3 Hours'},
    {'id':'SixHour', 'name': '6 Hours'},
    {'id':'TwelveHour', 'name': '12 Hours'},
    {'id':'OneDay', 'name': '1 Day'},
    {'id':'SevenDay', 'name': '7 Day'},
    {'id':'FifteenDay', 'name': '15 Day'},
    {'id':'OneMonth', 'name': '1 Month'},
    {'id':'TwoMonth', 'name': '2 Month'},
    {'id':'ThreeMonth', 'name': '3 Month'},
    {'id':'SixMonth', 'name': '6 Month'},
    {'id':'TwelveMonth', 'name': '12 Month'}
  ];

  homologationStatus = [
    {id: 1, name: 'Document request'},
    {id: 3, name: 'Document sent'},
    {id: 5, name: 'Document validate'},
    {id: 7, name: 'Send to ministry'},
    {id: 9, name: 'Aprovved by ministry'},
  ];

  homologationRequestStatus = [
    {id: 0, name: 'Sent'},
    {id: 1, name: 'Validated'},
    {id: 2, name: 'Rejected'},
  ];

  typeOfNotifications = [
    {id: 0, name: 'Text'},
    {id: 1, name: 'External URL'},
    {id: 2, name: 'Class'},
    {id: 3, name: 'Tutorship'},
    {id: 4, name: 'Exam'},
    {id: 5, name: 'Video'},
    {id: 6, name: 'Document'},
    {id: 7, name: 'Internal URL'},
  ];

  typeOfPayments = [
    {id: 0, name: 'Enrollment'},
    {id: 1, name: 'Monthly'},
    {id: 2, name: 'Totality'},
    {id: 3, name: 'Manuals'},
    {id: 4, name: 'Repeat rate'},
    {id: 5, name: 'Homologation rate'},
    {id: 6, name: 'Shipping'},
  ];

  formatOfPayments = [
    {id: 0, name: 'Paypal'},
    {id: 1, name: 'Redsys'},
    {id: 2, name: 'Stripe'},
    {id: 3, name: 'Bank transfer'},
    {id: 4, name: 'Bizum'},
    {id: 5, name: 'Cash'},
  ];

  currencies = [
    {id: 'EUR', name: 'EUR'},
    {id: 'USD', name: 'USD'},
    {id: 'MXN', name: 'MXN'},
    {id: 'ARS', name: 'ARS'},
    {id: 'CRC', name: 'CRC'},
  ];

  courseIndex: number;
  courseTypeIndex: number;
  modalityIndex: number;
  siteIndex: number;

  coursesSearch: BasicDto[] = [];
  coursesTypeSearch: BasicDto[] = [];
  modalitySearch: BasicDto[] = [];
  siteSearch: BasicDto[] = [];
  turnSearch: BasicDto[] = [];
  countries: BasicDto[] = [];

  showCourseType: boolean = false;
  showModality: boolean = false;
  showSite: boolean = false;
  showTurn: boolean = false;

  showSubStartDate: boolean = false;
  showSubEndDate: boolean = false;

  showNotStartDate: boolean = false;
  showNotEndDate: boolean = false;

  showSubStartDateSF: boolean = false;
  showSubStartDateST: boolean = false;
  showSubEndDateSF: boolean = false;
  showSubEndDateST: boolean = false;

  PshowSubStartDateSF: boolean = false;
  PshowSubStartDateST: boolean = false;
  PshowSubEndDateSF: boolean = false;
  PshowSubEndDateST: boolean = false;

  PshowDoneDateSF: boolean = false;
  PshowDoneDateST: boolean = false;
  PshowRestoreDateSF: boolean = false;
  PshowRestoreDateST: boolean = false;

  showFirstDate = false;
  showExportObject = false;
  showSearchObject = false;

  dssd: DownloadStudentSettingDto = new DownloadStudentSettingDto();

  searchUser: SearchStudentDto = new SearchStudentDto();
  searchSubscription: SearchSubcriptionDto = new SearchSubcriptionDto();
  searchSubscriptionPayments: SubscriptionPaymentsEntity = new SubscriptionPaymentsEntity();
  searchPayments: SearchPaymentsDto = new SearchPaymentsDto();


  headers: any = {
    columns: {
      corpId: {title: 'corp', hide: true, filter: false},
      name: {
        title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let report = this.reports.find(x => x.id == data.row.id)
              report.name = data.text;
              this.saveReport(report, false);
            });
          }
        },
      },
      searchObject:{
          title: 'Search object', hide: false, filter: false, type: "custom",
          valuePrepareFunction: (cell, row) => {
            return {cell: row.id, icon: 'pencil-outline', text: '', color: ''}
          },
          renderComponent: ButtonComponent,
          onComponentInitFunction: (instance) => {
            if (instance != null) {
              instance.changeButton.subscribe(data => {
                let report = this.reports.find(x => x.id == data)
                this.showSearchObject = true;
                this.current = report;
                if(this.current.searchObject != null) {
                  let obj = JSON.parse(this.current.searchObject);
                  this.searchUser = obj.searchUser;
                  this.searchSubscription = obj.searchSubscription;
                  this.searchSubscriptionPayments = obj.searchSubscriptionPayments;
                  this.searchPayments = obj.searchPayments;
                }else{
                  this.searchUser = new SearchStudentDto();
                  this.searchSubscription= new SearchSubcriptionDto();
                  this.searchSubscriptionPayments = new SubscriptionPaymentsEntity();
                  this.searchPayments = new SearchPaymentsDto();
                }
              });
            }
          },
        },
      exportObject:{
          title: 'Export object', hide: false, filter: false, type: "custom",
          valuePrepareFunction: (cell, row) => {
            return {cell: row.id, icon: 'pencil-outline', text: '', color: ''}
          },
          renderComponent: ButtonComponent,
          onComponentInitFunction: (instance) => {
            if (instance != null) {
              instance.changeButton.subscribe(data => {
                let report = this.reports.find(x => x.id == data)
                this.showExportObject = true;
                this.current = report;
                if(this.current.exportObject != null) {
                  this.dssd = JSON.parse(this.current.exportObject);
                }else{
                  this.dssd = new DownloadStudentSettingDto();
                }
              });
            }
          },
        },
      emails: {
        title: 'Emails', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let report = this.reports.find(x => x.id == data.row.id)
              report.emails = data.text;
              this.saveReport(report, false);
            });
          }
        },
      },
      periocity: {
        title: 'Periodicity', hide: false, filter: true, width: '180px', type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, options: this.typesOfPeriocity}
        },
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let report = this.reports.find(x => x.id == data.row.id)
              report.periocity = data.id;
              this.saveReport(report, false);
            });
          }
        },
      },
      firstDate: {
        title: 'First date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let report = this.reports.find(x => x.id == data.row.id)
              report.firstDate = data.date;
              this.saveReport(report, false);
            });
          }
        },
      },
      lastExecution: {title: 'Last execution', hide: false, filter: true},
      incremental: {
        title: 'Incremental', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let report = this.reports.find(x => x.id == data.row.id)
              report.incremental = data.bool;
              this.saveReport(report, false);
            });
          }
        },
      },
      enabled: {
        title: 'Enabled', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let report = this.reports.find(x => x.id == data.row.id)
              report.enabled = data.bool;
              this.saveReport(report, false);
            });
          }
        },
      },
      id:{
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'play-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              console.log(data);
              this.executeNow(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private reportService: ReportService, private userService: UserService, private shared: Shared) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    (await this.reportService.getReports()).subscribe(async t => {
      if (t != null) {
        this.reports = t;

        (await this.userService.getBasicDataByCorp()).subscribe(async p => {
          if (p != null) {
            this.basicData = p;

            (await this.userService.getCommonUserData()).subscribe(t => {
              if (t != null) {
                this.commonUserData = t;
              }
            });

            this.userService.getCountries().subscribe(y => {
              if (y != null) {
                this.countries = y;
              }
            });

            (await this.userService.getCoupons()).subscribe(z => {
              if (z != null) {
                this.coupons = z;
              }
            });

            this.coursesSearch = this.basicData;
            this.coursesSearch.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + ')';
              this.coursesTypeSearch.push(y)
            }));
            this.coursesSearch.map(t => t.list.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + '-' + t.alias + ')';
              this.modalitySearch.push(y)
            })));
            this.coursesSearch.map(p => p.list.map(t => t.list.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + '-' + t.alias + '-' + p.alias + ')';
              this.siteSearch.push(y)
            }))));
            this.coursesSearch.map(r => r.list.map(p => p.list.map(t => t.list.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + '-' + t.alias + '-' + p.alias + '-' + r.alias + ')';
              this.turnSearch.push(y)
            })))));
          }
        });
      }
    });
  }

  async saveReport(report: ReportsEntity, isNew: boolean) {

    this.reportService.saveReport(report).subscribe(t => {
      if (t != null) {
        if (isNew) {
          this.reports.push(t);
          this.reports = this.reports.filter(x => {
            return x
          });
        }
      }
    });
  }

  saveExportObject(){
      this.current.exportObject = JSON.stringify(this.dssd);
      this.saveReport(this.current, false);
      this.showExportObject = false;
  }

  async saveNewReport() {
    this.saveReport(this.newEntity, true);
    this.modeAdd = false;
  }

  newReport() {
    this.modeAdd = true;
    this.newEntity = new ReportsEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.enabled = true;
  }

  changeFirstDate(event) {
    this.newEntity.firstDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  executeNow(id){
    this.reportService.executeReportNow(id).subscribe(t => {
      if (t != null) {
        if(t) {
          this.shared.toastr.success('Executed now, check your email', 'Executed');
        }else{
          this.shared.toastr.error('There are errors on execution, review search and export objects', 'Error');
        }
      }
    });
  }

  cleanSearchsUsers() {
    this.searchUser = new SearchStudentDto();
    this.searchSubscription = new SearchSubcriptionDto();
    this.searchSubscriptionPayments = new SubscriptionPaymentsEntity();
    this.searchPayments = new SearchPaymentsDto();
  }

  async saveSearchObject() {
    const groups = await this.shared.getCorpGroups();
    this.searchUser.corpId = groups.split(',').map(Number);
    let obj = new SearchUserObject(this.searchUser, this.searchSubscription, this.searchSubscriptionPayments, this.searchPayments);

    this.current.searchObject = JSON.stringify(obj);
    this.saveReport(this.current, false);
    this.showSearchObject = false;
  }

  selectAllbs(bool){
    this.dssd.bsCorporation = bool;
    this.dssd.bsName = bool;
    this.dssd.bsSurname = bool;
    this.dssd.bsAddress = bool;
    this.dssd.bsProvince = bool;
    this.dssd.bsEmail = bool;
    this.dssd.bsTown = bool;
    this.dssd.bsMobile = bool;
    this.dssd.bsCP = bool;
    this.dssd.bsNIF = bool;
    this.dssd.bsCountry = bool;
    this.dssd.bsUniversity = bool;
    this.dssd.bsHospital = bool;
  }

  selectAllbc(bool){
    this.dssd.bcCourseprice = bool;
    this.dssd.bcEnrollment = bool;
    this.dssd.bcMonthly = bool;
    this.dssd.bcNumofmonths = bool;
    this.dssd.bcCoupons = bool;
  }

  selectAllbb(bool){
    this.dssd.bbCorporation = bool;
    this.dssd.bbReference = bool;
    this.dssd.bbEnabled = bool;
    this.dssd.bbTestsubscription = bool;
    this.dssd.bbEnrolled = bool;
    this.dssd.bbDisabledreason = bool;
    this.dssd.bbWorkgroup = bool;
    this.dssd.bbTutor = bool;
    this.dssd.bbCourse = bool;
    this.dssd.bbCoursetype = bool;
    this.dssd.bbModality = bool;
    this.dssd.bbSite = bool;
    this.dssd.bbTurn = bool;
    this.dssd.bbStartdate = bool;
    this.dssd.bbEnddate = bool;
    this.dssd.bbComments = bool;
    this.dssd.bbScholarship = bool;
    this.dssd.bbRegisterLinkUsed = bool;
  }

  selectAllbp(bool){
    this.dssd.bpPaid = bool;
    this.dssd.bpRestore = bool;
    this.dssd.bpStartdate = bool;
    this.dssd.bpEnddate = bool;
    this.dssd.bpDonedate = bool;
    this.dssd.bpRestoredate = bool;
    this.dssd.bpTypeofpayment = bool;
    this.dssd.bpFormatofpayment = bool;
    this.dssd.bpPrice = bool;
    this.dssd.bpCurrency = bool;
  }

  saveStartDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.startDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveStartDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.startDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.endDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.endDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePStartDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.startDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePStartDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.startDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePEndDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.endDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePEndDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.endDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePDoneDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.doneDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePRestoreDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.restoreDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePDoneDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.doneDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePRestoreDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.restoreDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  convertDate(date) {
    if (date != null) {
      return moment(date).format('yyyy-MM-DD');
    } else {
      return "";
    }
  }

  closeDateTimePickers() {
    this.showSubStartDateSF = false;
    this.showSubStartDateST = false;
    this.showSubEndDateSF = false;
    this.showSubEndDateST = false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
