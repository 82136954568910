import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";

export class ModuleEntity {
  corpId: number;
  id: number;
  name: string;
  courseId: number;
  startDate: string;
  endDate: string;
  showInCampus: boolean;
  showInMagnament: boolean;
}
