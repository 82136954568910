export class PackageItemEntity {
  corpId: number;
  id: number;
  typeOfEventId: number;
  maxTime: number;
  minTime: number;
  subjectId: number;
  topicId: number;
  customText: string;
  customIcon: string;
  startDate: string;
  endDate: string;
  order: number;
  showInMagnament: boolean;
  packageId: number;
  auxReference: string;
  modeEdit: boolean = false;
}
