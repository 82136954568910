import {Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {CalendarService} from "../../../service/CalendarService";
import {StepContentEntity} from "../../../model/StepContentEntity";
import {StepEntity} from "../../../model/StepEntity";
import {CourseService} from "../../../service/CourseService";
import {CourseEntity} from "../../../model/CourseEntity";
import {CourseTypeEntity} from "../../../model/CourseTypeEntity";
import {ModalityEntity} from "../../../model/ModalityEntity";
import {SiteEntity} from "../../../model/SiteEntity";
import {TurnEntity} from "../../../model/TurnEntity";
import {SubjectService} from "../../../service/SubjectService";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TopicEntity} from "../../../model/TopicEntity";
import {PackageEntity} from "../../../model/CalendarPackage";
import {TypeOfEventEntity} from "../../../model/TypeOfEventEntity";
import {ForumEntity} from "../../../model/ForumEntity";
import {ForumService} from "../../../service/ForumService";
import {ForumMessageEntity} from "../../../model/ForumMessageEntity";
import {ForumCategoriesEntity} from "../../../model/ForumCategoriesEntity";

@Component({
  selector: 'app-forum-data',
  templateUrl: 'forumData.html',
  styleUrls: ['forumData.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForumData {

  forums: ForumEntity[];
  forumMessages: ForumMessageEntity[];
  forumCategories: ForumCategoriesEntity[];

  currentForum : ForumEntity;

  @ViewChild('forumContentHeader') forumContentHeader:ElementRef;

  constructor(private forumService: ForumService, private shared: Shared, private renderer: Renderer2) {
  }

  ngOnInit() {
   this.myInit();
  }

  async myInit(){
    this.forumService.getForums().subscribe(x => {
      if (x != null) {
        this.forums = x;
      }
    });
  }

  filterForum(id: number){
    this.currentForum = this.forums.find(f=>f.id == id);
    this.forumService.getForumMessages(id, 'EMPTY').subscribe(x => {
      if (x != null) {
        this.forumMessages = x;
        this.renderer.selectRootElement('#forumMessageHeader').click();
      }
    });

    this.forumService.getForumCategories(id).subscribe(x => {
      if (x != null) {
        this.forumCategories = x;
        this.forumCategories.forEach(c=>{
          if(c.subject != null){
            c.name = c.subject.name;
            c.icon = c.subject.icon;
          }
        });
      }
    });
  }
}
