import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {CourseService} from "../../../../service/CourseService";
import {CourseEntity} from "../../../../model/CourseEntity";
import * as moment from "moment";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {CalendarComponent} from "../../generic/table/calendarComponent/calendarComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {CourseTypeEntity} from "../../../../model/CourseTypeEntity";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {ModalityEntity} from "../../../../model/ModalityEntity";
import {BasicDto} from "../../../../dto/BasicDto";
import {ContextService} from "../../../../service/ContextService";
import {CourseFrontPageEntity} from "../../../../model/CourseFrontPageEntity";
import {TurnEntity} from "../../../../model/TurnEntity";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'app-coursesType',
  templateUrl: 'coursesType.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class CoursesType {

  @Input() coursesType: CourseTypeEntity[];
  @Input() courses: CourseEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: CourseTypeEntity;
  modeAdd = false;

  roles: BasicDto[];

  showStartDate = false;
  showEndDate = false;
  showOpenSignupDate = false;
  showCloseSignupDate = false;

  currentCourseType: CourseTypeEntity;
  currentCourseFrontPage: CourseFrontPageEntity;

  availableParents: any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  headers: any = {
    columns: {
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.alias = data.text;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.name = data.text;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      courseId: {title: 'Course', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {return {cell: cell, options: this.courses}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.courseId = data.id;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      startDate: {
        title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.startDate = data.date;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      endDate: {
        title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.endDate = data.date;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      openSignupDate: {
        title: 'Open signup date', hide: true, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.openSignupDate = data.date;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      closeSignupDate: {
        title: 'Close signup date', hide: true, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: CalendarComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.closeSignupDate = data.date;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      defaultRole: {
        title: 'Default role', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, options: this.roles}
        },
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.defaultRole = data.id;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      contentNotification: {
        title: 'Content notification', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.contentNotification = data.bool;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      showInRegister: {
        title: 'Show in Register', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.showInRegister = data.bool;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.showInCampus = data.bool;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let courseType = this.coursesType.find(x => x.id == data.row.id)
              courseType.showInMagnament = data.bool;
              this.saveCourseType(courseType, false);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
      corpId: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'brush-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.customizeCourse(data);
            });
          }
        },
      },
      row2: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'copy-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.cloneCourseType(data);
            });
          }
        },
      },
      row3: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'settings-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.openSettingsDiv(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private courseService: CourseService, private contextService: ContextService, private shared: Shared) {
  }


  log(ev){
    console.log(ev);
  }
  ngOnInit() {
    this.contextService.getRoles().subscribe(t => {
      if (t != null) {
        this.roles = t;
      }
    });
  }

  customizeCourse(id: number) {
    this.currentCourseType = this.coursesType.find(x => x.id == id);
    this.currentCourseFrontPage = new CourseFrontPageEntity();
    this.currentCourseFrontPage.corpId = this.shared.CORP_ID;
    this.currentCourseFrontPage.courseTypeId = id;
    this.currentCourseFrontPage.html = "";
    this.currentCourseFrontPage.backgroundImage = "";
    this.currentCourseFrontPage.enabled = true;
    this.courseService.getCourseFrontPageByCourseType(this.currentCourseType.id).subscribe(t => {
      if (t != null) {
        this.currentCourseFrontPage = t;
      }
    });
  }

  saveCourseFrontPage() {
    this.courseService.saveCourseFrontPage(this.currentCourseFrontPage).subscribe(t => {
      if (t != null) {
        this.currentCourseFrontPage = t;
      }
    });
    this.currentCourseType = null;
  }


  nextStep(id: number){
    this.filter.emit(id);
  }

  openSettingsDiv(courseTypeId){
    this.currentCourseType = this.coursesType.find(p=> p.id == courseTypeId);
    let c = this.courses.find(p=> p.id == this.currentCourseType.courseId);

    this.availableParents.push({'id':c.id, 'name':c.name, 'type': "COURSE"});
    this.coursesType.filter(p=>p.courseId == c.id).forEach(ct=>{
        this.availableParents.push({'id': ct.id, 'name': "-- "+ct.name, 'type': "COURSE_TYPE"});
    })
  }

  cloneCourseType(id){
    let bool = confirm("¿Estás seguro que deseas duplicar el tipo de curso?");

    if(bool) {
      this.courseService.cloneCourseType(id).subscribe((w: CourseTypeEntity[]) => {
        if (w != null) {
          this.coursesType = w;
        }
      });
    }
  }

  saveCourseType(courseType: CourseTypeEntity, bool) {
    if(courseType.name.indexOf('(')!=-1) {
      courseType.name = courseType.name.substr(0,courseType.name.indexOf('('));
    }
    this.courseService.saveCourseType(courseType).subscribe( t =>{
      if(t!=null) {
        if(bool){this.coursesType.push(t);}
        this.coursesType = this.coursesType.filter(x => {return x});
      }
    });
  }

  async saveNewCourseType() {
    this.saveCourseType(this.newEntity, true);
    this.modeAdd = false;
  }

  newCourseType(){
    this.modeAdd = true;
    this.newEntity = new CourseTypeEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

  changeStartDate(event){
    this.newEntity.startDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeEndDate(event){
    this.newEntity.endDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeOpenSingupDate(event){
    this.newEntity.openSignupDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }

  changeCloseSingupDate(event){
    this.newEntity.closeSignupDate = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
  }


  convertFileToBase64DocumentB(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentCourseFrontPage.backgroundImage = reader.result.toString();
    };
  }

  convertFileToBase64DocumentS(evt) {
    let me = this;
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.currentCourseFrontPage.subscriptionImage = reader.result.toString();
    };
  }
}
