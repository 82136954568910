import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Shared} from '../view/shared/shared';
import {throwError} from "rxjs";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class ParentService {

  constructor(public http:HttpClient, public shared: Shared){}

  showError(error){
    console.log("Error detected");
    console.log(error);
  }

}
