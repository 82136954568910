export class RegisterLinkObjEntity {
  reference: string;

  mobile: boolean;
  nif: boolean;
  address: boolean;
  province: boolean;
  town: boolean;
  cp: boolean;
  country: boolean;
  university: boolean;
  hospital: boolean;

  mobileReq: boolean;
  nifReq: boolean;
  addressReq: boolean;
  provinceReq: boolean;
  townReq: boolean;
  cpReq: boolean;
  countryReq: boolean;
  universityReq: boolean;
  hospitalReq: boolean;

  course: number;
  courseType: number;
  modality: number;
  site: number;
  turn: number;ç
  testSubscription: boolean;
  startDateSub: string;
  endDateSub: string;
  singleCourse: boolean;

  payment: boolean;
  startDate: string;
  endDate: string;
  couponCode: string;
  defaultRole: number;
  activeSubscription: number;
}
