import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {SubjectEntity} from "../../../../model/SubjectEntity";
import {SubjectService} from "../../../../service/SubjectService";

@Component({
  selector: 'app-subject',
  templateUrl: 'subject.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Subject {

  @Input() subjects: SubjectEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: SubjectEntity;
  modeAdd = false;

  headers: any = {
    columns: {
      corpId: {title: 'Statement', hide: true, filter: false},
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.alias = data.text;
              this.saveSubject(subject, false);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.name = data.text;
              this.saveSubject(subject, false);
            });
          }
        },
      },
      icon: {title: 'Icon', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.icon = data.text;
              this.saveSubject(subject, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.showInCampus = data.bool;
              this.saveSubject(subject, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: true, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.showInMagnament = data.bool;
              this.saveSubject(subject, false);
            });
          }
        },
      },
      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.nextStep(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private subjectService: SubjectService, private shared: Shared) {
  }

  ngOnInit() {
  }

  nextStep(id: number){
    this.filter.emit(id);
  }

  saveSubject(subject: SubjectEntity,bool) {
    this.subjectService.saveSubject(subject).subscribe( t =>{
      if(t!=null) {
        if(bool){this.subjects.push(t);}
        this.subjects = this.subjects.filter(x => {return x});
      }
    });
  }

  async saveNewSubject() {
    this.saveSubject(this.newEntity,true);
    this.modeAdd = false;
  }

  newSubject(){
    this.modeAdd = true;
    this.newEntity = new SubjectEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

}
