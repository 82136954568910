import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {ReportRoutingModule} from "./report-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {CourseService} from "../../../service/CourseService";
import {Report} from "./report";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {ContextService} from "../../../service/ContextService";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {ReportService} from "../../../service/ReportService";
import {UserService} from "../../../service/UserService";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReportRoutingModule,
        NgxDatatableModule,
        TableModule,
        NgSelectModule,
        DlDateTimePickerModule,
        AngularEditorModule,
    ],
  providers: [ReportService, UserService],
  exports: [
    Report
  ],
  declarations: [Report]
})
export class ReportModule {}
