import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {PricetableEntity} from "../model/PricetableEntity";
import {ChatRoomEntity} from "../model/ChatRoomEntity";
import {ChatMessageEntity} from "../model/ChatMessageEntity";
import {MultisubscriptionEntity} from "../model/MultisubscriptionEntity";
import {BasicDto} from "../dto/BasicDto";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {CorpStyleEntity} from "../model/CorpStyleEntity";
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ContextService extends ParentService{

  public getPrices(){
    return this.http.get<PricetableEntity[]>(this.shared.MAGNAMENT_URL+'/getPricetable/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getCorpStyle(){
    return this.http.get<CorpStyleEntity>(this.shared.MAGNAMENT_URL+'/getCorpStyle/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveCorpStyle(corpStyle){
    return this.http.post<CorpStyleEntity>(this.shared.MAGNAMENT_URL+'/saveCorpStyle', corpStyle, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getRoles(){
    return this.http.get<BasicDto[]>(this.shared.MAGNAMENT_URL+'/getRoles', this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getMultisubscriptions(){
    return this.http.get<MultisubscriptionEntity[]>(this.shared.MAGNAMENT_URL+'/getMultisubscription/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public savePrice(price){
    return this.http.post<PricetableEntity>(this.shared.MAGNAMENT_URL+'/savePricetable', price, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)))
  }

  public getAllOpenRooms(){
    return this.http.get<ChatRoomEntity[]>(this.shared.MAGNAMENT_URL+'/getAllOpenRooms/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public getMessages(roomReference){
    return this.http.get<ChatMessageEntity[]>(this.shared.MAGNAMENT_URL+'/getChatMessages/'+roomReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public finishRoom(roomReference) {
    return this.http.get<boolean>(this.shared.MAGNAMENT_URL+'/finishRoom/'+roomReference, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public uploadMultimedia(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadLibraryImage', formData, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

}
