import {CorporationEntity} from "./CorporationEntity";
import {TopicEntity} from "./TopicEntity";
import {SubjectEntity} from "./SubjectEntity";
import {ModuleItemEntity} from "./ModuleItemEntity";

export class ModuleFolderEntity {
  corpId: number;
  id: number;
  name: string;
  moduleId: number;
  startDate: string;
  endDate: string;
  showInCampus: boolean;
  showInMagnament: boolean;
  folderOrder: number;
  items: ModuleItemEntity[];
}
