import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VideoPlaylistData } from './videoPlaylistData';
import {VideoPlaylistDataRoutingModule} from "./videoPlaylistData-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {VideoItem} from "./videoItem/videoItem";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {SubjectService} from "../../../service/SubjectService";
import {VideoPlaylist} from "./videoPlaylist/videoPlaylist";
import {BankService} from "../../../service/BankService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    VideoPlaylistDataRoutingModule,
    NgxDatatableModule,
    TableModule,
    DlDateTimePickerModule,
    NgSelectModule,
  ],
  providers: [BankService],
  exports: [
    VideoPlaylistData,
    VideoPlaylist
  ],
  declarations: [VideoPlaylistData, VideoItem, VideoPlaylist]
})
export class VideoPlaylistDataModule {}
