import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Table } from './table';
import {Ng2SmartTableModule} from "ng2-smart-table";
import {BooleanComponent} from "./booleanComponent/booleanComponent";
import {CalendarComponent} from "./calendarComponent/calendarComponent";
import {DlDateTimeDateModule, DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {TextComponent} from "./textComponent/textComponent";
import {DropdownComponent} from "./dropdownComponent/dropdownComponent";
import {DropdownMComponent} from "./dropdownMComponent/dropdownMComponent";
import {NgSelectModule} from "@ng-select/ng-select";
import {timezone} from "../../../pipes/timezone.pipe";
import {CalendarComponent2} from "./calendarComponent2/calendarComponent2";
import {timezone2} from "../../../pipes/timezone2.pipe";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    Ng2SmartTableModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    NgSelectModule,
  ],
  providers: [],
  exports: [
    Table,
    TextComponent,
    DropdownComponent,
    DropdownMComponent,
    CalendarComponent,
    CalendarComponent2,
    BooleanComponent,
    timezone
  ],
  declarations: [Table, BooleanComponent, CalendarComponent, CalendarComponent2, TextComponent, DropdownComponent, DropdownMComponent, timezone, timezone2]
})
export class TableModule {}
