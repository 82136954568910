import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../../shared/shared";
import {BooleanComponent} from "../../generic/table/booleanComponent/booleanComponent";
import {TextComponent} from "../../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../../generic/table/buttonComponent/buttonComponent";
import {StepEntity} from "../../../../model/StepEntity";
import {CalendarComponent} from "../../generic/table/calendarComponent/calendarComponent";
import {TurnEntity} from "../../../../model/TurnEntity";
import {SiteEntity} from "../../../../model/SiteEntity";
import {ModalityEntity} from "../../../../model/ModalityEntity";
import {CourseTypeEntity} from "../../../../model/CourseTypeEntity";
import {CourseEntity} from "../../../../model/CourseEntity";
import {DropdownComponent} from "../../generic/table/dropdownComponent/dropdownComponent";
import {CalendarService} from "../../../../service/CalendarService";
import * as moment from "moment";
import { ForumEntity } from 'src/app/model/ForumEntity';
import {ForumService} from "../../../../service/ForumService";

@Component({
  selector: 'app-forum',
  templateUrl: 'forum.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Forum {

  @Input() forums: ForumEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: ForumEntity;
  modeAdd = false;

  headers: any = {
    columns: {
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let forum = this.forums.find(x => x.id == data.row.id)
              forum.name = data.text;
              this.saveForum(forum, false);
            });
          }
        },
      },
      allowVideo: {
        title: 'Allow video', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let forum = this.forums.find(x => x.id == data.row.id)
              forum.allowVideo = data.bool;
              this.saveForum(forum, false);
            });
          }
        },
      },
      allowAudio: {
        title: 'Allow audio', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let forum = this.forums.find(x => x.id == data.row.id)
              forum.showInMagnament = data.bool;
              this.saveForum(forum, false);
            });
          }
        },
      },
      showInCampus: {
        title: 'Show in Campus', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let forum = this.forums.find(x => x.id == data.row.id)
              forum.showInMagnament = data.bool;
              this.saveForum(forum, false);
            });
          }
        },
      },
      showInMagnament: {
        title: 'Show in Magnament', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: BooleanComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeBool.subscribe(data => {
              let forum = this.forums.find(x => x.id == data.row.id)
              forum.showInMagnament = data.bool;
              this.saveForum(forum, false);
            });
          }
        },
      },

      id: {title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {return {cell: cell, icon: 'arrow-redo-outline', text: '', color: ''}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.insideForum(data);
            });
          }
        },
      },
      corpId: {title: '', hide: true, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: row.id, icon: 'cube-outline', text: 'Package', color: '#1c8aa5'}},
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.insideForum(data);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private forumService: ForumService, private shared: Shared) {
  }

  ngOnInit() {
  }

  insideForum(id: number){
    this.filter.emit(id);
  }

  saveForum(forum: ForumEntity,bool) {
    this.forumService.saveForum(forum).subscribe( t =>{
      if(t!=null) {
        if(bool){this.forums.push(t);}
        this.forums = this.forums.filter(x => {return x});
      }
    });
  }

  async saveNewForum() {
    this.saveForum(this.newEntity,true);
    this.modeAdd = false;
  }

  newForum(){
    this.modeAdd = true;
    this.newEntity = new ForumEntity();
    this.newEntity.corpId = this.shared.CORP_ID;
    this.newEntity.showInMagnament = true;
  }

}
