import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'dropdownM-component' ,
  templateUrl: 'dropdownMComponent.html',
  styleUrls: ['dropdownMComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownMComponent implements ViewCell, OnInit {
  @Input() value: any;
  @Output() changeDropdown:  EventEmitter<{row: any, id: number[]}> = new EventEmitter();
  rowData: any;
  options: any[];
  val: number[];
  valString: string;
  showInput = false;

  showBlankSpace = false;

  @ViewChild('select') select: ElementRef;
  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    if(this.value != null) {
      this.val = this.value.cell;
      this.options = this.value.options;

      if(this.value.cell==null || this.value.cell.length ==0){
        this.showBlankSpace = true;
      }

    }else{
      this.val = [];
      this.options = [];
      this.showBlankSpace = true;
    }

    if(this.options!= null && this.options.length > 0) {
      this.valString = "";
      for(let t of this.val){
        let obj:any = this.options.find(x => x.id == t);
        if(obj != null) {
          this.valString = this.valString + "-" + obj.alias;
        }
      };
    }
  }

  append(){
    this.renderer.appendChild(document.body, this.select.nativeElement);
  }

  change(){
    this.showInput = false;

    this.valString = "";
    for(let t of this.val){
      let obj:any = this.options.find(x => x.id == t);
      if(obj != null) {
        this.valString = this.valString + "-" + obj.alias;
      }
    };

    this.changeDropdown.emit({row: this.rowData, id: this.val});
  }
}
