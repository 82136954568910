import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../shared/shared";
import {LoginService} from "../../service/LoginService";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Login {

  email: string;
  password: string;

  isSuccess: boolean = false;
  isError: boolean = false;

  constructor(private loginService: LoginService ,private shared: Shared, private router: Router) {
  }

  ngOnInit() {
    if(this.router.url.indexOf('/logout')!=-1){
      localStorage.removeItem('CURRENT_USER');
      localStorage.removeItem('CURRENT_CORP');
      localStorage.removeItem('CURRENT_SUBS_NUMBER');
    }

    if(this.router.url.indexOf('/error')!=-1){
      this.isError = true;
    }
  }

  login(){
    this.loginService.login(this.email, this.password).subscribe(x=>{
      if(x!=null){
          this.shared.afterLogin(x.corpId, x.id, x.token);
      }
    },error => {
      if(error == '401' || error == '403' || error == '400'){
        this.isError = true;
      }
    });
  }

}
