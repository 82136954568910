import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import * as moment from 'moment-timezone';
import {Shared} from "../../../../shared/shared";

@Component({
  selector: 'calendar-component' ,
  templateUrl: 'calendarComponent.html',
  styleUrls: ['calendarComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Output() changeCalendar:  EventEmitter<{row: any, date: string}> = new EventEmitter();
  rowData: any;
  showDate = false;

  defaultDate;

  @ViewChild('datePicker') datePicker: ElementRef;
  
  constructor(private shared: Shared, private renderer: Renderer2) {}

  ngOnInit() {
    if(this.value == null) {
      this.defaultDate = moment.tz(this.shared.CURRENT_CORP.timezone).toDate();
    }else{
      this.defaultDate = moment.tz(this.value, this.shared.CURRENT_CORP.timezone).toDate();
    }
  }

  change(event){
    this.value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.value = moment.tz(this.value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
    this.changeCalendar.emit({row: this.rowData, date: this.value});
  }

  append(){
    this.renderer.appendChild(document.body, this.datePicker.nativeElement);
  }
}
