import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import {Shared} from "../shared/shared";

@Pipe({name: 'timezone2'})
export class timezone2 implements PipeTransform {

  constructor(private shared: Shared) {}

  transform(value: string, format: string): any {
    if(value != null) {
      return moment(value).tz(this.shared.CURRENT_CORP.timezone).format('DD/MM/yyyy');
    }else{
      return null;
    }
  }
}
