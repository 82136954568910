import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import * as moment from "moment";

@Component({
  selector: 'text-component' ,
  templateUrl: 'textComponent.html',
  styleUrls: ['textComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Output() changeText:  EventEmitter<{row: any, text: string}> = new EventEmitter();
  rowData: any;

  showInput = false;
  showBlankSpace = false;

  ngOnInit() {
    if(this.value == null || this.value.length == 0){
      this.value = "";
      this.showBlankSpace = true;
    }
  }

  change(){
    this.showInput = false;
    this.changeText.emit({row: this.rowData, text: this.value});
  }
}
