import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {ContextService} from "../../../service/ContextService";
import {ChatRoomEntity} from "../../../model/ChatRoomEntity";
import {ChatMessageEntity} from "../../../model/ChatMessageEntity";
import {Platform} from "@ionic/angular";
import {ImagePicker} from "@awesome-cordova-plugins/image-picker/ngx";

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

@Component({
  selector: 'app-chat-support',
  templateUrl: 'chatSupport.html',
  styleUrls: ['chatSupport.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatSupport {

  rooms: ChatRoomEntity[];
  messages: ChatMessageEntity[]=[];

  imageResponse: any;
  options: any;

  disabled = true;
  newmessage: string;
  stompClient = null;

  currentRoom: ChatRoomEntity;

  openEmoji: boolean = false;
  caretPos: number = 0;
  color = "";

  @ViewChild('myFileInput') myFileInput: ElementRef<HTMLElement>;

  constructor(private contextService: ContextService, private shared: Shared, public platform: Platform, private imagePicker: ImagePicker) {
  }

  ngOnInit() {
    this.contextService.getAllOpenRooms().subscribe(x=>{
      if(x!=null){
        this.rooms = x;
      }
    });
  }

  openRoom(room){
    this.currentRoom = room;

    this.connect();

    this.contextService.getMessages(this.currentRoom.reference).subscribe(x=>{
      if(x!=null){
        this.messages = x;
      }
    });
  }

  setConnected(connected: boolean) {
    this.disabled = !connected;
  }

  connect() {
    const socket = new SockJS(this.shared.CHAT_URL + '/userChat');
    this.stompClient = Stomp.over(socket);
    const _this = this;
    this.stompClient.connect({}, function (frame) {
      _this.stompClient.subscribe('/start/' + _this.currentRoom.reference + '/initial', function (hello) {
        _this.showMessage(JSON.parse(hello.body));
      });
    });
  }

  sendWelcomeMessage(){
    this.newmessage = "Bienvenido al chat de Mediplus, te atiende "+this.shared.CURRENT_MANAGER.name+" "+ this.shared.CURRENT_MANAGER.surname +". ¿En que puedo ayudarte?";
    this.sendMessage(true);
  }

  sendGoodbyeMessage(){
    this.newmessage = "Gracias por usar nuestro chat, estamos aquí para lo que necesites. Puedes hacer click en Finalizar chat cuando quieras.";
    this.sendMessage(true);
  }

  sendMessage(isText) {
    let obj;
    if(isText) {
      obj = {
        'corpId': this.currentRoom.corpId,
        'type': 0,
        'studentId': this.currentRoom.studentId,
        'staffId': 1,
        'message': this.newmessage,
        'roomReference': this.currentRoom.reference
      }
    }else{
      obj = {
        'corpId': this.currentRoom.corpId,
        'type': 1,
        'studentId': this.currentRoom.studentId,
        'staffId': 1,
        'message': this.base64textString,
        'roomReference': this.currentRoom.reference
      }
    }
    this.stompClient.send('/current/resume/' + this.currentRoom.reference, {}, JSON.stringify(obj));
    this.newmessage = "";
  }

  showMessage(body) {
    this.messages = body;
  }

  getCaretPos(evt) {
    if (evt.selectionStart || evt.selectionStart == '0') {
      this.caretPos = evt.selectionStart;
    }
  }

  addEmoji(evt) {
    this.newmessage = [
      this.newmessage.slice(0, this.caretPos),
      evt.emoji.native,
      this.newmessage.slice(this.caretPos)].join('');
  }

  finishRoom() {
    this.contextService.finishRoom(this.currentRoom.reference).subscribe(x => {
      if (x != null) {
        this.shared.goBack();
      }
    });
  }

  base64textString="";

  convertImage(evt) {
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload =this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString= "data:image/jpeg;base64,"+btoa(binaryString);
    this.sendMessage(false);
  }

  pickImage() {
    if (this.platform.is('cordova')) {
      this.options = {
        width: 200,
        quality: 25,
        outputType: 1
      };
      this.imageResponse = [];
      this.imagePicker.getPictures(this.options).then((results) => {
        for (var i = 0; i < results.length; i++) {
          this.imageResponse.push('data:image/jpeg;base64,' + results[i]);
        }
      }, (err) => {
        alert(err);
      });
    }else{
      this.myFileInput.nativeElement.click();
    }
  }
}
