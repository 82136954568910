import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import * as moment from "moment";
import {Payment} from "../../../../model/Payment";

@Component({
  selector: 'format-popover' ,
  templateUrl: 'formatPopover.html',
  styleUrls: ['./../users.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormatPopover implements ViewCell, OnInit {
  @Input() value: string;
  @Output() formatSave:  EventEmitter<any> = new EventEmitter();

  showFormatPopover: Boolean = false;
  rowData: Payment;
  tempRow: Payment;

  constructor() { }

  ngOnInit() {
    this.tempRow = this.rowData;
  }

  saveDataAndClose(){
    this.rowData = this.tempRow;
    this.formatSave.emit(this.rowData);
    this.showFormatPopover = false;
  }

  openPopover(){
    this.showFormatPopover = true;
  }

  selectPopover(val){
    this.tempRow.formatOfPayment=val;
  }

}
