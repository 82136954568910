import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {StudentEntity} from "../model/StudentEntity";
import {BasicDto} from "../dto/BasicDto";
import {CoursePriceDto} from "../dto/CoursePriceDto";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {ReportsEntity} from "../model/ReportsEntity";
import {CourseEntity} from "../model/CourseEntity";
import {JustBooleanDto} from "../dto/JustBooleanDto";

@Injectable()
export class ReportService extends ParentService{

  public getReports() {
    return this.http.get<ReportsEntity[]>(this.shared.MAGNAMENT_URL+'/getReports/'+this.shared.CORP_ID, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public saveReport(report) {
    return this.http.post<ReportsEntity>(this.shared.MAGNAMENT_URL+'/saveReport', report, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }

  public executeReportNow(id) {
    return this.http.get<JustBooleanDto>(this.shared.MAGNAMENT_URL+'/executeReportNow/'+id, this.shared.headers()).pipe(catchError((error: HttpErrorResponse) => this.shared.handleError(error)));
  }
}
